import { ActionType, IAppAction } from '../actions/Helpers';
const initialState = {
    locationReadings: Array(),
    totalResults: Number(0),
    locationPreviousCount: Number(0),
    allLocationMarkers: Array(),
}
const LocationReadingsReducer = (state = initialState, action: IAppAction) => {
    switch (action.type) {
        case ActionType.GET_LOCATION_READINGS:
            let updateGridArray: any[] = [];
            if (action.payload.data) {
                if (state.locationReadings.length === 0) {
                    updateGridArray.push(...action.payload.data)
                } else if ((state.totalResults - state.locationReadings.length === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.locationReadings]
                    }
                    updateGridArray.push(...action.payload.data)
                } else if (action.payload.data.length === state.totalResults) {
                    updateGridArray.push(...action.payload.data)
                }
                else if ((state.locationPreviousCount === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.locationReadings]
                    }
                    updateGridArray.push(...action.payload.data)
                } else {
                    updateGridArray.push(...action.payload.data)
                }
            }
            return {
                ...state,
                locationReadings: updateGridArray,
                locationPreviousCount: action.payload.data ? action.payload.data.length : 0,
                totalResults: action.payload.totalResults ? action.payload.totalResults : 0
            };
      default:
            return state
    };
};
export default LocationReadingsReducer;