import { ActionType, IAppAction } from '../actions/Helpers';
const initialState = {
    sensorReadings: Array(),
    totalResults: Number(0),
    sensorPreviousCount: Number(0),
    allLocationMarkers: Array(),
}
const sensorReadingsReducer = (state = initialState, action: IAppAction) => {
    switch (action.type) {
        case ActionType.GET_SENSOR_READINGS:
            let updateGridArray: any[] = [];
            if (action.payload.data) {
                if (state.sensorReadings.length === 0) {
                    updateGridArray.push(...action.payload.data)
                } else if ((state.totalResults - state.sensorReadings.length === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.sensorReadings]
                    }
                    updateGridArray.push(...action.payload.data)
                } else if (action.payload.data.length === state.totalResults) {
                    updateGridArray.push(...action.payload.data)
                }
                else if ((state.sensorPreviousCount === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.sensorReadings]
                    }
                    updateGridArray.push(...action.payload.data)
                } else {
                    updateGridArray.push(...action.payload.data)
                }
            }
            return {
                ...state,
                sensorReadings: updateGridArray,
                sensorPreviousCount: action.payload.data ? action.payload.data.length : 0,
                totalResults: action.payload.totalResults ? action.payload.totalResults : 0
            };
        case ActionType.GET_SENSOR_READINGS_RESET:
            return {
                ...state,
                sensorReadings: [],
                sensorPreviousCount: 0,
                totalResults: 0
            };
        case ActionType.GET_LOCATION_MARKERS:
            return { ...state, allLocationMarkers: action.payload.data ? action.payload.data : [], };
        case ActionType.ON_REFRESH:
                return initialState;
        default:
            return state
    };
};
export default sensorReadingsReducer;