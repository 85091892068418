import * as React from 'react';
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Theme, Typography, Grid,Tooltip,ListItemText, Box, capitalize
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as SensorReadingActions from '../../store/actions/SensorReadings.Action';
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router-dom";
import IFilterModel from '../../models/common/IFilterModel';
import { IUtility } from '../../state/Utility';
import { SearchBox } from '../../components/search/SearchBox';
import moment from 'moment';
import {
    FaThermometerQuarter, FaBatteryFull, FaBolt,FaHandSparkles,FaLightbulb
} from 'react-icons/fa';
import {FcHighBattery,FcIdea} from 'react-icons/fc'
import { IoSpeedometerOutline,IoSpeedometerSharp } from 'react-icons/io5';
import { MdWaterDrop,MdOutlineWaterDrop } from "react-icons/md";
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models';
import { GridToolbar } from '@mui/x-data-grid/components';

const classNames = require('classnames');

interface IPageProps extends RouteComponentProps<{}> {
    sensorReadings?: any,
    classes?: any,
    getSensorReadingsDetailsByRange: (start: number, limit: number, paramName?: string, paramValue?: string) => void;
    refresh:()=>void,
    totalResults: number;
    match: any;
    location: ILocation;
    utility: IUtility


}
interface ILocationState {
    imei: string
}

export interface ILocation<S = ILocationState> {
    pathname: string;
    search: string;
    state: S;
    hash: string;
    key?: string;
}

interface IPageState {
    title: string;
    sensorReadingTablePage: number,
    sensorReadingTableRowsPerPage: number,
    pageStartCount: number,
    anchorEl: any;
    searchBoxValue: string,
    selectedFilterMenuIndex: number,
    filterMenuOptions: any[],
    selectedFilterMenu: string | number | boolean;
    selectedFilterMenuKey: string;
    searchBoxFilterKey: string,
}

class SensorReadings extends React.Component<IPageProps, IPageState> {
    constructor(props: IPageProps) {
        super(props);
        this.state = {
            title: 'welcome',
            sensorReadingTablePage: 0,
            sensorReadingTableRowsPerPage: 10,
            pageStartCount: 10,
            anchorEl: null,
            selectedFilterMenuIndex: 0,
            searchBoxValue: '',
            filterMenuOptions: [{ "lable": 'Device Name', "key": 'devicename' }, { "lable": 'IMEI', "key": 'imei' }],
            selectedFilterMenu: 'Device Name',
            selectedFilterMenuKey: 'devicename',
            searchBoxFilterKey: ''
        }
    }

    columns: GridColDef[] = [
        {
          field: 'name',
          headerName: 'Sensor Name',
            width: 150,
            editable: false,
          valueGetter: this.getSensorName
        },
        {
          field: 'nickName',
          headerName: 'Device Nick Name',
          width: 150,
          editable: false,
          valueGetter:this.getSensorNickName
        },
        {
            field: 'imei',
            headerName: 'IMEI',
            width: 150,
            editable: false,
            valueGetter:this.getSensorImei
          },
          {
            field: 'sensorType',
            headerName: 'Type',
            width: 150,
            editable: false,
            renderCell: (params: GridRenderCellParams<any>) => (
                <>
                    {capitalize( params.row.sensorType)}
                    {/* {params.row.sensorType === 'battery_temp' &&
                        <Tooltip placement="top" title={params.row.name} >
                            <div>
                                <span><FaBatteryFull color='green' /> :  <FaThermometerQuarter color='#4f4ff1' /></span>
                            </div>
                        </Tooltip>
                    }
                    {params.row.sensorType === 'battery_voltage' &&
                        <Tooltip placement="top" title={params.row.name} >
                            <div>
                                <span> <FaBatteryFull color='green' /> : <FaBolt color='orange' /></span>
                            </div>
                        </Tooltip>
                    }
                    {params.row.sensorType === 'motion_sensor' &&
                        <Tooltip placement="top" title={params.row.name} >
                            <div> <FaHandSparkles color="darkgoldenrod" /></div>
                        </Tooltip>
                    }
                    {params.row.sensorType === 'pressure_sensor_1' &&
                        <Tooltip placement="top" title={params.row.name} >
                            <div> <IoSpeedometerOutline /></div>
                        </Tooltip>
                    }
                    {params.row.sensorType === 'pressure_sensor_2' &&
                        <Tooltip placement="top" title={params.row.name} >
                            <div> <IoSpeedometerSharp /></div>
                        </Tooltip>
                    }
                    {params.row.sensorType === 'humidtytemp_sensor_2' &&
                        <Tooltip placement="top" title={params.row.name} >
                            <div> <MdWaterDrop color='dodgerblue' /></div>
                        </Tooltip>
                    }
                    {params.row.sensorType === 'humidtytemp_sensor_1' &&
                        <Tooltip placement="top" title={params.row.name} >
                            <div> <MdOutlineWaterDrop color='dodgerblue' /></div>
                        </Tooltip>
                    }
                    {params.row.sensorType === 'light_sensor' &&
                        <Tooltip placement="top" title={params.row.name} >
                            <div>  <FcIdea /></div>
                        </Tooltip>
                    } */}
                </>
            ),
          },
        {
            field: 'value',
            headerName: 'Value',
            width: 150,
            editable: false,
           
        },
       
          {
            field: 'updateDateTime',
            headerName: 'Measurement Time',
            width: 200,
            editable: false,
            valueGetter: this.getSensorReading,
          },
      ];
    getSensorName(param){
        return `${param.row.device? param.row.device.name :'--'}`;
    }
    getSensorNickName(param){
        return `${param.row.device? param.row.device.nickName :'--'}`;
    }
    getSensorImei(param){
        return `${param.row.device? param.row.device.imei :'--'}`;
    }
    getSensorReading(param){
        const dateOption: any = { year: 'numeric', month: 'short', day: '2-digit' };
        const timeOption: any = { hour: '2-digit', minute: '2-digit' };
        return `${param.row.sensorReadingDateTime? new Date(param.row.sensorReadingDateTime).toLocaleDateString('en-US', dateOption) + ' ' + new Date(param.row.sensorReadingDateTime).toLocaleTimeString('en-US', timeOption) :'--'}`;
    }
  
    public componentWillMount() {
        const { imei } = (this.props.location && this.props.location.state) || {};
        if (imei) {  
            this.props.refresh();
            this.props.getSensorReadingsDetailsByRange(0, 10, 'imei', imei);
        } else {
            this.props.getSensorReadingsDetailsByRange(0, 10);
        }
    }
    public componentDidMount() {
        const { imei } = (this.props.location && this.props.location.state) || {};
        if (imei) {
            this.setState({
                searchBoxValue: imei,
                selectedFilterMenu: 'IMEI',
                selectedFilterMenuKey: 'imei',
                searchBoxFilterKey: 'imei',
                selectedFilterMenuIndex: 1
            })
        }
    }
    private handleChangesensorReadingPage = (event: any, page: number) => {
        this.setState({ sensorReadingTablePage: page });
    };
    private handleChangeTableRowsPerPage = async (event: any) => {
        await this.props.getSensorReadingsDetailsByRange(0, event.target.value as number, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ sensorReadingTableRowsPerPage: event.target.value, pageStartCount: event.target.value, sensorReadingTablePage: 0 });
    };
    private loadNextPage = async () => {
        await this.props.getSensorReadingsDetailsByRange(this.state.pageStartCount, this.state.sensorReadingTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ sensorReadingTablePage: this.state.sensorReadingTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.sensorReadingTableRowsPerPage });
    }
    private loadPreviousPage = () => {
        this.setState({ sensorReadingTablePage: this.state.sensorReadingTablePage - 1, pageStartCount: this.state.pageStartCount - this.state.sensorReadingTableRowsPerPage })
    }
    private handleClose = () => {
        this.setState({ anchorEl: null })
    }
    private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: IFilterModel) => {
        this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key })
    };
    private handleSearchBox = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private onEnterKeyPress = (event: any) => {
        if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
            this.callSearchByParam();
        }
    }
    private callSearchByParam = async () => {
        await this.props.getSensorReadingsDetailsByRange(0, this.state.sensorReadingTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    }
    private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    private dateAndTimeConverter = (date: string) => {
        const formateDefault = moment(date, moment.defaultFormat).toDate();
        if (moment(formateDefault).isValid()) {
            return moment(formateDefault).format('MMM DD YYYY HH:mm')
        }
        return '';
    }
    private getSensorTypeBasedData = (sensorReading: any) => {
        if (sensorReading.sensorType === 'battery') {
            return (<><i className="fa fa-battery-full" />: <i>{sensorReading.value} </i>%</>)
        } else if (sensorReading.sensorType === 'temperature') {
            return (<><i className="fa fa-thermometer-quarter" />: <i>{sensorReading.value}</i>&#176;</>)
        } else if (sensorReading.sensorType === 'dwyerExternalTemperatureSensor') {
            return (<><i className="fa fa-thermometer-quarter" />: <i>{sensorReading.value}</i>&#176;</>)
        } else if (sensorReading.sensorType === 'dolExternalTemperatureSensor') {
            return (<><i className="fa fa-thermometer-quarter" />: <i>{sensorReading.value}</i>&#176;</>)
        } else if (sensorReading.sensorType === 'dolExternalHumiditySensor') {
            return (<><i className="fa fa-tint" />: <i>{sensorReading.value} </i>%</>)
        }
        if (sensorReading.sensorType === null) {
            if (sensorReading.value === 1) {
                if (sensorReading.optionalCircuit === 0) {
                    return "Power On"
                } else {
                    return "Power Loss"
                }
            }
            else if (sensorReading.value === 2) { return "Relay Issue" }
            else if (sensorReading.value === 3) { return "False Alarm" }
            else if (sensorReading.value === 4) {
                if (sensorReading.optionalCircuit === 1) {
                    return "Power Loss"
                } else {
                    return "Power On"
                }
            }
        }
        return sensorReading.value;

    }
    public render(): JSX.Element {
        const dateOption: any = { year: 'numeric', month: 'short', day: '2-digit' };
        const timeOption: any = { hour: '2-digit', minute: '2-digit' };
        const { sensorReadings, classes, totalResults } = this.props;
        const { selectedFilterMenuIndex, anchorEl, selectedFilterMenu, filterMenuOptions, searchBoxValue } = this.state;
        return (
            <Paper className={classNames(classes.paper, classes.container, 'container')} elevation={5} square={false}>
                <Grid container={true} style={{ marginTop: 2 }}>
                    <Grid item={true} xs={12} sm={10}>
                        <SearchBox
                            handleClickListItem={this.handleClickListItem}
                            anchorEl={anchorEl}
                            handleClose={this.handleClose}
                            filterMenuOptions={filterMenuOptions}
                            selectedFilterMenuIndex={selectedFilterMenuIndex}
                            handleMenuItemClick={this.handleMenuItemClick}
                            selectedFilterMenu={selectedFilterMenu}
                            searchBoxValue={searchBoxValue}
                            handleSearchBox={this.handleSearchBox}
                            onEnterKeyPress={this.onEnterKeyPress}
                            gridLabel={'Readings'} />
                    </Grid>
                    <Grid item={true} xs={12} sm={2}>

                    </Grid>
                </Grid>
                {(Array.isArray(sensorReadings) ) &&

                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row.sensorReadingId}
                            rows={sensorReadings}
                            columns={this.columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            components={{
                                Toolbar: () => (
                                    <GridToolbar
                                      sx={{float: "right", 
                                      position: 'absolute',
                                      top:'-10%',
                                      right: '1%'
                                    }}
                                    />
                                  ),
                            }}
                            disableColumnFilter
                        /> 
                    </Box>}
                {/* <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} />
                {(Array.isArray(sensorReadings) && sensorReadings.length > 0) ? 
                            sensorReadings.slice(this.state.sensorReadingTablePage * this.state.sensorReadingTableRowsPerPage,
                                this.state.sensorReadingTablePage * this.state.sensorReadingTableRowsPerPage + this.state.sensorReadingTableRowsPerPage).map((sensorReading: any, sensorIndex: number) => {
                                    const timeStamp = sensorReading.sensorReadingDateTimeValue ? +sensorReading.sensorReadingDateTimeValue : null;
                                    return (
                                        <Grid container={true}  key={`sensorReading${sensorIndex}`}
                                            className={classes.actionArea} justifyContent={"space-between"}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                boxShadow: 3,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                // marginTop: 1,
                                                // marginBottom: 1,
                                                padding:0.5,
                                                    ':hover': {
                                                      boxShadow: 20,
                                                      backgroundColor:'#c2e3f1'
                                                    },
                                                
                                            }}>
            
                                            <Grid item xs={6} sm={2} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline', 
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                               {sensorReading.device ? sensorReading.device.name : ''}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Sensor Name"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline',
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                                {sensorReading.device ? sensorReading.device.nickName : ''}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Device Nick Name"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={1} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline', 
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                               {sensorReading.device ? sensorReading.device.imei : ''}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"IMEI"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={1} md={1}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline',
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                                {sensorReading.sensorType === 'battery_temp' &&
                                                                    <Tooltip placement="top" title={sensorReading.name} >
                                                                        <div>
                                                                            <span><FaBatteryFull color='green'/> :  <FaThermometerQuarter color='#4f4ff1' /></span>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                                {sensorReading.sensorType === 'battery_voltage' &&
                                                                    <Tooltip placement="top" title={sensorReading.name} >
                                                                        <div>
                                                                            <span> <FaBatteryFull color='green'/> : <FaBolt color='orange' /></span>                                                            
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                                {sensorReading.sensorType === 'motion_sensor' &&
                                                                    <Tooltip placement="top" title={sensorReading.name} >
                                                                        <div> <FaHandSparkles color="darkgoldenrod" /></div>
                                                                    </Tooltip>
                                                                }
                                                                {sensorReading.sensorType === 'pressure_sensor_1' &&
                                                                    <Tooltip placement="top" title={sensorReading.name} >
                                                                        <div> <IoSpeedometerOutline /></div>
                                                                    </Tooltip>
                                                                }
                                                                {sensorReading.sensorType === 'pressure_sensor_2' &&
                                                                    <Tooltip placement="top" title={sensorReading.name} >
                                                                        <div> <IoSpeedometerSharp /></div>
                                                                    </Tooltip>
                                                                }
                                                                {sensorReading.sensorType === 'humidtytemp_sensor_2' &&
                                                                    <Tooltip placement="top" title={sensorReading.name} >
                                                                        <div> <MdWaterDrop color='dodgerblue' /></div>
                                                                    </Tooltip>
                                                                }
                                                                {sensorReading.sensorType === 'humidtytemp_sensor_1' &&
                                                                    <Tooltip placement="top" title={sensorReading.name} >
                                                                        <div> <MdOutlineWaterDrop color='dodgerblue'/></div>
                                                                    </Tooltip>
                                                                }
                                                                {sensorReading.sensorType === 'light_sensor' &&
                                                                    <Tooltip placement="top" title={sensorReading.name} >
                                                                        <div>  <FcIdea /></div>
                                                                    </Tooltip>
                                                                }
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Type"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={1}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline',
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                              {this.getSensorTypeBasedData(sensorReading)}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Value"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline', 
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                            {(sensorReading.sensorReadingDateTime) ? new Date(sensorReading.sensorReadingDateTime).toLocaleDateString('en-US', dateOption) + ' ' + new Date(sensorReading.sensorReadingDateTime).toLocaleTimeString('en-US', timeOption) : '--'}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Measurement Time"}
                                                />
                                            </Grid>
                                                    
                                        </Grid>
                                    )
                                })
                : null}
                <Typography component="div">
              
                    {(Array.isArray(sensorReadings) && sensorReadings.length > 0) ?
                        <TablePagination
                            component="div"
                            count={totalResults ? totalResults : 0}
                            rowsPerPage={this.state.sensorReadingTableRowsPerPage}
                            page={this.state.sensorReadingTablePage}
                            backIconButtonProps={{ 'aria-label': 'Previous Page', 'onClick': this.loadPreviousPage }}
                            nextIconButtonProps={{ 'aria-label': 'Next Page', 'onClick': this.loadNextPage, }}
                            onPageChange={this.handleChangesensorReadingPage}
                            onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                        /> : null}
                    {(Array.isArray(sensorReadings) && sensorReadings.length == 0) ?
                        <div style={{ 'textAlign': 'center','margin':10 }}>No Record Found</div>
                    : null}
                </Typography>  */}
            </Paper>)
    }
}
const styles = (theme: Theme) => ({
    paper: {
        overflow: 'hidden',
        color: theme.palette.text.secondary,
    },
    container: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    tableContainer: {
        padding: '1px',
        overflow: 'auto'
    },
    iconPointer: {
        cursor: 'pointer'
    },
    dialogContainer: {
        width: '50%',
        height: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            margin: 0,
            maxWidth: '100%',
            maxHeight: 'none',
            borderRadius: 0,
        },
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    tableHeaderBg: {
        backgroundColor: '#1976d2',
        fontWeight: 700,
    }
});
const mapStateToProps = (state: any) => {
    return {
        sensorReadings: state.sensorReading?.sensorReadings,
        totalResults: state.sensorReading?.totalResults,
        utility: state?.utility,
    }
}
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getSensorReadingsDetailsByRange: SensorReadingActions.getSensorReadingsDetailsByRange,
        refresh:SensorReadingActions.refresh

    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchtoProps)(withStyles(styles as any)(SensorReadings as any)) as any);