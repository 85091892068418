import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables'
import { AppState } from '../../state/AppState';

export function showLoader() {
  return {
    type: ActionType.OPEN_SPINNER
  };
}

export function hideLoader() {
  return {
    type: ActionType.CLOSE_SPINNER
  };
}
export function userLogout() {
  return {
    type: ActionType.USER_LOGOUT
  };
}



export const getLoginUserDetailsByTokenSuccess = (response: any) => {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_SUCCESS,
    payload: response
  }
};
export const getLoginUserDetailsByTokenFail = (error: any) => {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_FAILURE,
    payload: error
  }
};

export const getLoginUserDetailsByToken = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.LOGIN_USER_DETAILS_SHOW_LOADER })
  return new Promise((resolve, reject) => {
    api(ApiVariables.loginUserApi.getLoginUserDetails).then((response: any) => {
      dispatch({ type: ActionType.GET_LOGIN_USER_DETAILS_SUCCESS, payload: response.data });
      dispatch({ type: ActionType.LOGIN_USER_DETAILS_HIDE_LOADER });
      resolve(response);
    }).catch((error: any) => {
      dispatch({ type: ActionType.LOGIN_USER_DETAILS_HIDE_LOADER });
      dispatch({ type: ActionType.GET_LOGIN_USER_DETAILS_FAILURE, payload: error });
      reject(error);
    })
  })
}
