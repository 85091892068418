import { combineReducers } from 'redux';
import { auth } from './Auth/Auth.Reducer'
import { common } from './Common.Reducers';
import devicesReducer from './DevicesReducer';
import customersReducer from './CustomerReducer';
import sensorReadingsReducer from "./SensorReadingsReducer";
import unwiredUsersReducer from './UsersReducer';
import LocationReadingsReducer from './LocationReadingsReducer';
import notificationReducer from './NotificationReducer';
const rootReducer = combineReducers({
    common,
    auth: auth,
    device: devicesReducer,
    customer: customersReducer,
    sensorReading: sensorReadingsReducer,
    locationReading:LocationReadingsReducer,
    unwiredUser: unwiredUsersReducer,
    notification:notificationReducer

});
export default rootReducer;
