import IActiveInActiveModel from '../../models/common/IActiveInActiveModel';
import { ActionType, IAppAction } from '../actions/Helpers';
const initialState = {
    notificationList: Array(),
    totalResults: Number(0),
    notificationPreviousCount: Number(0)
}
const notificationReducer = (state = initialState, action: IAppAction) => {
    switch (action.type) {
        case ActionType.GET_NOTIFICATION_LIST:
            let updateGridArray: any[] = [];
            if (action.payload.data) {
                if (state.notificationList.length === 0) {
                    updateGridArray.push(...action.payload.data)
                } 
                else if ((state.totalResults - state.notificationList.length === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.notificationList]
                    }
                    updateGridArray.push(...action.payload.data)
                } else if (action.payload.data.length === state.totalResults) {
                    updateGridArray.push(...action.payload.data)
                }
                else if ((state.notificationPreviousCount === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.notificationList]
                    }
                    updateGridArray.push(...action.payload.data)
                } else {
                    updateGridArray.push(...action.payload.data)
                }
            }
            return {
                ...state, notificationList: updateGridArray, notificationPreviousCount: action.payload.data ? action.payload.data.length : 0,
                totalResults: action.payload.totalResults ? action.payload.totalResults : 0
            };
        default:
            return state
    };
};
export default notificationReducer;
