import { ActionType, IAppAction } from '../actions/Helpers';

const initialState = {
    customers: Array(),
    totalResults: Number(0),
    editCustomerDetails: Object(null),
    dashboardCustomerDetails: Array(),
    totalCustomer: Number(0),
    customerPreviousCount: Number(0),
}
const customersReducer = (state = initialState, action: IAppAction) => {
    switch (action.type) {
        case ActionType.GET_CUSTOMERS:
            let updateGridArray: any[] = [];
            if (action.payload.data) {
                if (state.customers.length === 0) {
                    updateGridArray.push(...action.payload.data)
                } else if ((state.totalResults - state.customers.length === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.customers]
                    }
                    updateGridArray.push(...action.payload.data)
                } else if (action.payload.data.length === state.totalResults) {
                    updateGridArray.push(...action.payload.data)
                }
                else if ((state.customerPreviousCount === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.customers]
                    }
                    updateGridArray.push(...action.payload.data)
                } else {
                    updateGridArray.push(...action.payload.data)
                }
            }
            return { ...state, customers: updateGridArray, customerPreviousCount: action.payload.data ? action.payload.data.length : 0, totalResults: action.payload.totalResults ? action.payload.totalResults : 0 };
        case ActionType.EDIT_CUSTOMER_DETAILS:
            return { ...state, editCustomerDetails: action.payload, };
        case ActionType.GET_OU_CUSTOMER_DASHBOARD_DETAILS:
            const updateValue: any[] = action.payload.data;
            updateValue.forEach((customer: any, index: number) => {
                updateValue[index].totalDevices = +customer.totalDevices;
            })
            return { ...state, dashboardCustomerDetails: updateValue, totalCustomer: action.payload.totalResults };
        default:
            return state
    };
};
export default customersReducer;