import IActiveInActiveModel from '../../models/common/IActiveInActiveModel';
import { ActionType, IAppAction } from '../actions/Helpers';
const initialState = {
    devices: Array(),
    totalResults: Number(0),
    editDeviceDetail: Object(null),
    devicePreviousCount: Number(0),
    puDashBoardDeviceDetails: Array(),
    puDeviceActiveAndInActiveCount: Object(null),
    ouDeviceActiveAndInActiveCount: Object(null),
    ouDashBoardDeviceDetails: Array(),
    puMessageAlertDetails: Array(),
    sensorDetailsByDeviceId: Array(),
    deviceAvailableList: Array(),
    boundaryDetailsByDeviceId: Object(null),
}
const devicesReducer = (state = initialState, action: IAppAction) => {
    switch (action.type) {
        case ActionType.GET_DEVICES:
            let updateGridArray: any[] = [];
            if (action.payload.data) {
                if (state.devices.length === 0) {
                    updateGridArray.push(...action.payload.data)
                } else if ((state.totalResults - state.devices.length === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.devices]
                    }
                    updateGridArray.push(...action.payload.data)
                } else if (action.payload.data.length === state.totalResults) {
                    updateGridArray.push(...action.payload.data)
                }
                else if ((state.devicePreviousCount === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateGridArray = [...state.devices]
                    }
                    updateGridArray.push(...action.payload.data)
                } else {
                    updateGridArray.push(...action.payload.data)
                }
            }
            return {
                ...state, devices: updateGridArray, devicePreviousCount: action.payload.data ? action.payload.data.length : 0,
                totalResults: action.payload.totalResults ? action.payload.totalResults : 0
            };
        case ActionType.EDIT_DEVICE_DETAILS:
            return { ...state, editDeviceDetail: action.payload };
        case ActionType.GET_PU_DEVICE_DASHBOARD_DETAILS:
            const deviceDashBoard = action.payload.data;
            const activeAndInActiveObject = { "activeCount": 0, "inActiveCount": 0 };
            deviceDashBoard.forEach((element: IActiveInActiveModel) => {
                if (element.name === "Active") {
                    activeAndInActiveObject.activeCount = element.value;
                } else if (element.name === "InActive") {
                    activeAndInActiveObject.inActiveCount = element.value;
                }
            })
            return { ...state, puDashBoardDeviceDetails: action.payload.data, puDeviceActiveAndInActiveCount: activeAndInActiveObject };
        case ActionType.GET_OU_DEVICE_DASHBOARD_DETAILS:
            const deviceDashBoardOU = action.payload.data;
            const activeAndInActiveObjectOU = { "activeCount": 0, "inActiveCount": 0 };
            deviceDashBoardOU.forEach((element: IActiveInActiveModel) => {
                if (element.name === "Active") {
                    activeAndInActiveObjectOU.activeCount = element.value;
                } else if (element.name === "InActive") {
                    activeAndInActiveObjectOU.inActiveCount = element.value;
                }
            })
            return { ...state, ouDashBoardDeviceDetails: action.payload.data, ouDeviceActiveAndInActiveCount: activeAndInActiveObjectOU };
        case ActionType.GET_DASHBOARD_NOTIFICATIONS:
            return { ...state, puMessageAlertDetails: action.payload.data };
        case ActionType.GET_SENSOR_DETAILS_BY_DEVICE_ID:
            return { ...state, sensorDetailsByDeviceId: action.payload.data };
        case ActionType.GET_DEVICES_DROP_DOWN_LIST:
            return { ...state, deviceAvailableList: action.payload };
        case ActionType.GET_BOUNDARY_DETAILS_BY_DEVICE_ID:
                return { ...state, boundaryDetailsByDeviceId: action.payload };
        default:
            return state
    };
};
export default devicesReducer;
