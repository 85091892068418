import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import SignIn from './SignIn';
const LoginButton = (): JSX.Element => {
    const { isAuthenticated } = useAuth0();
    if (!isAuthenticated) {
        return <SignIn />
    } else {
        return <></>
    }
}
export default LoginButton;