import * as React from 'react';
import {
    Paper, Table, TableHead, TableRow,
    TableCell, TableBody, TablePagination,
    Theme, Typography, Button,
    Grid, Dialog
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as CustomersActions from '../../store/actions/Customers.Action';
import { withRouter } from 'react-router';
import ICustomer from '../../models/customer/ICustomerView';
import IFilterMenuOptionsModel from '../../models/common/IFilterMenuOptionsModel';
import { SearchBox } from '../../components/search/SearchBox';
import IFilterModel from '../../models/common/IFilterModel';
import { IUtility } from '../../state/Utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faShoppingCart, faTruck, faMapMarker, faObjectGroup, faEdit, } from '@fortawesome/free-solid-svg-icons'
import ICustomerUserDetailMapView from '../../models/customer/ICustomerUserDetailMapView';
import MapComponent from '../../components/map/MapComponent';
import { DialogContent, DialogTitle } from '../../components/dialog/DialogTitle';
import Geocode from "react-geocode";
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import Box from '@mui/material/Box';
import { GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid/components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models';
const classNames = require('classnames');

declare var google: any;
interface IPageProps {
    customers: ICustomerUserDetailMapView[],
    classes?: any,
    history?: any;
    totalResults: number,
    getCustomersDetailsByRange: (start: number, limit: number, parameterName?: string, parameterValue?: string) => void;
    editCustomer: (customer: ICustomer) => void;
    utility: IUtility;
    getDeviceMapViewByCustomerId: (customerId: number) => any;
}

interface IPageState {
    title: string;
    customersTablePage: number,
    customersTableRowsPerPage: number,
    anchorEl: any;
    selectedFilterMenuIndex: number,
    filterMenuOptions: IFilterMenuOptionsModel[],
    selectedFilterMenu: string | number | boolean;
    selectedFilterMenuKey: string;
    searchBoxValue: string;
    searchBoxFilterKey: string,
    pageStartCount: number;
    openMapView: boolean;
    zoom: number,
    center: ILatAndLng;
    address: string;
    markers: any[];
    centerName: string
}
interface ILatAndLng {
    lat: any;
    lng: any;
}
class Customers extends React.Component<IPageProps, IPageState> {
    constructor(props: IPageProps) {
        super(props);
        this.state = {
            title: 'welcome',
            customersTablePage: 0,
            customersTableRowsPerPage: 10,
            pageStartCount: 10,
            anchorEl: null,
            selectedFilterMenuIndex: 0,
            filterMenuOptions: [
                { "label": 'Customer Name', "key": 'name' },
                { "label": 'City', "key": 'city' },
                { "label": 'State', "key": 'state' },
                { "label": 'Zip Code', "key": 'zipcode' },
            ],
            selectedFilterMenu: 'Customer Name',
            selectedFilterMenuKey: 'name',
            searchBoxValue: '',
            searchBoxFilterKey: '',
            openMapView: false,
            zoom: 15,
            address: '',
            centerName: '',
            markers: [],
            center: {
                lat: null,
                lng: null
            },
        }
    }

    public componentWillMount() {
        this.props.getCustomersDetailsByRange(0, 10);
    }
    public componentDidMount() {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
    }
    private handleChangeCustomersPage = (event: any, page: number) => {
        console.log('page', page)
    };

    private handleChangeTableRowsPerPage = async (event: any) => {
        await this.props.getCustomersDetailsByRange(0, event.target.value as number, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ customersTableRowsPerPage: event.target.value, pageStartCount: event.target.value, customersTablePage: 0 });
    };

    private loadNextPage = async () => {
        await this.props.getCustomersDetailsByRange(this.state.pageStartCount, this.state.customersTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ customersTablePage: this.state.customersTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.customersTableRowsPerPage });
    }

    private loadPreviousPage = () => {
        this.setState({ customersTablePage: this.state.customersTablePage - 1, pageStartCount: this.state.pageStartCount - this.state.customersTableRowsPerPage })
    }

    private editCustomerRecord = (customer: ICustomer) => {
        this.props.editCustomer(customer);
        this.props.history.push(`/edit-customer/${customer.customerId}`)
    }
    private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: IFilterModel) => {
        this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key })
    };
    private handleClose = () => {
        this.setState({ anchorEl: null, });
    }
    private handleSearchBox = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private onEnterKeyPress = (event: any) => {
        if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
            this.callSearchByParam();
        }
    }
    private callSearchByParam = async () => {
        await this.props.getCustomersDetailsByRange(0, this.state.customersTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    }

    private gotoCustomerAddPage = () => {
        this.props.history.push('/add-customer')
    }

    private gotoDeviceGrid = (customer: ICustomerUserDetailMapView) => {
        this.props.history.push({
            pathname: '/devices',
            state: { 'customerName': customer.name }
        })
    }
    private openMapPopUp = async (customer: ICustomerUserDetailMapView) => {
        const address = customer.address1 + (customer.address1 ? ' ' : '') + customer.address2 + (customer.address2 ? ', ' : '') + customer.city + (customer.city ? ', ' : '') + customer.state + (customer.state ? ', ' : '') + customer.zip;
        this.setState({ address, centerName: `${customer.name}` })
        if (address && address !== "") {
            await Geocode.fromAddress(address).then((response: any) => {
                this.setState({ center: { lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng } })
            }, error => {
                console.error(error);
            });
        }
        // await this.props.getDeviceMapViewByCustomerId(customer.customerUserDetailMapViewId.customerId).then(async (response: any) => {
        //     if (response.data) {
        //         this.setState({ markers: response.data })
        //     }
        // }).catch((response: any) => {
        //     console.error(response)
        // });
        this.setState({ openMapView: true })
    }
    private handleCloseMapView = () => {
        this.setState({ openMapView: false })
        this.setState({ center: { lat: null, lng: null, }, address: '', centerName: '', markers: [] })
    }
    columns: GridColDef[] = [
        {
          field: 'name',
          headerName: 'Customer Name',
          width: 150,
          editable:false,
        },
        {
          field: 'nickName',
          headerName: 'Address',
          width: 500,
          editable:false,
          valueGetter:this.getAddress
        },
        {
            field: 'contact',
            headerName: 'Admin Contact',
            width: 150,
            editable:false,
            valueGetter:this.getContact
          },
          {
            field: 'device',
            headerName: 'Devices',
            width: 200,
            editable:false,
             renderCell: (params: GridRenderCellParams<any>) => (
                <>
                    <FontAwesomeIcon className={this.props.classes.iconPointer} icon={faObjectGroup as any} onClick={() => this.gotoDeviceGrid(params.row)} />&nbsp;&nbsp;&nbsp;<FontAwesomeIcon className={this.props.classes.iconPointer} icon={faMapMarker as any} onClick={() => this.openMapPopUp(params.row)} />
                </>
            ),
          },
          {
            field: 'edit',
            headerName: '',
            editable:false,
            width: 120,
            renderCell: (params: GridRenderCellParams<any>) => (
              <>
               <FontAwesomeIcon className={this.props.classes.iconPointer} icon={faEdit as any} onClick={() => this.editCustomerRecord(params.row)} />
              </>
            ),
          },
      ];

    getAddress(param){
        return `${param.row.address1 ? param.row.address1 + param.row.city + param.row.state + param.row.zip : '--'}`;
    }
    getContact(param){
        return `${param.row.contactFirstName ? param.row.contactFirstName + param.row.contactLastName :'--'}`;
    }
    CustomToolbar() {
        return (
          <GridToolbarContainer sx={{float: "right", 
          position: 'absolute',
          top:'-11%',
          right: '1%',
          justifyContent:'space-between'
        }}>
            <GridToolbarColumnsButton onResize={undefined} onResizeCapture={undefined} />
            <GridToolbarDensitySelector onResize={undefined} onResizeCapture={undefined} />
            <GridToolbarExport/>
            <Button onClick={() => this.gotoCustomerAddPage()} style={{ backgroundColor: '#f6f6f6', }} color="primary">Add Customer</Button>
          </GridToolbarContainer>
        );
      }
    public render(): JSX.Element {
        const { customers, classes, totalResults } = this.props; // utility
        const { selectedFilterMenuIndex, anchorEl, selectedFilterMenu, filterMenuOptions,
            searchBoxValue, openMapView, zoom, address, center, centerName, markers } = this.state;

        return (<Paper className={classNames(classes.paper, classes.container, 'container')} elevation={5} square={false}>

            <Grid container={true} style={{ marginTop: 5 }}>
                <Grid item={true} xs={12} sm={10}>
                    <SearchBox
                        handleClickListItem={this.handleClickListItem}
                        anchorEl={anchorEl}
                        handleClose={this.handleClose}
                        filterMenuOptions={filterMenuOptions}
                        selectedFilterMenuIndex={selectedFilterMenuIndex}
                        handleMenuItemClick={this.handleMenuItemClick}
                        selectedFilterMenu={selectedFilterMenu}
                        searchBoxValue={searchBoxValue}
                        handleSearchBox={this.handleSearchBox}
                        onEnterKeyPress={this.onEnterKeyPress}
                        gridLabel={'Customers'} />
                </Grid>
                {/* <Grid item={true} xs={12} sm={2}>
                    <Typography component={'div'} style={{ display: 'flex', justifyContent: 'end', paddingRight: 8, paddingTop: 8 }}>
                        <Button onClick={() => this.gotoCustomerAddPage()} style={{ backgroundColor: '#f6f6f6', }} color="primary">Add Customer</Button>
                    </Typography>
                </Grid> */}
            </Grid>
            {(Array.isArray(customers)) &&
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row.customerId}
                        rows={customers}
                        columns={this.columns}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        components={{
                            Toolbar: this.CustomToolbar,
                            // Toolbar: () => (
                            //     <GridToolbar
                            //       sx={{float: "right", 
                            //       position: 'absolute',
                            //       top:'-10%',
                            //       right: '10%'
                            //     }}
                            //     />
                            //   ),
                        }}
                        disableColumnFilter
                    />
                </Box>
            }
            {/* <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} />
            <Typography component="div" className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Admin Contact</TableCell>
                            <TableCell>Devices</TableCell>
                        </TableRow>
                    </TableHead>
                    {(Array.isArray(customers) && customers.length > 0) ? <TableBody>
                        {customers.slice(this.state.customersTablePage * this.state.customersTableRowsPerPage,
                            this.state.customersTablePage * this.state.customersTableRowsPerPage + this.state.customersTableRowsPerPage).map((customer: ICustomerUserDetailMapView, customerIndex: number) => {
                                return (
                                    <TableRow key={`customer${customerIndex}`} onDoubleClick={() => this.editCustomerRecord(customer)}>
                                        <TableCell >{customer.name}</TableCell>
                                        <TableCell>{customer.address1 ? customer.address1 + customer.city + customer.state + customer.zip : ''}</TableCell>
                                        <TableCell>{customer.contactFirstName ? customer.contactFirstName + customer.contactLastName : ''}</TableCell>
                                        <TableCell>
                                            <FontAwesomeIcon className={classes.iconPointer} icon={faObjectGroup as any} onClick={() => this.gotoDeviceGrid(customer)} />&nbsp;&nbsp;&nbsp;<FontAwesomeIcon className={classes.iconPointer} icon={faMapMarker as any} onClick={() => this.openMapPopUp(customer)} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody> : null}
                </Table>
                {(Array.isArray(customers) && customers.length > 0) ? <TablePagination
                    component="div"
                    count={totalResults ? totalResults : 0}
                    rowsPerPage={this.state.customersTableRowsPerPage}
                    page={this.state.customersTablePage}
                    backIconButtonProps={{ 'aria-label': 'Previous Page', 'onClick': this.loadPreviousPage }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page', 'onClick': this.loadNextPage, }}
                    onPageChange={this.handleChangeCustomersPage}
                    onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                /> : null}
            </Typography> */}
            <Dialog
                classes={{ paper: classes.dialogContainer }}
                onClose={() => this.handleCloseMapView()}
                maxWidth="md"
                fullWidth={true}
                aria-labelledby="Items-View"
                open={openMapView}>
                <DialogTitle
                    id="device-Map"
                    onClose={() => this.handleCloseMapView()}
                    style={{ textAlign: 'center' }}>Map Window</DialogTitle>
                <DialogContent >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} sm={12}>
                            <MapComponent mapViewFrom={'customerView'}
                                markersOptions={markers}
                                zoom={zoom}
                                address={address}
                                centerName={centerName}
                                flightPositions={[]}
                                center={center}
                                containerStyle={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '55vh'
                                }} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Paper>)
    }
}
const styles = (theme: Theme) => ({
    paper: {
        overflow: 'hidden',
        color: theme.palette.text.secondary,
    },
    container: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    tableContainer: {
        padding: '1px',
        overflow: 'auto'
    },
    iconPointer: {
        cursor: 'pointer'
    },
    dialogContainer: {
        // width: '50%',
        // height: '50%',
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%',
        //     height: '100%',
        //     margin: 0,
        //     maxWidth: '100%',
        //     maxHeight: 'none',
        //     borderRadius: 0,
        // },
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    tableHeaderBg: {
        backgroundColor: '#1976d2',
        fontWeight: 500,
    }
});

const mapStateToProps = (state: any) => {
    return {
        customers: state.customer?.customers,
        totalResults: state.customer?.totalResults,
        utility: state.utility,
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getCustomersDetailsByRange: CustomersActions.getCustomersDetailsByRange,
        editCustomer: CustomersActions.editCustomerDetails,
        getDeviceMapViewByCustomerId: CustomersActions.getDevicesByCustomerIdToShowTheMapMarkers
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(Customers as any)) as any);