const Measurement = [
    {
        "measurementIntervalValue": "1",
        "measurementIntervalName": "Hourly"
    },
    {
        "measurementIntervalValue": "4",
        "measurementIntervalName": "Every 4 Hours"
    },
    {
        "measurementIntervalValue": "12",
        "measurementIntervalName": "Every 12 Hours"
    },
    {
        "measurementIntervalValue": "24",
        "measurementIntervalName": "Daily"
    },
]

export default Measurement;