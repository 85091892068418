import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables';
import IUser from '../../models/user/IUser';
import { Alert } from '../../state/Alert';
import { toast } from 'react-toastify';
export const getUsersByRange = (start: number, limit: number, parameterName?: string, parameterValue?: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        if (localStorage.getItem('userType')) {
            if (localStorage.getItem('userType') === 'PU') {
                ApiVariables.users.getUsersByRangeAsPU.id = localStorage.getItem('userCustomerId') as any;
                ApiVariables.users.getUsersByRangeAsPU.setStart = start;
                ApiVariables.users.getUsersByRangeAsPU.setLimit = limit;
                ApiVariables.users.getUsersByRangeAsPU.setParameterName = parameterName;
                ApiVariables.users.getUsersByRangeAsPU.setParameterValue = parameterValue;
                api(ApiVariables.users.getUsersByRangeAsPU).then((response: any) => {
                    dispatch({ type: ActionType.GET_USERS, payload: response });
                    dispatch({ type: ActionType.CLOSE_SPINNER });
                    resolve(response);
                }).catch((response: any) => {
                    dispatch({ type: ActionType.CLOSE_SPINNER })
                    reject(response);
                })
            } else {
                ApiVariables.users.getUsersByRangeAsOwnerOrAU.setStart = start;
                ApiVariables.users.getUsersByRangeAsOwnerOrAU.setLimit = limit;
                ApiVariables.users.getUsersByRangeAsOwnerOrAU.setParameterName = parameterName;
                ApiVariables.users.getUsersByRangeAsOwnerOrAU.setParameterValue = parameterValue;
                api(ApiVariables.users.getUsersByRangeAsOwnerOrAU).then((response: any) => {
                    dispatch({ type: ActionType.GET_USERS, payload: response });
                    dispatch({ type: ActionType.CLOSE_SPINNER });
                    resolve(response);
                }).catch((response: any) => {
                    dispatch({ type: ActionType.CLOSE_SPINNER })
                    reject(response);
                })
            }

        }
    })
};

export const editUserDetails = (userDetals: IUser): IAppAction => {
    return { type: ActionType.EDIT_USER_DETAILS, payload: userDetals };
};
export const resetEditUserDetails = (): IAppAction => {
    return { type: ActionType.EDIT_USER_DETAILS, payload: {} };
};

export const resetUserGrid = (): IAppAction => {
    return { type: ActionType.GET_USERS, payload: {} };
};


export const getUsersRangeByDeviceId = (deviceId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.users.getUsersRangeByDeviceId.id = deviceId;
        ApiVariables.users.getUsersRangeByDeviceId.setLimit = 25;
        api(ApiVariables.users.getUsersRangeByDeviceId).then((response: any) => {
            dispatch({ type: ActionType.GET_USER_SELECTED, payload: response });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
}
export const getUserById = (userId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.users.getUserById.userId = userId;
        api(ApiVariables.users.getUserById).then((response: any) => {
            dispatch({ type: ActionType.EDIT_USER_DETAILS, payload: response.data });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
}

export const getUserProfileById = (userId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.users.getUserById.userId = userId;
        api(ApiVariables.users.getUserById).then((response: any) => {
            dispatch({ type: ActionType.USER_PROFILE_DETAILS, payload: response });
            resolve(response);
            dispatch({ type: ActionType.CLOSE_SPINNER });
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}
export const reseprofileStore = (): IAppAction => {
    return { type: ActionType.USER_PROFILE_DETAILS, payload: {} };
};

export const putUpdateUserById = (userId: string, body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.users.putUpdateUserById.userId = userId;
        api({ ...ApiVariables.users.putUpdateUserById, body }).then((response: any) => {
            if (response.status.success === "SUCCESS") {
                toast(response.status.message, { type: 'success' });
            } else if (response.status.success === "FAILED") {
                toast(response.status.message, { type: 'error' });
            }
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            toast("Something went wrong", { type: toast.TYPE.ERROR });
            reject(response);
        })
    })
}

export const putAddUserByDeviceId = (deviceId: string, body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.users.putAddUserByDeviceId.id = deviceId;
        api({ ...ApiVariables.users.putAddUserByDeviceId, body }).then((response: any) => {
            // toast(response.status.message, { type: 'success' });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            toast("Something went wrong", { type: toast.TYPE.ERROR });
            reject(response);
        })
    })
}


export const postRegisterUser = (body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        api({ ...ApiVariables.users.postRegisterUser, body }).then((response: any) => {
            toast(response.status.message, { type: 'success' });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            toast("Something went wrong", { type: toast.TYPE.ERROR });
            reject(response);
        })
    })
}
export const postSaveUserByCustomerId = (customerId: string, body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.users.putSaveUserByCustomerId.customerId = customerId;
        api({ ...ApiVariables.users.putSaveUserByCustomerId, body }).then((response: any) => {
            toast(response.status.message, { type: 'success' });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            toast("Something went wrong", { type: toast.TYPE.ERROR });
            reject(response);
        })
    })
}

export const sendInviteToUser = (userName: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.users.sendInviteToUserMail.setUserName = userName;
        api(ApiVariables.users.sendInviteToUserMail).then((response: any) => {
            if (response.responseMessage === "Mail Sent Successfully") {
                dispatch({ type: ActionType.OPEN_ALERT, payload: new Alert({ message: response.responseMessage, alertType: 'success' }) })
            } else {
                dispatch({ type: ActionType.OPEN_ALERT, payload: new Alert({ message: response.responseMessage, alertType: "error" }) })
            }
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            toast("Something went wrong", { type: toast.TYPE.ERROR });
            reject(response);
        })
    })
}





