import { Auth0Provider, withAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import history from '../@history';
import './App.css';
import AppContext from './AppContext';
import Layout from './layout/Layout';
import routes from './route-configs/routes.config';
import store from './store';
import { ThemeProvider, createTheme} from '@mui/material';
import "@fontsource/nunito-sans"
const THEME = createTheme({
  typography: {
   "fontFamily": ` "Nunito Sans", sans-serif`,
  //  "fontSize": 14,
   "fontWeightLight": 200,
   "fontWeightRegular": 500,
   "fontWeightMedium": 600
  }
});
function App() {
  return (
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : ''}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
      // useRefreshTokens={true}
      // cacheLocation={"localstorage"}
      >
          <ThemeProvider theme={THEME}>
        <AppContext.Provider value={{ routes }}>
          <Provider store={store}>
            <BrowserRouter >
              <Layout history={history} />
            </BrowserRouter>
          </Provider>
        </AppContext.Provider>
        </ThemeProvider>


      </Auth0Provider>
    </React.StrictMode>
  );
}

export default withAuth0(App);
