const CommunicationInterval = [
    {
        "communicationIntervalValue": 0,
        "communicationIntervalName": ""
    },
    {
        "communicationIntervalValue": 1,
        "communicationIntervalName": "Hourly"
    },
    {
        "communicationIntervalValue": 4,
        "communicationIntervalName": "Every 4 Hours"
    },
    {
        "communicationIntervalValue": 12,
        "communicationIntervalName": "Every 12 Hours"
    },
    {
        "communicationIntervalValue": 24,
        "communicationIntervalName": "Daily"
    },
]

export default CommunicationInterval;