import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables';
import IDevice from '../../models/device/IDeviceView';
import { toast } from 'react-toastify';
export const getDevicesByRange = (start: number, limit: number, parameterName: string, parameterValue: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.devices.getDevicesByRange.setStart = start;
        ApiVariables.devices.getDevicesByRange.setLimit = limit;
        ApiVariables.devices.getDevicesByRange.setParameterName = parameterName;
        ApiVariables.devices.getDevicesByRange.setParameterValue = parameterValue;
        api(ApiVariables.devices.getDevicesByRange).then((response: any) => {
            dispatch({ type: ActionType.GET_DEVICES, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const postETAAddress = (body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        api({ ...ApiVariables.devices.postETAAddress, body }).then((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            toast.success("ETA got saved")
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            toast.error("Something went worng")
            reject(response);
        })
    })
};


export const editDeviceDetails = (deviceDetals: IDevice): IAppAction => {
    return { type: ActionType.EDIT_DEVICE_DETAILS, payload: deviceDetals };
};

export const resetEditDeviceDetails = (): IAppAction => {
    return { type: ActionType.EDIT_DEVICE_DETAILS, payload: {} };
};

export const resetDeviceGrid = (): IAppAction => {
    return { type: ActionType.GET_DEVICES, payload: [] };
};

export const exportDevicesData = () => {
    const url = process.env?.REACT_APP_BASE_URL + 'reports/devices/download?token=' + localStorage.getItem("auth-token");
    window.location.assign(url);
};

export const getDeviceById = (deviceId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        ApiVariables.devices.getDeviceById.id = deviceId
        await api(ApiVariables.devices.getDeviceById).then(async (response: any) => {
            dispatch({ type: ActionType.EDIT_DEVICE_DETAILS, payload: response.data as IDevice });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const postSaveDeviceDetails = (body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        await api({ ...ApiVariables.devices.postSaveDeviceDetails, body }).then(async (response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const putUpdateDeviceDetailsByDeviceId = (deviceId: string, body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        ApiVariables.devices.putSaveDeviceDetails.deviceId = deviceId;
        await api({ ...ApiVariables.devices.putSaveDeviceDetails, body }).then(async (response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};


export const getPUDashBoradDeviceDetails = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        await api(ApiVariables.devices.getPUActiveAndInActiveDeviceCout).then(async (response: any) => {
            dispatch({ type: ActionType.GET_PU_DEVICE_DASHBOARD_DETAILS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const getOUDashBoradDeviceDetails = (ownerId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        ApiVariables.devices.getOUActiveAndInActiveDeviceCout.id = ownerId
        await api(ApiVariables.devices.getOUActiveAndInActiveDeviceCout).then(async (response: any) => {
            dispatch({ type: ActionType.GET_OU_DEVICE_DASHBOARD_DETAILS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const getPUDashBoradAlerts = (duration: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        ApiVariables.devices.getPUDashBoradAlerts.duration = duration as unknown as string;
        await api(ApiVariables.devices.getPUDashBoradAlerts).then((response: any) => {
            dispatch({ type: ActionType.GET_DASHBOARD_NOTIFICATIONS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const getLocationsByDeviceId = (deviceId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        ApiVariables.devices.getLocationsByDeviceId.id = deviceId as unknown as string;
        await api(ApiVariables.devices.getLocationsByDeviceId).then(async (response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const updateDeviceData = (data: any): IAppAction => {
    return { type: ActionType.UPDATE_DEVICE_DATA, payload: data };
};


export const getDevicesBySearchText = (parameterName: string | null, parameterValue: string | null) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.devices.getDevicesByRangeUnassigned.setStart = 0;
        ApiVariables.devices.getDevicesByRangeUnassigned.setLimit = 50;
        ApiVariables.devices.getDevicesByRangeUnassigned.setParameterName = parameterName;
        ApiVariables.devices.getDevicesByRangeUnassigned.setParameterValue = parameterValue;
        api(ApiVariables.devices.getDevicesByRangeUnassigned).then((response: any) => {
            dispatch({ type: ActionType.GET_DEVICES_DROP_DOWN_LIST, payload: response.data });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const getBoundaryByDeviceId = (deviceId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        ApiVariables.devices.getBoundaryByDeviceId.id = deviceId
        await api(ApiVariables.devices.getBoundaryByDeviceId).then(async (response: any) => {
            dispatch({ type: ActionType.GET_BOUNDARY_DETAILS_BY_DEVICE_ID, payload: response.data });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const saveBoundaryByDeviceID = (id,body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        ApiVariables.devices.saveBoundaryByDeviceID.id = id
        await api({ ...ApiVariables.devices.saveBoundaryByDeviceID, body }).then(async (response: any) => {
            dispatch({ type: ActionType.GET_BOUNDARY_DETAILS_BY_DEVICE_ID, payload: response.data });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            toast.success("Device Boundary Saved Successfully")
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const resetBoundaryByDeviceId = (): IAppAction => {
    return { type: ActionType.GET_BOUNDARY_DETAILS_BY_DEVICE_ID, payload: {} };
};