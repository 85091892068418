
import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables';
export const getSensorReadingsDetailsByRange = (start: number, limit: number, parameterName?: string, parameterValue?: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.sensorReadings.getSensorReadingsByRange.setStart = start;
        ApiVariables.sensorReadings.getSensorReadingsByRange.setLimit = limit;
        ApiVariables.sensorReadings.getSensorReadingsByRange.setParameterName = parameterName;
        ApiVariables.sensorReadings.getSensorReadingsByRange.setParameterValue = parameterValue;
        api(ApiVariables.sensorReadings.getSensorReadingsByRange).then((response: any) => {
            dispatch({ type: ActionType.GET_SENSOR_READINGS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const getSensorReadingsDetailsReset = (): IAppAction => {
    return { type: ActionType.GET_SENSOR_READINGS_RESET};
};

export const getSensorDetailsByDeviceId = (deviceId: string,) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise(async (resolve, reject) => {
        ApiVariables.sensorReadings.getSensorDetailsByDeviceId.id = deviceId
        await api(ApiVariables.sensorReadings.getSensorDetailsByDeviceId).then(async (response: any) => {
            dispatch({ type: ActionType.GET_SENSOR_DETAILS_BY_DEVICE_ID, payload: response });
            // dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            // dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const postSensorConfigurationsSave = (body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        await api({ ...ApiVariables.sensorReadings.postSensorConfigurationsSave, body }).then(async (response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const refresh = (): IAppAction => {
    return { type: ActionType.ON_REFRESH };
};

export const getAllLocationMarkers = (locationId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise(async (resolve, reject) => {
        ApiVariables.sensorReadings.getAllLocationMarkers.id = locationId as unknown as string;
        await api(ApiVariables.sensorReadings.getAllLocationMarkers).then(async (response: any) => {
            await dispatch({ type: ActionType.GET_LOCATION_MARKERS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};





