import React, { useState, useRef, useEffect, useCallback } from "react";
import { GoogleMap, Marker, Polygon, Circle, DrawingManager, useJsApiLoader, useLoadScript } from "@react-google-maps/api";

const libraries = ['places', 'drawing'];
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY ? process.env.REACT_APP_GOOGLE_MAP_KEY : '';

const Map = ({ paths = [], point, showCircle = false, circleRadius, showPolygon = false, zoom, center }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries
    });

    const [circles, setCircles] = useState([]);
    const [path, setPath] = useState(paths);
    console.log("::::::::::::::::::::::::path", path);
    useEffect(() => {
        setPath(paths);
    }, [paths]);

    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
            point(nextPath);
        }



    }, [setPath, point]);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    const onCircleComplete = (eventOnCircle) => {
        const radius = eventOnCircle.getRadius();
        // Handle Circle using event.overlay
        console.log(":::::::::::::::::::::::::::::radius", radius)
    }

    const onPolygonComplete = (eventOnPolygon) => {
        const path = eventOnPolygon.getPath().getArray().map(coord => {
            return {
                lat: coord.lat(),
                lng: coord.lng()
            }
        });
        setPath(path);
        point(path);
    }

    const onLoadDraw = React.useCallback(function onLoadDraw(draw) {
        if (draw.drawingMode === "polyline") {
            console.log(":::::::::::::::::::::::::::::draw", draw)
        } else if (draw.drawingMode === "circle") {
            draw.setDrawingMode(window.google.maps.drawing.OverlayType.CIRCLE);
            draw.addListener("overlaycomplete", event => {
                setCircles(circles => [...circles, event.overlay]);
            });
            console.log(":::::::::::::::::::::::::::::draw", draw)
        }

    }, []);

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    if (loadError) return <p>Error loading maps</p>
    if (!isLoaded) return <p>Loading Maps</p>


    return (
        <GoogleMap
            mapContainerStyle={{ height: "400px", width: "100%" }}
            zoom={zoom}
            center={center}
        >
            {(center && center.lat && center.lng) && <Marker draggable={false} label={""} position={center} />}

            {showCircle && <Circle
                editable={false}
                draggable={true}
                // onDrag={(e, b, j) => { console.log(":::::::::::::::onDrag", e, b, j) }}
                // onLoad={(e, b, j) => { console.log(":::::::::::::::onLoad", e, b, j) }}
                // onDragStart={(e, b, j) => { console.log(":::::::::::::::onDragStart", e, b, j) }}
                // onDragEnd={(e, b, j) => { console.log(":::::::::::::::onDragEnd", e, b, j) }}
                center={{ lat: center.lat, lng: center.lng }}
                radius={circleRadius ? circleRadius * 100 : 400}
                options={{
                    fillColor: "#ff0000",
                    strokeColor: "#ff0000",
                    fillOpacity: 0.35,
                    strokeOpacity: 1,
                    strokeWeight: 2
                }}
            />}
            {/* {circles.map((circle, index) => (
                <Circle key={index} options={{
                    fillColor: "#ff0000",
                    fillOpacity: 0.35,
                    strokeColor: "#ff0000",
                    strokeOpacity: 1,
                    strokeWeight: 2
                }}
                    center={circle?.getCenter()}
                    radius={circle?.getRadius()} />
            ))} */}
            {(showPolygon && path?.length > 0 && path[0]?.sortOrder === 0) &&
                <Polygon
                    editable={false}
                    draggable={true}
                    paths={path}
                    // path={path}
                    // onMouseUp={onEdit}
                    // onDragEnd={onEdit}
                    // onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={{
                        fillColor: "#ff0000",
                        fillOpacity: 0.5,
                        strokeColor: "#ff0000",
                        strokeOpacity: 1,
                        strokeWeight: 1.5
                    }}
                />}
            <DrawingManager
                options={{
                    drawingMode: null,
                    drawingControl: showPolygon,
                    drawingControlOptions: {
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [showPolygon ? window.google.maps.drawing.OverlayType.POLYGON : null]
                    },
                    circleOptions: {
                        fillColor: "#ff0000",
                        fillOpacity: 0.35,
                        strokeColor: "#ff0000",
                        strokeOpacity: 1,
                        strokeWeight: 2
                    },
                    polygonOptions: {
                        // paths: path,
                        draggable: true,
                        fillColor: "#ff0000",
                        fillOpacity: 0.35,
                        strokeColor: "#ff0000",
                        strokeOpacity: 1,
                        strokeWeight: 2
                    }
                }}
                onCircleComplete={onCircleComplete}
                onPolygonComplete={onPolygonComplete}
                onUnmount={onUnmount}
                onLoad={onLoadDraw} >

            </DrawingManager>
        </GoogleMap>
    );
};

export default Map;