
import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables';
import ICustomer from '../../models/customer/ICustomerView';
import { Alert } from '../../state/Alert';
export const getCustomersDetailsByRange = (start: number, limit: number, parameterName?: string, parameterValue?: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        ApiVariables.customers.getCustomersByRange.setStart = start;
        ApiVariables.customers.getCustomersByRange.setLimit = limit;
        ApiVariables.customers.getCustomersByRange.setParameterName = parameterName;
        ApiVariables.customers.getCustomersByRange.setParameterValue = parameterValue;
        api(ApiVariables.customers.getCustomersByRange).then((response: any) => {
            dispatch({ type: ActionType.GET_CUSTOMERS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const editCustomerDetails = (customerDetals: ICustomer): IAppAction => {
    return { type: ActionType.EDIT_CUSTOMER_DETAILS, payload: customerDetals };
};

export const getDashBoradCustomersDeails = (ownerId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise(async (resolve, reject) => {
        ApiVariables.customers.getDashBoardDeviceCountDetails.id = ownerId;
        await api(ApiVariables.customers.getDashBoardDeviceCountDetails).then(async (response: any) => {
            await dispatch({ type: ActionType.GET_OU_CUSTOMER_DASHBOARD_DETAILS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const getDevicesByCustomerIdToShowTheMapMarkers = (customerId: number) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise(async (resolve, reject) => {
        ApiVariables.customers.getDevicesByCustomerIdToShowTheMarkers.customerId = customerId as unknown as string;
        await api(ApiVariables.customers.getDevicesByCustomerIdToShowTheMarkers).then(async (response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const postSaveOrUpdateCustomer = (body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        api({ ...ApiVariables.customers.postCustomer, body }).then((response: any) => {
            dispatch({ type: ActionType.OPEN_ALERT, payload: new Alert({ message: response.status.message, alertType: 'success' }) })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.OPEN_ALERT, payload: new Alert({ message: 'Something went wrong', alertType: "error" }) })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}

export const putUpdateCustomer = (customerById:string,body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        ApiVariables.customers.putUpdateCustomerById.customerId = customerById;
        api({ ...ApiVariables.customers.putUpdateCustomerById, body }).then((response: any) => {
            dispatch({ type: ActionType.OPEN_ALERT, payload: new Alert({ message: response.status.message, alertType: 'success' }) })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.OPEN_ALERT, payload: new Alert({ message: 'Something went wrong', alertType: "error" }) })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}

export const resetEditCustomerDetails = (): IAppAction => {
    return { type: ActionType.EDIT_CUSTOMER_DETAILS, payload: {} };
};