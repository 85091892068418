
import * as React from 'react';
import {
    Paper, Box,
    TablePagination, Theme, Typography, Grid, Button, Dialog, ListItemText, Checkbox, FormHelperText, Card, CardHeader, CardContent
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as DeviceActions from '../../store/actions/Devices.Action';
import { withRouter } from 'react-router';
import MapComponent from '../../components/map/MapComponent';
import Geocode from "react-geocode";


import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select from '@mui/material/Select/Select';
import SimpleReactValidator from 'simple-react-validator';
import CustomGoogleAutocomplete from '../../components/google/CustomGoogleAutocomplete';
import IDeviceView from '../../models/device/IDeviceView';
import IDeviceBoundary from '../../models/device/IDeviceBoundary';
import CustomGoogleMap from '../../components/map/CustomGoogleMap';
const classNames = require('classnames');

interface IPageProps {
    classes: any,
    google: any;
    getBoundaryByDeviceId: (deviceId: number) => any;
    saveBoundaryByDeviceID: (deviceId: number, body) => any,
    resetBoundaryByDeviceId: () => void,
    resetEditDeviceDetails: () => void,
    getDeviceById: (deviceId: string) => void;
    boundaryByDevice: IDeviceBoundary,
    match: any;
    selectedDevice?: any;
    onCloseModal: () => void;
    history?: any;
    loader?: boolean;
}

interface IPageState extends IDeviceView {
    deviceBoundary: IDeviceBoundary;
    zoom: number;
    markers?: any[],
    location?: string
    paths?: any[];
    center: any;
}

interface ILatAndLng {
    lat: number | null;
    lng: number | null;
}


class LocationSearchMap extends React.Component<IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();
    constructor(props: IPageProps) {
        super(props);
        this.state = {
            center: { lat: 39.866914000103435, lng: -75.19595901093751 },
            paths: [

            ],
            deviceBoundary: {
                address1: '',
                address2: '',
                state: '',
                city: '',
                postalCode: '',
                country: '',
                latitude: '',
                longitude: '',
                boundaryType: '',
                boundaryValue: 2,
                deviceBoundaryId: null
            },
            recipientList: [],
            zoom: 16,
            name: '',
            //  deviceBoundary: this.props.boundaryByDevice
        }
    }

    public async componentDidMount() {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
        const deviceId = this.props.match.params.id;
        await this.props.getDeviceById(deviceId);

        await this.props.getBoundaryByDeviceId(deviceId).then((response) => {
            if (response?.data) {
                const data = response.data;
                const center = {
                    lat: +data.latitude,
                    lng: +data.longitude
                };
                if (response?.data?.paths && Array.isArray(response?.data?.paths) && response?.data?.paths.length > 0) {
                    const paths: any = response.data.paths || [];
                    this.setState({ paths: paths.sort(function (a, b) { return a.sortOrder - b.sortOrder }) });
                }
                this.setState({ center });
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<IPageProps>, prevState: Readonly<IPageState>, snapshot?: any): void {
        if (this.props.match.params.id && prevProps?.boundaryByDevice?.deviceBoundaryId !== prevState?.deviceBoundary?.deviceBoundaryId
        ) {
            const paths: any = prevProps?.boundaryByDevice?.paths || [];
            this.setState({
                ...this.state,
                deviceBoundary: { ...prevProps.boundaryByDevice },
                paths: paths?.sort(function (a, b) { return a.sortOrder - b.sortOrder })
            });

        }
    }
    resetAddress() {
        this.setState({
            ...this.state, deviceBoundary: { ...this.state.deviceBoundary, address1: '', address2: '', state: '', city: '', country: '', postalCode: '' },
        });
    }

    private handleBoundaryChange = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        if (name === 'boundaryType') {
            this.setState({
                ...this.state,
                deviceBoundary: {
                    ...this.state.deviceBoundary,
                    [name]: value,
                    boundaryValue: 2,
                },
            });
        } else {
            this.setState({
                ...this.state,
                deviceBoundary: {
                    ...this.state.deviceBoundary,
                    [name]: value,
                },
            });
        }

    };

    private handleSubmitDeviceBoundaryDetails = () => {
        if (this.validator.allValid()) {
            const payload: any = this.state.deviceBoundary;
            this.state.paths?.forEach((path, index) => {
                path.sortOrder = index;
            });
            payload.paths = this.state.paths;
            if (payload.boundaryType === 'Polygon') {
                payload.boundaryValue = 2;
            } else {
                payload.paths = [];
                payload.boundaryValue = 1;
            }
            this.props.saveBoundaryByDeviceID(this.props.selectedDevice.deviceId, JSON.stringify(payload)).then((response: any) => {
                if (response && response.status.success === "SUCCESS") {
                }
            }).catch((error: any) => {
            });
        }

    }

    fillInAddress = (place, isAutoSearch) => {
        this.resetAddress();
        let address1 = "";
        let postcode = "";
        let city = "";
        for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];
            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }
                case "route": {
                    address1 += component.short_name;
                    break;
                }
                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    this.setState({
                        ...this.state, deviceBoundary: { ...this.state.deviceBoundary, postalCode: postcode },
                    });
                    break;
                }
                case "postal_code_suffix": {
                    if (!postcode) {
                        this.setState({
                            ...this.state, deviceBoundary: { ...this.state.deviceBoundary, postalCode: component.long_name },
                        });
                    }
                    break;
                }
                // case "administrative_area_level_2": {
                //     this.setState({
                //         ...this.state,deviceBoundary:{...this.state.deviceBoundary,city: component.long_name },
                //     });
                //     break;
                // }
                case "locality":
                    this.setState({
                        ...this.state, deviceBoundary: { ...this.state.deviceBoundary, city: component.long_name },
                    });
                    break;

                case "administrative_area_level_1": {
                    this.setState({
                        ...this.state, deviceBoundary: { ...this.state.deviceBoundary, state: component.short_name },
                    });

                    break;
                }
                case "country":
                    this.setState({
                        ...this.state, deviceBoundary: { ...this.state.deviceBoundary, country: component.long_name },
                    });
                    break;
            }
        }
        if (isAutoSearch) {
            // const path: any = [{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
            // { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
            // { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
            // { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }];

            // address1=this.state.deviceBoundary.address1 + ' '+this.state.deviceBoundary.city+' '+this.state.deviceBoundary.state+' '+this.state.deviceBoundary.country
            this.setState({
                ...this.state,
                // paths: path,
                center: {
                    lat: place.geometry.location.lat(), lng: place.geometry.location.lng(),
                },
                deviceBoundary: { ...this.state.deviceBoundary, latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng(), address1: address1 },
            });
        }
        else {
            this.setState({
                ...this.state, deviceBoundary: { ...this.state.deviceBoundary, address1: place.formatted_address },
            });
        }

    }
    private onCloseBoundaryModel = () => {
        this.props.resetBoundaryByDeviceId();
        this.props.history.push('/devices')
    }
    onDragEnd = (coord) => {
        Geocode.fromLatLng(coord.lat, coord.lat).then(
            response => {
                this.setState({
                    ...this.state, deviceBoundary: {
                        ...this.state.deviceBoundary,
                        latitude: coord.lat, longitude: coord.lng,
                        address1: response.results[0].formatted_address
                    },
                });

                this.fillInAddress(response.results[0], false)
            },
            error => {
                console.error(error);
            }
        );

    }

    componentWillUnmount(): void {
        this.setState({
            deviceBoundary: { boundaryType: null, boundaryValue: null },
            recipientList: [],
        });
        this.props.resetBoundaryByDeviceId();

    }

    public render(): JSX.Element {

        const { classes, selectedDevice, loader } = this.props; // utility
        const { deviceBoundary, paths } = this.state;
        console.log(paths)
        const { address, center, zoom } = this.state;
        return (

            <Paper className={classNames(classes.paper)}>
                <h3 style={{ textAlign: 'center' }}> {selectedDevice?.name || ''}  {(selectedDevice?.nickName) ? ' - ' + selectedDevice?.nickName : ''} Boundary Details</h3>
                <Grid container={true} spacing={5} paddingTop="0px">
                    <Grid item={true} xs={12} sm={12} paddingTop="0px" >
                        <Box boxShadow={3} className={classes.box} >
                            <Card className={classes.card} >
                                <CardHeader
                                    className={classes.header}
                                    component={Typography}
                                    title={"Address Information"}
                                />
                                <CardContent className={classes.content}>
                                    <Grid container={true} spacing={3} padding={0}>
                                        <Grid item={true} xs={12} sm={6}>
                                            <CustomGoogleAutocomplete
                                                id="address1"
                                                isRequired={true}
                                                name="address1"
                                                value={deviceBoundary?.address1 || ''}
                                                placeholder="Address Line 1"
                                                onBlur={() => this.validator.showMessageFor('address1')}
                                                onChange={this.handleBoundaryChange}
                                                onPlaceSelected={(place) => this.fillInAddress(place, true)} />
                                            {this.validator.message('address1', deviceBoundary?.address1, 'required')}
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6}>
                                            <FormControl fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="address2">Address2</InputLabel>
                                                <Input value={deviceBoundary.address2 || ''} onChange={this.handleBoundaryChange} id="address2" name="address2" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} md={3}>
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="city">City</InputLabel>
                                                <Input value={deviceBoundary.city || ''} onChange={this.handleBoundaryChange} id="city" name="city" onBlur={() => this.validator.showMessageFor('City')} />
                                                {this.validator.message('City', deviceBoundary.city, 'required')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} md={3}>
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="state">State</InputLabel>
                                                <Input value={deviceBoundary.state || ''} onChange={this.handleBoundaryChange} id="state" name="state" onBlur={() => this.validator.showMessageFor('State')} />
                                                {this.validator.message('State', deviceBoundary.state, 'required')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} md={3}>
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="state">Country</InputLabel>
                                                <Input value={deviceBoundary.country || ''} onChange={this.handleBoundaryChange} id="country" name="country" onBlur={() => this.validator.showMessageFor('country')} />
                                                {this.validator.message('Country', deviceBoundary.country, 'required')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} md={3}>
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="postalCode">Zip Code</InputLabel>
                                                <Input value={deviceBoundary.postalCode || ''}
                                                    onChange={this.handleBoundaryChange} id="postalCode" name="postalCode"
                                                    onBlur={() => this.validator.showMessageFor('postalCode')} />
                                                {this.validator.message('ZipCode', deviceBoundary.postalCode, 'required|min:0|max:6')}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box boxShadow={3} className={classes.box} >
                            <Card className={classes.card} >
                                <CardHeader
                                    className={classes.header}
                                    component={Typography}
                                    title={"Border Information"}
                                />
                                <CardContent className={classes.content}>
                                    <div className={`${classes.contentItem} `}>
                                        <Grid container={true} spacing={3} padding={3}>

                                            <Grid item={true} xs={12} sm={12}>

                                                <FormControl required={true} fullWidth={true} >
                                                    <InputLabel htmlFor="contactLastName">Boundary Type</InputLabel>
                                                    <Select
                                                        label="Type"
                                                        value={deviceBoundary.boundaryType || ''}
                                                        disabled={!deviceBoundary?.address1}
                                                        variant="standard"
                                                        name="boundaryType"
                                                        onChange={this.handleBoundaryChange}
                                                        onBlur={() => this.validator.showMessageFor('boundaryType')}
                                                    >
                                                        <MenuItem key="circular" value='Circular'>Circular Boundary</MenuItem>
                                                        <MenuItem key="polygon" value='Polygon'>Polygon Boundary</MenuItem>

                                                    </Select>
                                                    {this.validator.message('boundaryType', deviceBoundary.boundaryType, 'required|min:1')}

                                                </FormControl>
                                            </Grid>
                                            {deviceBoundary!.boundaryType === 'Circular' &&
                                                <Grid item={true} xs={12} sm={12}>
                                                    <FormControl required={true} fullWidth={true} >
                                                        <InputLabel htmlFor="boundaryValue">Boundary value</InputLabel>
                                                        <Input type='number'
                                                            disabled={(deviceBoundary.boundaryType) ? false : true}
                                                            value={deviceBoundary.boundaryValue || ''} id="boundaryValue"
                                                            name="boundaryValue" onChange={this.handleBoundaryChange}
                                                            onBlur={() => this.validator.showMessageFor('boundaryValue')} />
                                                        {this.validator.message('Boundary Value', deviceBoundary.boundaryValue, 'required')}
                                                        {deviceBoundary!.boundaryType === 'Circular' && <FormHelperText color="primary">Enter Boundary Value in KM</FormHelperText>}
                                                    </FormControl>
                                                    {deviceBoundary!.boundaryType === 'Circular' && this.validator.message('boundaryValue', deviceBoundary.boundaryValue, 'required')}
                                                </Grid>
                                            }
                                            <Grid item={true} xs={12} sm={12}>
                                                <FormControl fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="latitude">Latitude</InputLabel>
                                                    <Input
                                                        disabled={true}
                                                        value={deviceBoundary.latitude || ''} id="latitude" name="latitude"
                                                        onBlur={() => this.validator.showMessageFor('latitude')}
                                                    />
                                                    {this.validator.message('latitude', deviceBoundary.latitude, 'required')}
                                                </FormControl>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={12}>
                                                <FormControl fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="longitude">Longitude</InputLabel>
                                                    <Input
                                                        disabled={true}
                                                        value={deviceBoundary.longitude || ''} id="longitude" name="longitude"
                                                        onBlur={() => this.validator.showMessageFor('latitude')}
                                                    />
                                                    {this.validator.message('longitude', deviceBoundary.longitude, 'required')}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.contentItem}>
                                        <div className="App2">
                                            {(deviceBoundary.latitude && deviceBoundary.longitude && !loader) && <CustomGoogleMap
                                                paths={paths}
                                                center={center}
                                                point={paths => {
                                                    this.setState({ paths: paths }, () => {
                                                    })
                                                }}
                                                showCircle={deviceBoundary.boundaryType === "Circular" ? true : false}
                                                circleRadius={deviceBoundary.boundaryType === "Circular" ? deviceBoundary.boundaryValue : 0}
                                                showPolygon={deviceBoundary.boundaryType === "Polygon" ? true : false}
                                                zoom={zoom}
                                            />}

                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Box>
                        <div>

                        </div>
                        <Grid item={true} xs={12} sm={12}>
                            <Grid container={true} spacing={3} marginBottom={5}>
                                <Grid item={true} xs={12} sm={6} style={{ textAlign: "center" }}>
                                    <Button
                                        onClick={() => this.onCloseBoundaryModel()}
                                        variant="outlined"
                                        color="primary"
                                        style={{ textAlign: "center", marginRight: 20, }}
                                    >Cancel</Button>
                                </Grid>
                                <Grid item={true} xs={12} sm={6} style={{ textAlign: "center" }}>
                                    <Button
                                        onClick={this.handleSubmitDeviceBoundaryDetails}
                                        variant="contained"
                                        color="primary"
                                        style={{ textAlign: "center" }}
                                    >Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>

            </Paper>

        )
    }
}
const styles = (theme: Theme) => ({
    paper: {
        paddingTop: '0px',
        paddingBottom: '0px',
        //padding: theme.spacing(4),
        overflow: 'hidden',
        color: theme.palette.text.secondary,
    },
    content: {
        display: "flex",
        flexWrap: "wrap"
    },
    contentItem: {
        flex: "calc(50% - 4px)",
        "@media (max-width: 500px)": {
            flex: "100%"
        }
    },
    container: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    box: {
        margin: '0',
        "@media (min-width: 500px)": {
            margin: '2%'
        }
    }

});
const mapStateToProps = (state: any) => {
    return {
        boundaryByDevice: state.device.boundaryDetailsByDeviceId,
        selectedDevice: state.device?.editDeviceDetail,
        loader: state.common?.isAppLoader
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getBoundaryByDeviceId: DeviceActions.getBoundaryByDeviceId,
        saveBoundaryByDeviceID: DeviceActions.saveBoundaryByDeviceID,
        resetBoundaryByDeviceId: DeviceActions.resetBoundaryByDeviceId,
        resetEditDeviceDetails: DeviceActions.resetEditDeviceDetails,
        getDeviceById: DeviceActions.getDeviceById,


    }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(LocationSearchMap as any)) as any);

