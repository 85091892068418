import { faObjectGroup, faSearchLocation, faTachometerAlt,faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, Grid, IconButton, ListItemText, Paper, TablePagination, Theme, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid/components';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid/models';
import * as React from 'react';
import BatteryGauge from 'react-battery-gauge';
import ReactSpeedometer from "react-d3-speedometer";
import Geocode from "react-geocode";
import { BiWalk } from 'react-icons/bi';
import { BsBoundingBoxCircles } from "react-icons/bs";
import { FaRegMoon, FaSun } from 'react-icons/fa';
import { GrMapLocation } from "react-icons/gr";
import { ImMan } from 'react-icons/im';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Thermometer from 'react-thermometer-chart';
import { toast } from 'react-toastify';
import { ResponsiveContainer } from 'recharts';
import { bindActionCreators, Dispatch } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import { DialogContent, DialogTitle } from '../../components/dialog/DialogTitle';
import MapComponent from '../../components/map/MapComponent';
import { SearchBox } from '../../components/search/SearchBox';
import IFilterMenuOptionsModel from '../../models/common/IFilterMenuOptionsModel';
import IFilterModel from '../../models/common/IFilterModel';
import IDevice from '../../models/device/IDeviceView';
import { IUtility } from '../../state/Utility';
import * as DeviceActions from '../../store/actions/Devices.Action';
import { exportDevicesData } from '../../store/actions/Devices.Action';
import * as LocationActions from '../../store/actions/LocationReadings.Action';
import * as SensorReadingsActions from '../../store/actions/SensorReadings.Action';
import { getDateFormateWithTime } from '../../utilities/DateUtils';
import moment from 'moment';

const classNames = require('classnames');

interface IPageProps {
    devices?: IDevice[],
    classes: any,
    getDevicesByRange: (start: number, limit: number, paramName?: string, paramValue?: string) => void;
    totalResults?: number;
    editDeviceDetail: (deviceDetail: IDevice) => void,
    history: any;
    utility: IUtility;
    materialChartData: any[];
    google: any;
    getLocations: (locationId: number) => void;
    getLocationsByDeviceId: (deviceId: number) => any;
    getLocationReadingsDetailsByRange: (start: number, limit: number, paramName?: string, paramValue?: string) => any;
    getBoundaryByDeviceId: (deviceId: number) => any;
    saveBoundaryByDeviceID: (deviceId: number, body) => any,
    resetBoundaryByDeviceId: () => void,
    boundaryByDevice?: any,
    match: any;
    location: ILocation;
}

interface IPageState {
    title: string;
    deviceTablePage: number,
    deviceTableRowsPerPage: number,
    pageStartCount: number,
    anchorEl: any;
    selectedFilterMenuIndex: number,
    filterMenuOptions: IFilterMenuOptionsModel[],
    selectedFilterMenu: string | number | boolean;
    selectedFilterMenuKey: string;
    searchBoxValue: string;
    searchBoxFilterKey: string,
    openSensorModel: boolean;
    batteryVoltageValue: number;
    batteryTemperatureValue: any;
    batteryTempState: string;
    temperatureValue: any;
    lastSensorReadingCenter:any;
    lastSensorReadingMarker:any[];
    pressureValue: any;
    humidity: any;
    humidityTemp2Value: any;
    motionValue: any;
    light_sensor: any;
    center: ILatAndLng;
    zoom: number;
    flightPlanCoordinates: ILatAndLng[];
    address: string;
    name: string;
    markers: any[],
    locationMarkers: any[],
    flightPlanLocationCoordinates: ILatAndLng[];
    openLocationModel: boolean;
    openLocationBoundaryModel: boolean,
    selectedDeviceBoundary: any,
    deviceBoundary: any
}
// interface IMarkerProps {
//     text: string;
//     lat?: number; 
//     lng?: number;
// }
interface ILatAndLng {
    lat: number | null;
    lng: number | null;
}
interface ILocationState {
    customerName: string
}

export interface ILocation<S = ILocationState> {
    pathname: string;
    search: string;
    state: S;
    hash: string;
    key?: string;
}

class Devices extends React.Component<IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();

    constructor(props: IPageProps) {
        super(props);
        this.state = {
            title: 'welcome',
            deviceTablePage: 0,
            deviceTableRowsPerPage: 10,
            pageStartCount: 10,
            anchorEl: null,
            selectedFilterMenuIndex: 0,
            filterMenuOptions: [{ "label": 'Device Name', "key": 'name' },
            { "label": 'IMEI', "key": 'imei' },
            { "label": 'Customer Name', "key": 'customerName' }],
            selectedFilterMenu: 'Device Name',
            selectedFilterMenuKey: 'name',
            searchBoxValue: '',
            searchBoxFilterKey: '',
            openSensorModel: false,
            batteryVoltageValue: 0,
            batteryTemperatureValue: 0,
            batteryTempState: '',
            humidity: 0,
            humidityTemp2Value: 0,
            pressureValue: 0,
            temperatureValue: 0,
            motionValue: 0,
            light_sensor: 0,
            center: {
                lat: null,
                lng: null
            },
            zoom: 15,
            flightPlanCoordinates: [],
            address: '',
            name: '',
            markers: [],
            locationMarkers: [],
            openLocationModel: false,
            openLocationBoundaryModel: false,
            selectedDeviceBoundary: null,
            flightPlanLocationCoordinates: [],
            deviceBoundary: { boundaryType: null, boundaryValue: null },
            lastSensorReadingCenter: {
                lat: null,
                lng: null
            },
            lastSensorReadingMarker:[]
        }
    }

    public async componentWillMount() {
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this, messages: {
                integer: "Please enter only number"
            }
        });
        const { customerName } = (this.props.location && this.props.location.state) || {};
        if (customerName) {
            await this.props.getDevicesByRange(0, 10, 'customerName', customerName);
        } else {
            await this.props.getDevicesByRange(0, 10);
        }
    }
    public componentDidMount() {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
        const { customerName } = (this.props.location && this.props.location.state) || {};
        if (customerName) {
            this.setState({
                searchBoxValue: customerName,
                selectedFilterMenu: 'Customer Name',
                selectedFilterMenuKey: 'customerName',
                searchBoxFilterKey: 'customerName',
                selectedFilterMenuIndex: 1
            })
        }
    }


    private handleChangeDevicePage = (event: any, page: number) => {
        console.log('page', page)
    };

    private handleChangeTableRowsPerPage = async (event: any) => {
        await this.props.getDevicesByRange(0, event.target.value as number, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ deviceTableRowsPerPage: event.target.value, pageStartCount: event.target.value, deviceTablePage: 0 });
    };

    private loadNextPage = async () => {
        await this.props.getDevicesByRange(this.state.pageStartCount, this.state.deviceTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ deviceTablePage: this.state.deviceTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.deviceTableRowsPerPage });
    }

    private loadPreviousPage = () => {
        this.setState({ deviceTablePage: this.state.deviceTablePage - 1, pageStartCount: this.state.pageStartCount - this.state.deviceTableRowsPerPage })
    }

    private editDeviceRecord = (device: IDevice) => {
        this.props.editDeviceDetail(device);
        this.props.history.push(`/edit-device-details/${device.deviceId}`)
    }

    private readingDeviceSensorDetailsByIMEI = (imei: any) => {
        this.props.history.push({
            pathname: '/sensor-readings',
            state: { 'imei': imei }
        })
    }
    formatLatLng(data){
        return parseFloat(data.replace(':', '.'))
    }
    resetPreviousData(){
        this.setState({
            flightPlanLocationCoordinates:[],
            center:{
                lat: null,
                lng: null
            },
            locationMarkers:[]
        })
    }
    private readingLocation = async (device: any) => {
        this.resetPreviousData();
        const { flightPlanLocationCoordinates } = this.state;
        let deviceMarkers: any[] = [];

        const address = device.address + (device.address ? ' ' : '') + device.address2 + (device.address ? ', ' : '') + device.city + (device.city ? ', ' : '') + device.state + (device.state ? ', ' : '') + device.postalCode;

        await this.props.getLocationReadingsDetailsByRange(0, 50, 'imei', device.imei).then(async (response: any) => {
            if (response && response.data) {
                const filteredObjects = response.data.filter(obj =>{
                    if( obj.messageType === 'SP9G'){
                        console.log(obj);
                        obj.latitude=this.formatLatLng(obj.latitude);
                        obj.longitude=this.formatLatLng(obj.longitude);
                        return  obj.messageType === 'SP9G';
                    }               
                });

               // position = { lat: response.data.latitude, lng: response.data.longitude };
                //flightPlanLocationCoordinates.push(position);

                deviceMarkers = [...filteredObjects];
                this.setState({ locationMarkers: response.data })
                await filteredObjects.forEach((marker: any, markerIndex: number) => {
                    if (markerIndex === 0 && marker.latitude && marker.longitude) {
                        this.setState({ center: { lat: marker.latitude, lng: marker.longitude } })
                    }
                })
            }
            // else {
            //     this.toGetTheCenterLocationPoint(position, address, deviceMarkers)
            // }
        });

        this.setState({ flightPlanLocationCoordinates, address })
        this.setState({ openLocationModel: true });
    }
    private readingDeviceLocationDetailsByIMEI = (imei: any) => {
        this.props.history.push({
            pathname: '/location-readings',
            state: { 'imei': imei }
        })
    }
    private readingSensor = async (device: any) => {
        if (device) {
            const { flightPlanCoordinates } = this.state;debugger
            const position: ILatAndLng = { lat: device.latitude, lng: device.longitude };
            flightPlanCoordinates.push(position);
            const address = device.address + (device.address ? ' ' : '') + device.address2 + (device.address ? ', ' : '') + device.city + (device.city ? ', ' : '') + device.state + (device.state ? ', ' : '') + device.postalCode;
            const readings: any[] = device.latestSensoryReadingList;
            let deviceMarkers: any[] = [];
            if (readings) {
                const name = device.name;
                if (readings && readings.length > 0) {
                    await readings.forEach(async (sensor: any) => {
                        if (sensor.unitOfMeasure !== null) {
                            if (sensor.sensorType == 'battery') {
                                this.setState({ batteryVoltageValue: +sensor.value })
                            } else if (sensor.sensorType === 'battery_temp') {
                                let ntc_mv, batteryTempState;
                                ntc_mv = ((+sensor.value) / (1000 * 2.2) - 0.57) / ((0.57 - 0.19) / (0 - 60));
                                ntc_mv = parseInt(ntc_mv + 273);
                                if (ntc_mv < 283) {
                                    batteryTempState = "COLD";
                                } else if (ntc_mv >= 283 && ntc_mv < 318) {
                                    batteryTempState = "NOMINAL";
                                } else {
                                    batteryTempState = "HOT";
                                }

                                this.setState({ batteryTemperatureValue: ntc_mv, batteryTempState: batteryTempState })
                            }
                            else if (sensor.sensorType == 'humidity') {
                                this.setState({ humidity: parseInt(sensor.value) })
                            }
                            else if (sensor.sensorType == 'humidtytemp_sensor_2') {
                                let HumidtySensorCtoF = (sensor.value * 9 / 5) + 32
                                this.setState({ humidityTemp2Value: HumidtySensorCtoF })
                            }
                            else if (sensor.sensorType == 'motion') {
                                this.setState({ motionValue: sensor.value })
                            }
                            else if (sensor.sensorType === 'barometer') {
                                this.setState({ pressureValue: parseInt(sensor.value) })
                            }
                            else if (sensor.sensorType == 'temperature') {
                                let pressureSensorCtoF = (sensor.value * 9 / 5) + 32
                                this.setState({ temperatureValue: pressureSensorCtoF })
                            }
                            else if (sensor.sensorType == 'light_sensor') {
                                this.setState({ light_sensor: sensor.value })
                            }
                            else if(sensor.sensorType =='gps'){
                                const [lat, lng]=sensor.value.split(',')
                                if(sensor.value){
                                this.setState({
                                    lastSensorReadingMarker:[{latitude: this.formatLatLng(lat),longitude: this.formatLatLng(lng)}],
                                    lastSensorReadingCenter:{lat:  this.formatLatLng(lat),lng: this.formatLatLng(lng)}
                                })
                                }
                                else{
                                this.toGetTheCenterLocationPoint(position, address, deviceMarkers)
                                }
                            }
                        }
                    })
                }
                // const result = readings?.find((sensor: any) => {
                //     return sensor.sensorType === 'gps';
                // });
                // if (result) {
                //     await this.props.getLocationsByDeviceId(device.deviceId).then(async (response: any) => {
                //         if (response && response.data) {
                //             deviceMarkers = [...response.data];
                //             this.setState({ markers: response.data })
                //             await response.data.forEach((marker: any, markerIndex: number) => {
                //                 if (markerIndex === 0) {
                //                     this.setState({ center: { lat: marker.latitude, lng: marker.longitude } })
                //                 }
                //             })
                //         } else {
                //             this.toGetTheCenterLocationPoint(position, address, deviceMarkers)
                //         }
                //     }).catch((response: any) => {
                //         console.error(response);
                //         this.toGetTheCenterLocationPoint(position, address, deviceMarkers)
                //     })
                // }

                // if (deviceMarkers.length === 0) {
                //     this.toGetTheCenterLocationPoint(position, address, deviceMarkers)
                // }

                this.setState({ flightPlanCoordinates, address, name })
                this.setState({ openSensorModel: true });
            } else {
                toast.warning("Latest Sensor Reading Not Found")
            }

        }
    }

    private toGetTheCenterLocationPoint = async (position: ILatAndLng, address: string, markers: any) => {
        if ((Array.isArray(markers) && markers.length === 0) && (!position.lat && !position.lat && address !== "")) {
            await Geocode.fromAddress(address).then((response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                if (position.lat && position.lat) {
                    this.setState({ center: { lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng } })
                } else {
                    position.lat = lat;
                    position.lng = lng;
                    this.setState({ center: { lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng } })
                }
            }, error => {
                console.error(error);
            });
        }


    }
    private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: IFilterModel) => {
        this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key })
    };
    private handleClose = () => {
        this.setState({ anchorEl: null, });
    }
    private handleSearchBox = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private onEnterKeyPress = (event: any) => {
        if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
            this.callSearchByParam();
        }
    }
    private callSearchByParam = async () => {
        await this.props.getDevicesByRange(0, this.state.deviceTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    }
    private exportDevicesData = () => {
        exportDevicesData();
    }
    private handleCloseSensorModel = () => {
        this.setState({
            openSensorModel: false, markers: [], batteryVoltageValue: 0, batteryTemperatureValue: 0,
            address: '', flightPlanCoordinates: [], center: { lat: null, lng: null }
        })
    }
    private handleLocationModel = () => {
        this.setState({
            openLocationModel: false, markers: [],
            address: '', flightPlanCoordinates: [], center: { lat: null, lng: null }
        })
    }

    private locationBoundary = async (device: any) => {
        if (device) {
             this.setState({ openLocationBoundaryModel: true, selectedDeviceBoundary: device });
             this.props.history.push(`/location-boundary/${device.deviceId}`)

            // await this.props.getBoundaryByDeviceId(device.deviceId);
            // if (this.props.boundaryByDevice) {
            //     this.setState({ deviceBoundary: this.props.boundaryByDevice });
            // }
        }
    }

    private onCloseBoundaryModel = () => {
        this.props.resetBoundaryByDeviceId();
        this.setState({
            openLocationBoundaryModel: false,
            selectedDeviceBoundary: null,
            deviceBoundary: { boundaryType: null, boundaryValue: null },
        })
    }

    private handleBoundaryChange = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state, deviceBoundary: { ...this.state.deviceBoundary, [event.target.name]: value },
        });
    };

    private handleSubmitDeviceBoundaryDetails = () => {
        if (this.validator.allValid()) {
            this.props.saveBoundaryByDeviceID(this.state.selectedDeviceBoundary.deviceId, JSON.stringify(this.state.deviceBoundary)).then((response: any) => {
                if (response && response.status.success === "SUCCESS") {
                    this.onCloseBoundaryModel();
                    this.props.resetBoundaryByDeviceId();
                }
            }).catch((error: any) => {
                console.error(error);
            });
        }

    }
    getLastReading(param){
        // const dateOption: any = { year: 'numeric', month: 'short', day: '2-digit' };
        // const timeOption: any = { hour: '2-digit', minute: '2-digit' };
       // return `${param.row.updateDateTime? new Date(param.row.updateDateTime) + ' ' + new Date(param.row.updateDateTime).toLocaleTimeString('en-US', timeOption) :'--'}`;
       let formattedDate;
       if (param.row.latestSensoryReadingList) {
        const latestDate = param.row.latestSensoryReadingList.reduce((latest, reading) => {
            const currentDateTime = moment(reading.sensorReadingDateTime, 'YYYY-MM-DD HH:mm:ss.S');
            return currentDateTime.isAfter(latest) ? currentDateTime : latest;
          }, moment('1970-01-01 00:00:00.0', 'YYYY-MM-DD HH:mm:ss.S'));
           formattedDate = latestDate.format('MMM DD YYYY hh:mm a');
       }
       return formattedDate? formattedDate:'--'
    }
   
    columns: GridColDef[] = [
        { field: 'deviceId', headerName: 'Device ID', width: 90,hide: true  },
        {
          field: 'customerName',
          headerName: 'Customer name',
          width: 150,
          editable: false,
        },
        {
          field: 'name',
          headerName: 'Device name',
          width: 150,
          editable: false,
        },
        {
            field: 'nickName',
            headerName: 'Nick name',
            width: 150,
            editable: false,
          },
          {
            field: 'imei',
            headerName: 'IMEI',
            width: 150,
            editable: false,
          },
          {
            field: 'updateDateTime',
            headerName: 'Last Reading ',
            width: 200,
            editable: false,
            valueGetter: this.getLastReading,
          },
          {
            field: 'deviceReading',
            editable: false,
            headerName: 'Device reading',
            width: 120,
            renderCell: (params: GridRenderCellParams<any>) => (
              <>
                <FontAwesomeIcon className={this.props.classes.iconPointer} icon={faObjectGroup as any} onClick={() => this.readingDeviceSensorDetailsByIMEI(params.row.imei)} />
              </>
            ),
          },
          {
            field: 'locationBoundary',
            headerName: 'Location Boundary',
            editable: false,
            width: 120,
            renderCell: (params: GridRenderCellParams<any>) => (
              <>
                <BsBoundingBoxCircles onClick={() => this.locationBoundary(params.row)} />
              </>
            ),
          },
         
          {
            field: 'locationReading',
            headerName: 'Location Reading',
            editable: false,
            width: 120,
            renderCell: (params: GridRenderCellParams<any>) => (
              <>
               <FontAwesomeIcon className={this.props.classes.iconPointer} icon={faSearchLocation as any} onClick={() => this.readingDeviceLocationDetailsByIMEI(params.row.imei)} />
              </>
            ),
          },
          {
            field: 'locationTrace',
            headerName: 'Location Trace',
            editable: false,
            width: 120,
            renderCell: (params: GridRenderCellParams<any>) => (
              <>
                <GrMapLocation className={this.props.classes.iconPointer} onClick={() => this.readingLocation(params.row)} />
              </>
            ),
          },
          {
            field: 'sensor',
            headerName: 'Sensor',
            editable: false,
            width: 120,
            renderCell: (params: GridRenderCellParams<any>) => (
              <>
               <FontAwesomeIcon className={this.props.classes.iconPointer} icon={faTachometerAlt as any} onClick={() => this.readingSensor(params.row)} />
              </>
            ),
          },
          {
            field: 'edit',
            headerName: '',
            editable: false,
            width: 120,
            renderCell: (params: GridRenderCellParams<any>) => (
              <>
               <FontAwesomeIcon className={this.props.classes.iconPointer} icon={faEdit as any} onClick={() => this.editDeviceRecord(params.row)} />
              </>
            ),
          },
      ];
    
         handleRowClick = (params) => {
          console.log('Row clicked:', params.row);
          // Do something with the clicked row data
        };
      
    CustomToolbar() {
        return (
          <GridToolbarContainer  sx={{float: "right", 
          position: 'absolute',
          top:'-11%',
          right: '1%',
          justifyContent:'space-between'
        }}>
            <GridToolbarColumnsButton onResize={undefined} onResizeCapture={undefined} />
            <GridToolbarDensitySelector onResize={undefined} onResizeCapture={undefined} />
            <Button onClick={() => this.exportDevicesData()}
                                    style={{ backgroundColor: '#f6f6f6' }}
                                    color="primary">Export
            </Button>
          </GridToolbarContainer>
        );
      }
      
    public render(): JSX.Element {
        const dateOption: any = { year: 'numeric', month: 'short', day: '2-digit' };
        const timeOption: any = { hour: '2-digit', minute: '2-digit' };
        const { devices, classes, totalResults } = this.props; // utility
        const { selectedFilterMenuIndex, anchorEl, selectedFilterMenu, filterMenuOptions,
            searchBoxValue, openSensorModel, openLocationModel, openLocationBoundaryModel, deviceBoundary, selectedDeviceBoundary, batteryVoltageValue, humidity, humidityTemp2Value, light_sensor, motionValue, batteryTemperatureValue, pressureValue, zoom, center, address, flightPlanCoordinates,lastSensorReadingCenter,lastSensorReadingMarker, flightPlanLocationCoordinates, name, temperatureValue } = this.state;
        return (
            <Paper className={classNames(classes.paper, classes.container, 'container')} elevation={5} square={false}>


                <Dialog classes={{ paper: classes.dialogContainer }}
                    onClose={() => this.handleCloseSensorModel()} maxWidth="lg"
                    fullWidth={true} aria-labelledby="Items-View" open={openSensorModel}>
                    <DialogTitle id="customized-dialog-title"
                        onClose={() => this.handleCloseSensorModel()}
                        style={{ textAlign: 'center' }}>Device Sensor Readings</DialogTitle>
                    <DialogContent >
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={7}>
                                <Grid container={true}>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Grid container={true} spacing={3} >
                                            <Grid item={true} xs={12} sm={4} >
                                                <ResponsiveContainer width="100%" height={120} className={classes.containerAlign}>
                                                    {light_sensor > 0 ? <div className={classNames(classes.toggle, classes.toggleLight)}   >
                                                        <div className={classNames(classes.dot, classes.dotLight)} style={{ textAlign: 'center' }}> <FaSun className={classes.sunIcon} /></div>
                                                        <div className={classNames(classes.toggleFont, classes.lightFont)}>Light</div>
                                                    </div> :
                                                        <div className={classNames(classes.toggle, classes.toggleDark)} >
                                                            <div className={classNames(classes.dot, classes.dotDark)} style={{ textAlign: 'center' }}> <FaRegMoon className={classes.moonIcon} /></div>
                                                            <div className={classNames(classes.toggleFont, classes.darkFont)}>Dark</div>
                                                        </div>}
                                                </ResponsiveContainer>
                                                <Typography component="p" align="center" margin={3} className={classes.textAlign} variant='body2'>Light Sensor</Typography>
                                            </Grid>

                                            <Grid item={true} xs={12} sm={4} >
                                                <ResponsiveContainer width="100%" height={120} className={classes.containerAlign}>
                                                    {motionValue !== 0 ? <div className={classNames(classes.toggle, classes.toggleShake)}   >
                                                        <div className={classNames(classes.dot, classes.dotShake)} style={{ textAlign: 'center' }}> < BiWalk className={classNames(classes.shakeIcon, classes.iconSize)} /></div>
                                                        <div className={classNames(classes.toggleFont, classes.shakeFont)}>Shake</div>
                                                    </div>
                                                        :
                                                        <div className={classNames(classes.toggle, classes.toggleStill)} >
                                                            <div className={classNames(classes.dot, classes.dotStill)} style={{ textAlign: 'center' }}> <ImMan className={classNames(classes.moonIcon, classes.iconSize)} /></div>
                                                            <div className={classNames(classes.toggleFont, classes.darkFont)}>Still</div>
                                                        </div>}
                                                </ResponsiveContainer>
                                                <Typography component="p" align="center" margin={3} className={classes.textAlign} variant='body2'>Motion Sensor</Typography>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={4} style={{ textAlign: 'center' }}>
                                                <ResponsiveContainer width="100%" height={150} >
                                                    <BatteryGauge value={batteryVoltageValue / 100} animated padding={5}
                                                        customization={{
                                                            batteryBody: {
                                                                strokeColor: 'silver',
                                                                strokeWidth: 2
                                                            }, batteryCap: {
                                                                capToBodyRatio: 0.4,
                                                                cornerRadius: 3,
                                                                strokeColor: 'silver',
                                                            },
                                                            batteryMeter: {
                                                                gradFill: [
                                                                    {
                                                                        color: 'red',
                                                                        offset: 0
                                                                    },
                                                                    {
                                                                        color: 'orange',
                                                                        offset: 15
                                                                    },
                                                                    {
                                                                        color: 'green',
                                                                        offset: 90
                                                                    }
                                                                ],
                                                                outerGap: 0
                                                            },
                                                            readingText: {
                                                                darkContrastColor: 'yellow',
                                                                // fontFamily: 'Arial',
                                                                //lightContrastColor: 'purple',
                                                                lowBatteryColor: 'red'
                                                            }
                                                        }} />
                                                </ResponsiveContainer>
                                                <Typography component="p" align="center" className={classes.textAlign} variant='body2'>Battery Voltage Sensor</Typography>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={4} style={{ textAlign: 'center' }}>
                                                <ResponsiveContainer width="100%" height={180} >
                                                    <ReactSpeedometer
                                                        maxValue={600}
                                                        minValue={0}
                                                        needleTransitionDuration={2000}
                                                        value={batteryTemperatureValue}
                                                        customSegmentLabels={[
                                                            {
                                                                text: 'COLD',
                                                            },
                                                            {
                                                                text: 'NOMINAL',
                                                            },
                                                            {
                                                                text: 'HOT',
                                                            },
                                                        ]}
                                                        labelFontSize="12px"
                                                        needleHeightRatio={0.7}
                                                        needleColor="black"
                                                        segments={3}
                                                        segmentColors={['#00bbf0', 'yellowGreen', 'tomato']}
                                                    />
                                                </ResponsiveContainer>
                                                <Typography component="p" align="center" className={classes.textAlign} variant='body2'>Battery Temperature Sensor</Typography>
                                            </Grid>



                                            <Grid item={true} xs={12} sm={4} style={{ textAlign: 'center' }}>
                                                <ResponsiveContainer width="100%" height="160px" >
                                                    <Thermometer steps={2} size={'small'} maxValue={105} minValue={-40} currentValue={temperatureValue} color="#0070df">
                                                    </Thermometer>
                                                </ResponsiveContainer>
                                                <Typography component="p" align="center" fontWeight="bold" className={classes.textAlign} variant='body2'>{temperatureValue + ' F'}</Typography>
                                                <Typography component="p" align="center" className={classes.textAlign} variant='body2'>Temperature Sensor</Typography>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={4} style={{ textAlign: 'center' }}>
                                                <ResponsiveContainer width="100%" height={180} >
                                                    <ReactSpeedometer
                                                        maxValue={50000}
                                                        minValue={10000}
                                                        needleTransitionDuration={2000}
                                                        value={pressureValue}
                                                        currentValueText={pressureValue + ' PA'}
                                                        needleHeightRatio={0.7}
                                                        needleColor="black"
                                                        startColor={'blue'}
                                                        endColor={"green"}
                                                        segments={4}
                                                    />
                                                </ResponsiveContainer>
                                                <Typography component="p" align="center" className={classes.textAlign} variant='body2'>Pressure Sensor</Typography>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={4} style={{ textAlign: 'center' }}>
                                                <ResponsiveContainer width="100%" height={180} >
                                                    <ReactSpeedometer
                                                        maxValue={100}
                                                        minValue={0}
                                                        needleTransitionDuration={2000}
                                                        value={humidity}
                                                        currentValueText={humidity + ' %'}
                                                        needleHeightRatio={0.7}
                                                        needleColor="black"
                                                        startColor={'yellowgreen'}
                                                        endColor={"red"}
                                                        segments={4}
                                                    />
                                                </ResponsiveContainer>
                                                <Typography component="p" align="center" className={classes.textAlign} variant='body2'>Humidity Temperature (RH)</Typography>
                                            </Grid>

                                            <Grid item={true} xs={12} sm={4} style={{ textAlign: 'center' }}>
                                                <ResponsiveContainer width="100%" height="160px" >
                                                    <Thermometer steps={2} size={'small'} maxValue={105} minValue={-40} currentValue={humidityTemp2Value} color="#0070df">
                                                    </Thermometer>
                                                </ResponsiveContainer>
                                                <Typography component="p" align="center" fontWeight="bold" className={classes.textAlign} variant='body2'>{humidityTemp2Value + ' F'}</Typography>
                                                <Typography component="p" align="center" className={classes.textAlign} variant='body2'>Humidity Temperature</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item={true} xs={12} sm={5}>
                                <MapComponent
                                    markersOptions={lastSensorReadingMarker}
                                    zoom={zoom} address={address}
                                    centerName={name}
                                    flightPositions={flightPlanCoordinates}
                                    center={lastSensorReadingCenter}
                                    containerStyle={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '55vh'
                                    }} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog classes={{ paper: classes.dialogContainer }}
                    onClose={() => this.handleLocationModel()} maxWidth="md"
                    fullWidth={true} aria-labelledby="Items-View" open={openLocationModel}>
                    <DialogTitle id="customized-dialog-title"
                        onClose={() => this.handleLocationModel()}
                        style={{ textAlign: 'center' }}>Location Trace</DialogTitle>
                    <DialogContent >
                        <Grid container={true} spacing={3}>

                            <Grid item={true} xs={12} sm={12} md={12}>
                                <MapComponent
                                    markersOptions={this.state.locationMarkers}
                                    zoom={zoom}
                                    address={address}
                                    centerName={name}
                                    flightPositions={flightPlanLocationCoordinates}
                                    center={center}
                                    containerStyle={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '55vh'
                                    }} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                
                <Grid container={true} >
                    <Grid container={true} style={{ marginTop: 2 }}>
                        <Grid item={true} xs={12} sm={10}>
                            <SearchBox
                                handleClickListItem={this.handleClickListItem}
                                anchorEl={anchorEl}
                                handleClose={this.handleClose}
                                filterMenuOptions={filterMenuOptions}
                                selectedFilterMenuIndex={selectedFilterMenuIndex}
                                handleMenuItemClick={this.handleMenuItemClick}
                                selectedFilterMenu={selectedFilterMenu}
                                searchBoxValue={searchBoxValue}
                                handleSearchBox={this.handleSearchBox}
                                onEnterKeyPress={this.onEnterKeyPress}
                                gridLabel={'Devices'} />
                        </Grid>
                      {/* <Grid item={true} xs={12} sm={2}>
                             <Typography component={'div'} style={{ display: 'flex', justifyContent: 'end', paddingRight: 8, paddingTop: 8 }}>
                                <Button onClick={() => this.exportDevicesData()}
                                    style={{ backgroundColor: '#f6f6f6' }}
                                    color="primary">Export
                                </Button>
                            </Typography> 
                        </Grid>*/}
                    </Grid>
                </Grid>
                {/* <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} /> */}
                {(Array.isArray(devices)) &&

                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        onRowClick={this.handleRowClick}
                        getRowId={(row) => row.deviceId}
                        rows={devices}
                        columns={this.columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        
                        components={{
                            Toolbar: this.CustomToolbar,
                            // Toolbar: () => (
                            //     <GridToolbar
                            //       sx={{float: "right", 
                            //       position: 'absolute',
                            //       top:'-10%',
                            //       right: '1%'
                            //     }}
                            //     />
                            //   ),
                        }}
                       
                          disableColumnFilter
                    />
                </Box>}
                {/* {(Array.isArray(devices) && devices.length > 0) &&
                    devices.slice(this.state.deviceTablePage * this.state.deviceTableRowsPerPage, this.state.deviceTablePage * this.state.deviceTableRowsPerPage + this.state.deviceTableRowsPerPage).map((device: IDevice, deviceIndex: number) => {

                        return (
                            <Grid container={true} key={device?.name}
                                className={classes.actionArea} justifyContent={"space-between"}
                                onDoubleClick={() => this.editDeviceRecord(device)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    boxShadow: 3,
                                    borderRadius: 2,
                                    textAlign: 'center',
                                    padding: 0.5,
                                    ':hover': {
                                        boxShadow: 20,
                                        backgroundColor: '#c2e3f1'
                                    },

                                }}>

                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline', 
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {device.customerName}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Customer Name"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline', 
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {device.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Device Name"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline',
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {device.nickName}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Nick Name"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2} md={2}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline',
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {device.imei}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"IMEI"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline',
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {(device.updateDateTime) ? new Date(device.updateDateTime).toLocaleDateString('en-US', dateOption) + ' ' + new Date(device.updateDateTime).toLocaleTimeString('en-US', timeOption) : '--'}

                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Last Reading"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline',
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    <FontAwesomeIcon className={classes.iconPointer} icon={faObjectGroup as any}
                                                        onClick={() => this.readingDeviceSensorDetailsByIMEI(device.imei)} />
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Device reading"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText onClick={() => this.locationBoundary(device)}
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline', 
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    <BsBoundingBoxCircles />
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Location Boundary"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline', 
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    <FontAwesomeIcon className={classes.iconPointer} icon={faSearchLocation as any}
                                                        onClick={() => this.readingDeviceLocationDetailsByIMEI(device.imei)} />
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Location reading"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline',
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >

                                                    <GrMapLocation className={classes.iconPointer} onClick={() => this.readingLocation(device)} />
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Location Trace"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline',
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    <FontAwesomeIcon className={classes.iconPointer} icon={faTachometerAlt as any} onClick={() => this.readingSensor(device)} />
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Sensors"}
                                    />
                                </Grid>


                            </Grid>
                        )
                    })}

                {(Array.isArray(devices) && devices.length > 0) && <TablePagination
                    component="div"
                    count={totalResults ? totalResults : 0}
                    rowsPerPage={this.state.deviceTableRowsPerPage}
                    page={this.state.deviceTablePage}
                    backIconButtonProps={{ 'aria-label': 'Previous Page', 'onClick': this.loadPreviousPage }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page', 'onClick': this.loadNextPage, }}
                    onPageChange={this.handleChangeDevicePage}
                    onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                />}

                {(Array.isArray(devices) && devices.length === 0) ?
                        <div style={{ 'textAlign': 'center','margin':10 }}>No Record Found</div>
                : null} */}

            </Paper>)
    }
}
const styles = (theme: Theme) => ({
    paper: {
        overflow: 'hidden',
        color: theme.palette.text.secondary,
    },
    container: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    tableContainer: {
        padding: '1px',
        overflow: 'auto'
    },
    iconPointer: {
        cursor: 'pointer'
    },
    dialogContainer: {
        // width: '50%',
        //height: '50%',
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%',
        //     height: '100%',
        //     margin: 0,
        //     maxWidth: '100%',
        //     maxHeight: 'none',
        //     borderRadius: 0,
        // },
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    tableHeaderBg: {
        backgroundColor: '#1976d2',
        fontWeight: 700,
    },
    flexContainer: {
        scrollBehavior: 'smooth',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto',
        flexFlow: "column wrap",
        [theme.breakpoints.between('sm', 'xl')]: {
            //  padding: 15,
        },
        [theme.breakpoints.only('xs')]: {
            // paddingTop: 20,
            // paddingBottom: 20,
        },
    },
    account: {

        backgroundColor: "#EBEBEB",
        [theme.breakpoints.only('xs')]: {
            marginBottom: '32%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
        overflow: 'hidden',
    },
    loader: {
        position: 'sticky',
        'text-align': 'center',
        margin: '0 auto',
        top: '50%',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },

    toggleLight: {
        background: '#fff9ee',
    },
    toggle: {
        marginLeft: '20%',
        marginTop: '5px',
        height: '45%',
        width: '60%',
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0px 0px 6px -2px #111',
        borderRadius: '40px',
    },
    toggleDark: {
        background: '#3c3c3c',
        flexDirection: 'row-reverse'
    },
    toggleShake: {
        background: '#f64b32'
    },
    toggleStill: {
        background: '#59d7b3',
        flexDirection: 'row-reverse'
    },
    dotShake: {
        backgroundColor: '#f2e2de'
    },
    dotStill: {
        backgroundColor: '#b5e7dc'
    },
    dotLight: {
        backgroundColor: '#ffff',
    },
    dot: {
        width: '30%',
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderRadius: '50%',
        boxShadow: "0px 0px 6px -2px #111"
    },
    dotDark: {
        backgroundColor: '#5f5e5e',
    },
    shakeIcon: {
        color: '#dd775b'
    },
    sunIcon: {
        color: '#ffa500',
    },
    iconSize: {
        // fontSize: '24px'
    },
    moonIcon: {
        color: '#ffff',
    },
    lightFont: {
        marginLeft: '20px',
        color: '#ffa500'
    },
    shakeFont: {
        marginLeft: '20px',
        color: '#f2e2de'
    },
    toggleFont: {
        fontWeight: 'bold'
    },
    darkFont: {
        marginRight: '20px',
        color: '#ffff',
    },
    containerAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    media: {
        width: '100%',
        height: '100%',
        padding: 0,
        margin: 0,
    },

});
const mapStateToProps = (state: any) => {
    return {
        devices: state.device.devices,
        boundaryByDevice: state.device.boundaryDetailsByDeviceId,
        totalResults: state.device.totalResults,
        utility: state.utility,
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getDevicesByRange: DeviceActions.getDevicesByRange,
        editDeviceDetail: DeviceActions.editDeviceDetails,
        getLocations: SensorReadingsActions.getAllLocationMarkers,
        getLocationsByDeviceId: DeviceActions.getLocationsByDeviceId,
        getLocationReadingsDetailsByRange: LocationActions.getLocationReadingsDetailsByRange,
        getBoundaryByDeviceId: DeviceActions.getBoundaryByDeviceId,
        saveBoundaryByDeviceID: DeviceActions.saveBoundaryByDeviceID,
        resetBoundaryByDeviceId: DeviceActions.resetBoundaryByDeviceId
    }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(Devices as any)) as any);

