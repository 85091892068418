import { RouteAccess } from '../AppConstants';
import LoginButton from '../components/Auth/LoginButton';
import DeviceGrid from '../pages/device/Devices';
import DeviceForm from '../pages/device/DeviceForm';
import SensorReadings from '../pages/sensor-readings/SensorReadings';
import Customers from '../pages/customers/Customers';
import CustomerForm from '../pages/customers/CustomerForm';
import locationReadings from '../pages/location-readings/location-readings';
import LocationSearchMap from '../pages/device/LocationSearchMap';
import Notification from '../pages/notification/Notification';
const routes = [
  {
    path: '/login',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/edit-device-details/:id',
    component: DeviceForm,
    access: RouteAccess.Administrator,
    exact: true,
  },

  {
    path: '/devices',
    component: DeviceGrid,
    access: RouteAccess.Administrator,
    exact: true,
  },
  {
    path: '/device-register',
    component: DeviceForm,
    access: RouteAccess.Administrator,
    exact: true,
  },
  {
    path: '/location-boundary/:id',
    exact: true,
    access: RouteAccess.Administrator,
    component: LocationSearchMap,
  },
  {
    path: '/sensor-readings',
    exact: true,
    access: RouteAccess.Administrator,
    component: SensorReadings,
  },
  {
    path: '/location-readings',
    exact: true,
    access: RouteAccess.Administrator,
    component: locationReadings,
  },
  {
    path: '/customers',
    exact: true,
    access: RouteAccess.Administrator,
    component: Customers,
  },
  {
    path: '/add-customer',
    exact: true,
    access: RouteAccess.Administrator,
    component: CustomerForm,
  },
  {
    path: '/edit-customer/:id',
    exact: true,
    access: RouteAccess.Administrator,
    component: CustomerForm,
  },
  {
    path: '/notifications',
    component: Notification,
    access: RouteAccess.Administrator,
    exact: true,
  },

];

export default routes;
