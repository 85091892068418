import { faComment, faObjectGroup, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DevicesIcon from '@mui/icons-material/Devices';
import GroupIcon from '@mui/icons-material/Group';
import { RouteAccess } from '../AppConstants';

const SideNavOptions = [
  { path: '/customers', title: 'Customers', icon: () => <GroupIcon/> },
  { path: '/devices', title: 'Devices', icon: () => <DevicesIcon />, access: RouteAccess.Administrator },
  { path: '/sensor-readings', title: 'Sensor Readings', icon: () => <FontAwesomeIcon icon={faObjectGroup as any} /> },
  { path: '/location-readings', title: 'location Readings', icon: () => <FontAwesomeIcon icon={faSearchLocation as any} /> },
  { path: '/notifications', title: 'Notifications', icon: () =><FontAwesomeIcon icon={faComment as any}/> },

];

export default SideNavOptions;