import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow, Circle, Polygon} from 'google-maps-react';
interface IPageProps {
    google?: any;
    center: ILatAndLng;
    markersOptions: any[];
    zoom: number;
    containerStyle: any;
    flightPositions?: ILatAndLng[]
    address: string;
    centerName: string
    mapViewFrom?: string;
    dragableMarker?:boolean;
    showCircle?:boolean;
    showPolygon?:boolean;
    ondragMarkerCoord: (ILatAndLng: any) => any;
    circleRadius?: number;
    polygonCoord?:[];
}
interface ILatAndLng {
    lat: number;
    lng: number;
}
class MapComponent extends Component<IPageProps, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false
        };
    }


    private onMarkerClick = (props: any, marker: any) =>
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
        });

    private onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });

    private onMapClicked = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                activeMarker: null,
                showingInfoWindow: false
            });
        }

    };
    private onMarkerDragEnd = (coord,map,t) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.props.ondragMarkerCoord({lat:lat,lng:lng});
    }
    private onPolygonDrag = (coord,map,t) => {
        console.log(coord,map,t)
        
    }
    private onEditPolygon =(data)=>{
        console.log(data)

    }
    public render() {    
        const { markersOptions, flightPositions, center, zoom, containerStyle, address, mapViewFrom ,dragableMarker,showCircle,circleRadius,showPolygon,polygonCoord} = this.props;
        let staticCoords=   [
            {
                lat:31.28396974288662,
                lng:-99.5361328125,
            },
            {
                lat:35.28396974288662,
                lng:-99.5361328125,
            },
            {
                lat:29.93948823006319,
                lng: -97.76953125
            },
            {
                lat:29.93948823006319,
                lng: -97.76953125
            },  
        ];
        return (
            <Map
                google={this.props.google}
                onClick={this.onMapClicked}
                initialCenter={center ? (center.lat !== null && center.lat !== 0) ? center : {
                    lat: 41.676388,
                    lng: -86.250275
                } : {
                    lat: 41.676388,
                    lng: -86.250275
                }
                }
                center={{
                    lat: (center.lat !== null && center.lat !== 0) ? center.lat : 41.676388,
                    lng: (center.lng !== null && center.lng !== 0) ? center.lng : -86.250275
                }
                }
                /** @ts-ignore */
                //flightPlanCoordinates={flightPositions.length > 0 ? flightPositions : []}
                zoom={center ? (center.lat !== null && center.lat !== 0) ? zoom : 3 : 3}
                containerStyle={containerStyle}
            > 
                 {showCircle && <Circle center={{ lat: center.lat, lng: center.lng }} radius={circleRadius?circleRadius*100 :2000}  strokeColor= '#ff0000'></Circle>}

                {(Array.isArray(markersOptions) && markersOptions.length > 0) ? markersOptions.map((marker: any, markerIndex: number) => {
                    if (((marker.latitude && marker.longitude) && (marker.latitude !== "" && marker.longitude !== ""))) {
                        return (
                            <Marker key={`marker${markerIndex}`}
                                /** @ts-ignore */
                                label={''} // (mapViewFrom && mapViewFrom === "customerView") ? marker.name : markerIndex.toString()
                                /** @ts-ignore */
                                name={(mapViewFrom && mapViewFrom === "customerView") ? marker.name : markerIndex.toString()}
                                onClick={this.onMarkerClick}
                                position={{ lat: marker.latitude, lng: marker.longitude }}
                            />)
                    }
                    return (null)
                }) : (center.lat !== null && center.lat !== 0) && (dragableMarker?
                    <Marker
                    /** @ts-ignore */
                    name={address ? address : ''}
                    label={''} // (mapViewFrom && mapViewFrom === "customerView") ? centerName ? centerName : 'H' :
                    onClick={this.onMarkerClick}
                    draggable={true}
                    onDragend={(t, map, coord) => this.onMarkerDragEnd(coord,map,t)}
                    position={{ lat: center.lat, lng: center.lng }}
                />: 
                    <Marker
                    /** @ts-ignore */
                    name={address ? address : ''}
                    label={''} // (mapViewFrom && mapViewFrom === "customerView") ? centerName ? centerName : 'H' :
                    onClick={this.onMarkerClick}
                    position={{ lat: center.lat, lng: center.lng }}
                />)}

                {showPolygon &&
                <Polygon
                    paths={ polygonCoord?.length?polygonCoord:staticCoords}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor="#0000FF"
                    fillOpacity={0.35} 
                    onClick={(event:any)=>{console.log('working',event)}}
                    draggable={true}
                    onDragEnd={(coord,map,t) => this.onPolygonDrag(coord,map,t)}
                    editable={true}
                    onMouseUp={this.onEditPolygon}
                /> }
             
                <InfoWindow
                    marker={this.state.activeMarker}
                    /** @ts-ignore */
                    onClose={this.onInfoWindowClose}
                    visible={this.state.showingInfoWindow}
                >
                    <div>
                        <h4>{
                            /** @ts-ignore */
                            this.state.selectedPlace.name
                        }</h4>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}
const Container = GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ? process.env.REACT_APP_GOOGLE_MAP_KEY : '', version: "3.38", region: 'US' })((MapComponent as any) as React.ComponentType<any>);
export default Container;