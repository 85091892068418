import { useAuth0 } from '@auth0/auth0-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { CSSObject, IconButton, Menu, MenuItem, styled, Theme, Toolbar, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { NavLink, Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SideNavOptions from '../route-configs/SideNavOptions';
import { getLoginUserDetailsByToken, userLogout } from '../store/actions/App.Actions';
import VerifyEmail from '../pages/verify-email/VerifyEmail';

const classNames = require('classnames');
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    position: 'relative',
    background: theme.palette.primary.main,
    top: 0,
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })

  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
    },
  },
  paper: {
    marginTop: '45px',
    marginLeft: '13px',
    border: '1px solid rgba(0, 0, 0, 0.26)'
  },

  fillSpace: {
    flex: '1 1 auto'
  }
}));
const SideMiniDrawer = (props: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isLoading, isAuthenticated } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const locationActive = window.location.pathname;
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const { logout, routes, history } = props;
  const { user, authLoader } = useSelector((state: any) => state.auth);
  const { isAppLoader } = useSelector((state: any) => state.common);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [countMakeCall, setCountMakeCall] = useState(0);
  useEffect(() => {
    if (isAuthenticated && !isLoading && !user && !isAppLoader && !authLoader) {
      if (countMakeCall <= 4) {
        dispatch(getLoginUserDetailsByToken())
        setCountMakeCall(countMakeCall + 1);
      }

    }
  }, [dispatch, user, isAuthenticated, isLoading, isAppLoader, authLoader, countMakeCall]);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logoutAuth0 = () => {
    setAnchorElMenu(null);
    logout();
    dispatch(userLogout())
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  };



  const handleClickOpenMenu = (event: any) => {
    setAnchorElMenu(event.currentTarget)
  };

  const gotoProfile = () => {
    setAnchorElMenu(null)
    history.push(`/profile`);
  }


  useEffect(() => {
    if (!localStorage.getItem('userType')) {
      if (isAuthenticated && !isLoading && user) {
        localStorage.setItem("userType", user.userType)
      }
    }
    if (user && user.userType !== localStorage.getItem('userType')) {
      localStorage.setItem("userType", user.userType)
    }
  }, [isAuthenticated, isLoading, user]);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" style={{ backgroundColor: '#c2e3f1' }} open={open}>
        <Toolbar >
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.fillSpace} component={'div'} color="primary" noWrap={true}>
            <img alt="icon-logo" src="https://www.mywirelessfence.com/assets/images/logo.png" height="30px" />
          </Typography>
          <Typography variant='body1' color="primary"> Welcome, {user?.userName}</Typography >
          <IconButton aria-label="show profile" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClickOpenMenu(event)} >
            <AccountCircle />
          </IconButton>
          <Menu style={{ top: '8px' }}
            id="simple-menu-open"
            anchorEl={anchorElMenu}
            keepMounted={true}
            open={Boolean(anchorElMenu)}
            onClose={() => setAnchorElMenu(null)}
          >
            <MenuItem onClick={() => gotoProfile()}>Profile</MenuItem>
            <MenuItem onClick={() => logoutAuth0()}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} classes={{
        paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            { /** @ts-ignore */
              theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />
            }
          </IconButton>
        </DrawerHeader>
        <Divider />
        {(user && user.verified) ?
          SideNavOptions.map((route: any, index: number) => {
            return (
              <NavLink
                activeStyle={{ fontWeight: "bold" }}
                activeClassName="active"
                style={{ textDecoration: 'none', color: 'black' }}
                key={index} to={route.path}>
                <ListItem button={true}>
                  <ListItemIcon>
                    {route.icon()}
                  </ListItemIcon>
                  <ListItemText primary={route.title} />
                </ListItem>
              </NavLink>
            )
          }) : <></>}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 1, width: '90%' }}>
        <DrawerHeader />
        {(locationActive === process.env.PUBLIC_URL || locationActive === process.env.PUBLIC_URL + '/') && (
          <>
            {(user && user.verified) ? <Route exact path="/" component={() => (<Redirect to="/devices" />)} /> : ''}
            {(user && user.verified) ? <Route exact path="/verifyEmail" component={() => (<Redirect to="/devices" />)} /> : ''}
            {(user && !user.verified) ? <Route exact path="/" component={() => (<Redirect to="/verifyEmail" />)} /> : ''}
          </>
        )}
        {(user && user.verified) ? renderRoutes(routes) : null}
        {(user && !user.verified) ? (<Route exact  path="/verifyEmail" component={() => <VerifyEmail />} />) : <></>}
        {(user && user.verified) ? <Route exact path="/verifyEmail" component={() => (<Redirect to="/devices" />)} /> : ''}
        {props.children}
        <ToastContainer autoClose={5000} />
      </Box>
    </Box >
  );
}

export default SideMiniDrawer;
