import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables';
import { AppState } from "../../state/AppState";
import { Spinner } from '../../state/Spinner';
import { ActionType, IAppAction } from './Helpers';


export const getNotificationByRange = (start: number, limit: number, parameterName: string, parameterValue: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.notifications.getNotificationByRange.setStart = start;
        ApiVariables.notifications.getNotificationByRange.setLimit = limit;
        ApiVariables.notifications.getNotificationByRange.setParameterName = parameterName;
        ApiVariables.notifications.getNotificationByRange.setParameterValue = parameterValue;
        api(ApiVariables.notifications.getNotificationByRange).then((response: any) => {
            dispatch({ type: ActionType.GET_NOTIFICATION_LIST, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
