import { Input } from "@mui/material";
import React from "react";
import InputMask from 'react-input-mask';



export const PhoneNumberMaskInput = (props: any) => (
    <InputMask mask="+1 (999) 999-9999"
        disabled={props.disabled} name={props.name}
        id={props.id} value={props.value} onChange={props.onChange}>
        {(inputProps: any) => <Input {...inputProps} disabled={props.disabled ? props.disabled : false} type="tel" />}
    </InputMask>
);