import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@mui/styles';
import React from 'react';
const styles = {
	app: {
		width: '100%',
		height: '100%',
	},
	crest: {
		position: 'absolute',
		animationName: '$shrink',
		animationDelay: '1s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
		bottom: 30,
		transition: 'all .3s',
		left: 55,
		'& path': {
			fill: '#04264C'
		}
	},
	logo: {
		position: 'absolute',
		height: 125,
		opacity: 0,
		animationName: '$fadeLogo',
		animationDelay: '1s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
		marginLeft: 30,
		bottom: 30,
		right: 100,
		'& path': {
			fill: '#04264C'
		}
	},
	loginButton: {
		opacity: 0,
		animationName: '$fade',
		animationDelay: '1.5s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
	},
	'@keyframes shrink': {
		to: { height: 125, left: 0 }
	},
	'@keyframes fadeLogo': {
		to: { opacity: 1, right: 0 }
	},
	'@keyframes fade': {
		to: { opacity: 1 }
	},
};

class SignIn extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loader: false
		};
	}
	render() {
		const { auth0 } = this.props;
		return (<>{auth0?.loginWithRedirect()}</>);
	}
}

export default withStyles(styles as any)(withAuth0(SignIn));

