import { Grid, ListItemText, Paper, TablePagination, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import { GridToolbar } from '@mui/x-data-grid/components';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import { SearchBox } from '../../components/search/SearchBox';
import IFilterMenuOptionsModel from '../../models/common/IFilterMenuOptionsModel';
import IFilterModel from '../../models/common/IFilterModel';
import { IUtility } from '../../state/Utility';
import * as NotificationActions from '../../store/actions/Notification.Action';

const classNames = require('classnames');

interface IPageProps {
    messages?: any[],
    classes: any,
    getNotificationByRange: (start: number, limit: number, paramName?: string, paramValue?: string) => void;
    totalResults?: number;
    location:any;
}

interface IPageState {
    messageTablePage: number,
    messageTableRowsPerPage: number,
    pageStartCount: number,
    anchorEl: any;
    selectedFilterMenuIndex: number,
    filterMenuOptions: IFilterMenuOptionsModel[],
    selectedFilterMenu: string | number | boolean;
    selectedFilterMenuKey: string;
    searchBoxValue: string;
    searchBoxFilterKey: string,
   }


class Notification extends React.Component<IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();

    constructor(props: IPageProps) {
        super(props);
        this.state = {
            messageTablePage: 0,
            messageTableRowsPerPage: 10,
            pageStartCount: 10,
            anchorEl: null,
            selectedFilterMenuIndex: 0,
            filterMenuOptions: [{ "label": 'message type', "key": 'messageType' },
            { "label": 'IMEI', "key": 'imei' },
            { "label": 'Customer Name', "key": 'customerName' }],
            selectedFilterMenu: 'message type',
            selectedFilterMenuKey: 'messageType',
            searchBoxValue: '',
            searchBoxFilterKey: '',
        }
    }
    columns: GridColDef[] = [
        {
          field: 'messageType',
          headerName: 'Message Type',
          width: 150,
          editable: false,
        },
        {
            field: 'messageBody',
            headerName: 'Message',
            width: 700,
            editable: false,
        },
        {
          field: 'customerName',
          headerName: 'Customer Name',
          width: 200,
          editable: false,
          valueGetter:this.getCustomer
        },
        {
            field: 'deviceName',
            headerName: 'Device Name',
            width: 200,
            editable: false,
            valueGetter:this.getDeviceName
        },

      ];

    getCustomer(param){
        return `${param.row.device.customer ? param.row.device.customer?.name:'--'}`;
    }
    getDeviceName(param){
        return `${param.row.device.name ? param.row.device.name :'--'}`;
    }
   
    public async componentWillMount() {
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this, messages: {
                integer: "Please enter only number"
            }
        });
        // const { customerName } = (this.props.location && this.props.location.state) || {};
        // if (customerName) {
        //     await this.props.getNotificationByRange(0, 10, 'customerName', customerName);
        // } else {
             await this.props.getNotificationByRange(0, 10);
        // }
    }
    public componentDidMount() {
        // const { customerName } = (this.props.location && this.props.location.state) || {};
        // if (customerName) {
        //     this.setState({
        //         searchBoxValue: customerName,
        //         selectedFilterMenu: 'Customer Name',
        //         selectedFilterMenuKey: 'customerName',
        //         searchBoxFilterKey: 'customerName',
        //         selectedFilterMenuIndex: 1
        //     })
        // }
    }


    private handleChangemessagePage = (event: any, page: number) => {
        console.log('page', page)
    };

    private handleChangeTableRowsPerPage = async (event: any) => {
        await this.props.getNotificationByRange(0, event.target.value as number, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ messageTableRowsPerPage: event.target.value, pageStartCount: event.target.value, messageTablePage: 0 });
    };

    private loadNextPage = async () => {
        await this.props.getNotificationByRange(this.state.pageStartCount, this.state.messageTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ messageTablePage: this.state.messageTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.messageTableRowsPerPage });
    }

    private loadPreviousPage = () => {
        this.setState({ messageTablePage: this.state.messageTablePage - 1, pageStartCount: this.state.pageStartCount - this.state.messageTableRowsPerPage })
    }

    private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: IFilterModel) => {
        this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key })
    };
    private handleClose = () => {
        this.setState({ anchorEl: null, });
    }
    private handleSearchBox = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private onEnterKeyPress = (event: any) => {
        if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
            this.callSearchByParam();
        }
    }
    private callSearchByParam = async () => {
        await this.props.getNotificationByRange(0, this.state.messageTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    }
 
 
    public render(): JSX.Element {
        const { messages, classes, totalResults } = this.props; // utility
        const { selectedFilterMenuIndex, anchorEl, selectedFilterMenu, filterMenuOptions,
            searchBoxValue,} = this.state;
        return (
            <Paper className={classNames(classes.paper, classes.container, 'container')} elevation={5} square={false}>
               <Grid container={true} >
                    <Grid container={true} style={{ marginTop: 2 }}>
                        <Grid item={true} xs={12} sm={10}>
                            <SearchBox
                                handleClickListItem={this.handleClickListItem}
                                anchorEl={anchorEl}
                                handleClose={this.handleClose}
                                filterMenuOptions={filterMenuOptions}
                                selectedFilterMenuIndex={selectedFilterMenuIndex}
                                handleMenuItemClick={this.handleMenuItemClick}
                                selectedFilterMenu={selectedFilterMenu}
                                searchBoxValue={searchBoxValue}
                                handleSearchBox={this.handleSearchBox}
                                onEnterKeyPress={this.onEnterKeyPress}
                                gridLabel={'Messages'} />
                        </Grid>
                       
                    </Grid>
                </Grid>
                 {(Array.isArray(messages)) &&
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row.messageId}
                            rows={messages}
                            columns={this.columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            disableColumnFilter
                            components={{
                                Toolbar: () => (
                                    <GridToolbar
                                      sx={{float: "right", 
                                      position: 'absolute',
                                      top:'-10%',
                                      right: '1%'
                                    }}
                                    />
                                  ),
                            }}
                        /> 
                    </Box>} 
                 {/* <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} />
                {(Array.isArray(messages) && messages.length > 0) &&
                    messages.slice(this.state.messageTablePage * this.state.messageTableRowsPerPage, this.state.messageTablePage * this.state.messageTableRowsPerPage + this.state.messageTableRowsPerPage).map((message: any, messageIndex: number) => {

                        return (
                            <Grid container={true} key={messageIndex}
                                className={classes.actionArea} justifyContent={"space-between"}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    boxShadow: 3,
                                    borderRadius: 2,
                                    textAlign: 'center',
                                    padding: 0.5,
                                    ':hover': {
                                        boxShadow: 20,
                                        backgroundColor: '#c2e3f1'
                                    },

                                }}>

                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline', 
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {message.messageType}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Message Type"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={5}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline', 
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {message.messageBody}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"message"}
                                    />
                                </Grid>
                               
                                
                                <Grid item xs={6} sm={1} md={2}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline', 
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {message.device.customer.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Customer Name"}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1} md={1}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline', 
                                                    }}
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {message.device.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={"Device Name"}
                                    />
                                </Grid>                           
                                 </Grid>
                        )
                    })}

                {(Array.isArray(messages) && messages.length > 0) && <TablePagination
                    component="div"
                    count={totalResults ? totalResults : 0}
                    rowsPerPage={this.state.messageTableRowsPerPage}
                    page={this.state.messageTablePage}
                    backIconButtonProps={{ 'aria-label': 'Previous Page', 'onClick': this.loadPreviousPage }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page', 'onClick': this.loadNextPage, }}
                    onPageChange={this.handleChangemessagePage}
                    onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                />}
                
                 {(Array.isArray(messages) && messages.length === 0) ?
                        <div style={{ 'textAlign': 'center','margin':10 }}>No Record Found</div>
                : null} */}

            </Paper>)
    }
}
const styles = (theme: Theme) => ({
    paper: {
        overflow: 'hidden',
        color: theme.palette.text.secondary,
    },
    container: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    tableContainer: {
        padding: '1px',
        overflow: 'auto'
    },
    iconPointer: {
        cursor: 'pointer'
    },
    dialogContainer: {
        // width: '50%',
        //height: '50%',
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%',
        //     height: '100%',
        //     margin: 0,
        //     maxWidth: '100%',
        //     maxHeight: 'none',
        //     borderRadius: 0,
        // },
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    tableHeaderBg: {
        backgroundColor: '#1976d2',
        fontWeight: 700,
    },
    flexContainer: {
        scrollBehavior: 'smooth',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto',
        flexFlow: "column wrap",
        [theme.breakpoints.between('sm', 'xl')]: {
            //  padding: 15,
        },
        [theme.breakpoints.only('xs')]: {
            // paddingTop: 20,
            // paddingBottom: 20,
        },
    },
    account: {

        backgroundColor: "#EBEBEB",
        [theme.breakpoints.only('xs')]: {
            marginBottom: '32%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
        overflow: 'hidden',
    },
    loader: {
        position: 'sticky',
        'text-align': 'center',
        margin: '0 auto',
        top: '50%',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },

    toggleLight: {
        background: '#fff9ee',
    },
    toggle: {
        marginLeft: '20%',
        marginTop: '5px',
        height: '45%',
        width: '60%',
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0px 0px 6px -2px #111',
        borderRadius: '40px',
    },
    toggleDark: {
        background: '#3c3c3c',
        flexDirection: 'row-reverse'
    },
    toggleShake: {
        background: '#f64b32'
    },
    toggleStill: {
        background: '#59d7b3',
        flexDirection: 'row-reverse'
    },
    dotShake: {
        backgroundColor: '#f2e2de'
    },
    dotStill: {
        backgroundColor: '#b5e7dc'
    },
    dotLight: {
        backgroundColor: '#ffff',
    },
    dot: {
        width: '30%',
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderRadius: '50%',
        boxShadow: "0px 0px 6px -2px #111"
    },
    dotDark: {
        backgroundColor: '#5f5e5e',
    },
    shakeIcon: {
        color: '#dd775b'
    },
    sunIcon: {
        color: '#ffa500',
    },
    iconSize: {
        fontSize: '24px'
    },
    moonIcon: {
        color: '#ffff',
    },
    lightFont: {
        marginLeft: '20px',
        color: '#ffa500'
    },
    shakeFont: {
        marginLeft: '20px',
        color: '#f2e2de'
    },
    toggleFont: {
        fontWeight: 'bold'
    },
    darkFont: {
        marginRight: '20px',
        color: '#ffff',
    },
    containerAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    media: {
        width: '100%',
        height: '100%',
        padding: 0,
        margin: 0,
    },

});
const mapStateToProps = (state: any) => {
    return {
        messages: state.notification.notificationList,
        totalResults: state.notification.totalResults,
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getNotificationByRange: NotificationActions.getNotificationByRange,
      
    }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(Notification as any)) as any);

