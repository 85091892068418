import axios from "axios";
import { toast } from "react-toastify";

export const axiosInstance: any = axios.create({
    headers: {
        "Accept": '*/*',
    },
});

export const logoutSessionExpire = async () => {
    localStorage.clear();
    //history.push('/account/login');
    window.location.reload()
    /* tslint:disable */
    axiosInstance.defaults.headers.common['Authorization'] = ''
    toast('Your session has expired, please login', { type: toast.TYPE.ERROR });
}
