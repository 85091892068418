
import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables';
export const getLocationReadingsDetailsByRange = (start: number, limit: number, parameterName?: string, parameterValue?: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.locationReadings.getLocationReadingsByRange.setStart = start;
        ApiVariables.locationReadings.getLocationReadingsByRange.setLimit = limit;
        ApiVariables.locationReadings.getLocationReadingsByRange.setParameterName = parameterName;
        ApiVariables.locationReadings.getLocationReadingsByRange.setParameterValue = parameterValue;
        api(ApiVariables.locationReadings.getLocationReadingsByRange).then((response: any) => {
            dispatch({ type: ActionType.GET_LOCATION_READINGS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};