import { ActionType, IAppAction } from '../actions/Helpers';
const initialState = {
    users: Array(),
    totalResults: Number(0),
    usersPreviousCount: Number(0),
    editUserDetails: Object(null),
    userSelected: Array(),
}
const unwiredUsersReducer = (state = initialState, action: IAppAction) => {
    switch (action.type) {
        case ActionType.GET_USERS:
            let updateUsersArray: any[] = [];
            if (action.payload.data) {
                if (state.users.length === 0) {
                    updateUsersArray.push(...action.payload.data)
                } else if ((state.usersPreviousCount === action.payload.data.length) || (state.totalResults - state.users.length === action.payload.data.length)) {
                    if (action.payload.data.length !== 0) {
                        updateUsersArray = [...state.users]
                    }
                    updateUsersArray.push(...action.payload.data)
                } else {
                    updateUsersArray.push(...action.payload.data)
                }
            }

            return { ...state, users: action.payload.data, usersPreviousCount: action.payload.data ? action.payload.data.length : 0, totalResults: action.payload.totalResults };
        case ActionType.EDIT_USER_DETAILS:
            return { ...state, editUserDetails: action.payload };
        case ActionType.GET_USER_SELECTED:
            return { ...state, userSelected: action.payload.data };

        default:
            return state
    };
};
export default unwiredUsersReducer;