import { Button, FormControl, Grid, Input, InputLabel, Paper, Theme, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import ICustomerUserDetailMapView from '../../models/customer/ICustomerUserDetailMapView';
import ICustomer from '../../models/customer/ICustomerView';
import * as CustomerAction from '../../store/actions/Customers.Action';
const classNames = require('classnames');

interface IPageProps {
    classes?: any,
    editCustomerDetails: ICustomerUserDetailMapView;
    match: any;
    history: any;
    userDetails: any
    postSaveOrUpdateCustomer: (body: string) => any;
    resetTheEditCustomerData: () => void;
    putUpdateCustomer: (customerId: string, body: string) => any;
}
interface IPageState extends ICustomer {
    title: string;
}


class CustomerForm extends React.Component<IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();

    constructor(props: IPageProps) {
        super(props);
        this.state = {
            title: 'welcome',
            ...props.editCustomerDetails
        }
    }
    public componentWillMount = () => {
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this, messages: {
                integer: "Please enter only number"
            }
        });
    }
    public componentDidMount() {
        if (Object.keys(this.props.editCustomerDetails).length > 0 && this.props.editCustomerDetails.customerUserDetailMapViewId && this.props.editCustomerDetails.customerUserDetailMapViewId.customerId) {
            const { customerId } = this.props.editCustomerDetails.customerUserDetailMapViewId;
            this.setState({ customerId, appOwner: { appOwnerId: this.props.editCustomerDetails.customerUserDetailMapViewId.appOwnerId } })
        }
    }
    public componentWillUnmount() {
        this.props.resetTheEditCustomerData();
    }
    // public componentDidUpdate(prevProps: IPageProps, prevState: IPageState) {
    //     debugger;
    //     if (prevProps.editCustomerDetails.customerUserDetailMapViewId.customerId !== this.props.editCustomerDetails.customerId) {
    //         const { customerId } = prevProps.editCustomerDetails;
    //         this.setState({ customerId });
    //     }
    // }
    private onhandleChangeForm = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }

    private handleSubmitCustomerDetails = () => {
        if (this.validator.allValid()) {
            const { customers, userId } = this.props.userDetails;
            const updateOrCreateCustomerPayload: any = Object.assign({}, this.state);
            delete updateOrCreateCustomerPayload.title;
            updateOrCreateCustomerPayload.user = { userId: userId };
            updateOrCreateCustomerPayload.userId = userId;
            if (customers) {
                updateOrCreateCustomerPayload.appOwnerId = customers[0]?.appOwner?.appOwnerId;
            }
            if ((Object.keys(this.props.editCustomerDetails).length > 0)) {
                this.props.putUpdateCustomer(updateOrCreateCustomerPayload.customerId, JSON.stringify(updateOrCreateCustomerPayload)).then((response: any) => {
                    if (response && response.status.success === "SUCCESS") {
                        this.props.history.push('/customers');
                    }
                }).catch((error: any) => {
                    console.error(error);
                });
            } else {
                this.callPostSaveOrUpdateCustomer(JSON.stringify(updateOrCreateCustomerPayload));
            }
        }

    }

    private callPostSaveOrUpdateCustomer = async (payload: string) => {
        await this.props.postSaveOrUpdateCustomer(payload).then((response: any) => {
            if (response && response.status.success === "SUCCESS") {
                this.props.history.push('/customers');
            }
        }).catch((error: any) => {
            console.error(error);
        });
    }

    private handleCancel = () => {
        this.props.history.push('/customers');
    }
    private generateUserName = () => {
        const { contactFirstName, userName, contactLastName } = this.state;
        if ((contactFirstName && contactLastName) && (contactFirstName !== "" && contactLastName !== "")) {
            if (userName === undefined || userName === null || userName === "") {
                this.setState({ userName: (contactFirstName + '.' + contactLastName).toLowerCase() })
            }
        }
    }
    public render() {
        this.validator.purgeFields();
        const { classes } = this.props;
        const {
            name, address1, address2,
            city, state, zip,
            contactFirstName, userName, contactLastName,
            contactEmailId } = this.state;
        return (
            <Paper className={classNames(classes.paper, classes.customer)}>
                <h3 className={classes.sectionTitle}>Customer Details</h3>
                <Grid container={true} spacing={5}>
                    <Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={12}>
                                {/* <Grid item={true} xs={12} sm={6} style={{ marginBottom: '5px' }}>
                                    <Typography variant="subtitle1" align='left' component="h6" className={classes.formTitle}>Customer Information</Typography>
                                </Grid> */}
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="Customername">Customer Name</InputLabel>
                                        <Input value={name ? name : ''} onChange={this.onhandleChangeForm} id="Customername" name="name" onBlur={() => this.validator.showMessageFor('Customer Name')} />
                                        {this.validator.message('Customer Name', name, 'required')}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12} sm={12}>
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Typography variant="subtitle1" align='left' component="h6" className={classes.formTitle}>Address Information</Typography>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Grid container={true} spacing={3}>
                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="address1">Address1</InputLabel>
                                                    <Input value={address1 ? address1 : ''} onChange={this.onhandleChangeForm} id="address1" name="address1"
                                                        onBlur={() => this.validator.showMessageFor('Address1')}
                                                    />
                                                    {this.validator.message('Address1', address1, 'required')}
                                                </FormControl>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="address2">Address2</InputLabel>
                                                    <Input value={address2 ? address2 : ''} onChange={this.onhandleChangeForm} id="address2" name="address2"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="city">City</InputLabel>
                                                    <Input value={city ? city : ''} onChange={this.onhandleChangeForm} id="city" name="city"
                                                        onBlur={() => this.validator.showMessageFor('City')}
                                                    />
                                                    {this.validator.message('City', city, 'required')}

                                                </FormControl>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="state">State</InputLabel>
                                                    <Input value={state ? state : ''} onChange={this.onhandleChangeForm} id="state" name="state"
                                                        onBlur={() => this.validator.showMessageFor('State')}
                                                    />
                                                    {this.validator.message('State', state, 'required')}
                                                </FormControl>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="zip">Zip Code</InputLabel>
                                                    <Input value={zip ? zip : ''} onChange={this.onhandleChangeForm} id="zip" name="zip"
                                                        onBlur={() => this.validator.showMessageFor('Zip Code')}
                                                    />
                                                    {this.validator.message('Zip Code', zip, 'required|integer')}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {<Grid item={true} xs={12} sm={12}>
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Typography variant="subtitle1" align='left' component="h6" className={classes.formTitle}>Administrator Information</Typography>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Grid container={true} spacing={3}>
                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="contactFirstName">First Name</InputLabel>
                                                    <Input value={contactFirstName ? contactFirstName : ''} onChange={this.onhandleChangeForm} id="contactFirstName" name="contactFirstName"
                                                        onBlur={() => { this.validator.showMessageFor('First Name'); this.generateUserName() }}
                                                    />
                                                    {this.validator.message('First Name', contactFirstName, 'required')}
                                                </FormControl>
                                            </Grid>

                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="contactLastName">Last Name</InputLabel>
                                                    <Input value={contactLastName ? contactLastName : ''} onChange={this.onhandleChangeForm} id="contactLastName" name="contactLastName"
                                                        onBlur={() => { this.validator.showMessageFor('Last Name'); this.generateUserName() }}
                                                    />
                                                    {this.validator.message('Last Name', contactLastName, 'required')}

                                                </FormControl>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="userName">User Name</InputLabel>
                                                    <Input value={userName ? userName : ''} onChange={this.onhandleChangeForm} id="userName" name="userName"
                                                        onBlur={() => this.validator.showMessageFor('User Name')}
                                                    />
                                                    {this.validator.message('User Name', userName, 'required')}
                                                </FormControl>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={6}>
                                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                                    <InputLabel htmlFor="email">Email</InputLabel>
                                                    <Input value={contactEmailId ? contactEmailId : ''} onChange={this.onhandleChangeForm} id="contactEmailId" name="contactEmailId"
                                                        onBlur={() => this.validator.showMessageFor('Email')}
                                                    />
                                                    {this.validator.message('Email', contactEmailId, 'required|email')}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={6}>
                                <Button
                                    onClick={this.handleCancel}
                                    variant="text"
                                    color="secondary"
                                    style={{ backgroundColor: '#f8bbd0', textAlign: "center", color: '#6d6c6c', marginRight: 20, }}
                                    className={classes.button}>Cancel</Button>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <Button
                                    onClick={this.handleSubmitCustomerDetails}
                                    variant="text"
                                    color="primary"
                                    style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' }}
                                    className={classes.button}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>)

    }
}
const styles = (theme: Theme) => ({
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '6%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: '15%',
        },
    },
    formTitle: {
        fontWeight: 'bold'
    }

});
const mapStateToProps = (state: any) => {
    return {
        editCustomerDetails: state.customer.editCustomerDetails,
        userDetails: state.auth.user
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        putUpdateCustomer: CustomerAction.putUpdateCustomer,
        postSaveOrUpdateCustomer: CustomerAction.postSaveOrUpdateCustomer,
        resetTheEditCustomerData: CustomerAction.resetEditCustomerDetails,
    }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(CustomerForm as any)) as any);

