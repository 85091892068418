import * as React from 'react';
import {
    Paper,Theme, Typography, Grid,Tooltip,ListItemText, TablePagination
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as LocationReadingsAction from '../../store/actions/LocationReadings.Action';
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router-dom";
import IFilterModel from '../../models/common/IFilterModel';
import { IUtility } from '../../state/Utility';
import { SearchBox } from '../../components/search/SearchBox';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

const classNames = require('classnames');

interface IPageProps extends RouteComponentProps<{}> {
    locationReadings?: any,
    classes?: any,
    getLocationReadingsDetailsByRange: (start: number, limit: number, paramName?: string, paramValue?: string) => void;
    totalResults: number;
    match: any;
    location: ILocation;
    utility: IUtility


}
interface ILocationState {
    imei: string
}

export interface ILocation<S = ILocationState> {
    pathname: string;
    search: string;
    state: S;
    hash: string;
    key?: string;
}

interface IPageState {
    title: string;
    locationReadingTablePage: number,
    locationReadingTableRowsPerPage: number,
    pageStartCount: number,
    anchorEl: any;
    searchBoxValue: string,
    selectedFilterMenuIndex: number,
    filterMenuOptions: any[],
    selectedFilterMenu: string | number | boolean;
    selectedFilterMenuKey: string;
    searchBoxFilterKey: string,
}

class LocationReadings extends React.Component<IPageProps, IPageState> {
    constructor(props: IPageProps) {
        super(props);
        this.state = {
            title: 'welcome',
            locationReadingTablePage: 0,
            locationReadingTableRowsPerPage: 10,
            pageStartCount: 10,
            anchorEl: null,
            selectedFilterMenuIndex: 0,
            searchBoxValue: '',
            filterMenuOptions: [{ "lable": 'Device Name', "key": 'devicename' }, { "lable": 'IMEI', "key": 'imei' }],
            selectedFilterMenu: 'Device Name',
            selectedFilterMenuKey: 'devicename',
            searchBoxFilterKey: ''
        }
    }
    public componentWillMount() {
        const { imei } = (this.props.location && this.props.location.state) || {};
        if (imei) {
            this.props.getLocationReadingsDetailsByRange(0, 10, 'imei', imei);
        } else {
            this.props.getLocationReadingsDetailsByRange(0, 10);
        }
    }
    public componentDidMount() {
        const { imei } = (this.props.location && this.props.location.state) || {};
        if (imei) {
            this.setState({
                searchBoxValue: imei,
                selectedFilterMenu: 'IMEI',
                selectedFilterMenuKey: 'imei',
                searchBoxFilterKey: 'imei',
                selectedFilterMenuIndex: 1
            })
        }
    }
    handlePageChange=async(params) =>{
        this.setState({ locationReadingTablePage: params });
        await this.props.getLocationReadingsDetailsByRange( params * 10,this.state.pageStartCount, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
       // this.setState({ locationReadingTablePage: this.state.locationReadingTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.locationReadingTableRowsPerPage });
      }

    private handleChangelocationReadingPage = (event: any, page: number) => {
        this.setState({ locationReadingTablePage: page });
    };
    private handleChangeTableRowsPerPage = async (event: any) => {
        await this.props.getLocationReadingsDetailsByRange(0, event.target.value as number, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ locationReadingTableRowsPerPage: event.target.value, pageStartCount: event.target.value, locationReadingTablePage: 0 });
    };
    private loadNextPage = async () => {
        await this.props.getLocationReadingsDetailsByRange(this.state.pageStartCount, this.state.locationReadingTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ locationReadingTablePage: this.state.locationReadingTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.locationReadingTableRowsPerPage });
    }
    private loadPreviousPage = () => {
        this.setState({ locationReadingTablePage: this.state.locationReadingTablePage - 1, pageStartCount: this.state.pageStartCount - this.state.locationReadingTableRowsPerPage })
    }
    private handleClose = () => {
        this.setState({ anchorEl: null })
    }
    private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: IFilterModel) => {
        this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key })
    };
    private handleSearchBox = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private onEnterKeyPress = (event: any) => {
        if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
            this.callSearchByParam();
        }
    }
    private callSearchByParam = async () => {
        await this.props.getLocationReadingsDetailsByRange(0, this.state.locationReadingTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    }
    private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    columns: GridColDef[] = [
        {
          field: 'name',
          headerName: 'Sensor Name',
          width: 200,
          editable: false,
          valueGetter: this.getName
        },
        {
          field: 'nickName',
          headerName: 'Device Nick Name',
          width: 200,
          editable: false,
          valueGetter:this.getNickName
        },
      
        
        {
            field: 'imei',
            headerName: 'IMEI',
            width: 200,
            editable: false,
            valueGetter:this.getIMEI
        },
          {
            field: 'latitude',
            headerName: 'Latitude',
            width: 200,
            editable: false,
        },
          {
            field: 'longitude',
            headerName: 'Longitude',
            width: 200,
            editable: false,
        },
          {
            field: 'messageType',
            headerName: 'Message Type',
            width: 200,
            editable: false,
        },
        {
            field: 'updateDateTime',
            headerName: 'Last Reading ',
            width: 200,
            editable: false,
            valueGetter: this.getLastReading,
          },
      ];
      getLastReading(param){
        const dateOption: any = { year: 'numeric', month: 'short', day: '2-digit' };
        const timeOption: any = { hour: '2-digit', minute: '2-digit' };
        return `${param.row.updateDateTime? new Date(param.row.updateDateTime).toLocaleDateString('en-US', dateOption) + ' ' + new Date(param.row.updateDateTime).toLocaleTimeString('en-US', timeOption) :param.row.createDateTime? new Date(param.row.createDateTime).toLocaleDateString('en-US', dateOption) + ' ' + new Date(param.row.createDateTime).toLocaleTimeString('en-US', timeOption):'--'}`;
    }
    getName(param){
        return `${param.row.device? param.row.device.name :'--'}`;
    }
    getNickName(param){
        return `${param.row.device? param.row.device.nickName :'--'}`;
    }
    getIMEI(param){
        return `${param.row.device? param.row.device.imei :'--'}`;
    }

    public render(): JSX.Element {
        const dateOption: any = { year: 'numeric', month: 'short', day: '2-digit' };
        const timeOption: any = { hour: '2-digit', minute: '2-digit' };
        const { locationReadings, classes, totalResults } = this.props;
        const { selectedFilterMenuIndex, anchorEl, selectedFilterMenu, filterMenuOptions, searchBoxValue } = this.state;
        return (
            <Paper className={classNames(classes.paper, classes.container, 'container')} elevation={5} square={false}>
                <Grid container={true} style={{ marginTop: 2 }}>
                    <Grid item={true} xs={12} sm={10}>
                        <SearchBox
                            handleClickListItem={this.handleClickListItem}
                            anchorEl={anchorEl}
                            handleClose={this.handleClose}
                            filterMenuOptions={filterMenuOptions}
                            selectedFilterMenuIndex={selectedFilterMenuIndex}
                            handleMenuItemClick={this.handleMenuItemClick}
                            selectedFilterMenu={selectedFilterMenu}
                            searchBoxValue={searchBoxValue}
                            handleSearchBox={this.handleSearchBox}
                            onEnterKeyPress={this.onEnterKeyPress}
                            gridLabel={'Location Readings'} />
                    </Grid>
                    <Grid item={true} xs={12} sm={2}>

                    </Grid>
                </Grid>
                {(Array.isArray(locationReadings)) &&
                    <Box sx={{ height: 500, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row.locationId}
                            rows={locationReadings}
                            columns={this.columns}
                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            components={{
                                Toolbar: () => (
                                    <GridToolbar
                                      sx={{float: "right", 
                                      position: 'absolute',
                                      top:'-10%',
                                      right: '1%'
                                    }}
                                    />
                                  ),
                            }}
                            disableColumnFilter
                            pagination
                            rowCount={totalResults ? totalResults : 0}
                            onPageSizeChange={this.handleChangeTableRowsPerPage}
                            onPageChange={this.handlePageChange}
                            pageSize={this.state.locationReadingTableRowsPerPage}
                        /> 
                    </Box>} 
                {/* <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} />
                {(Array.isArray(locationReadings) && locationReadings.length > 0) ? 
                            locationReadings.slice(this.state.locationReadingTablePage * this.state.locationReadingTableRowsPerPage,
                                this.state.locationReadingTablePage * this.state.locationReadingTableRowsPerPage + this.state.locationReadingTableRowsPerPage).map((locationReading: any, locationIndex: number) => {
                                    return (
                                        <Grid container={true}  key={`locationReading${locationIndex}`}
                                            className={classes.actionArea} justifyContent={"space-between"}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                boxShadow: 3,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                padding:0.5,
                                                    ':hover': {
                                                      boxShadow: 20,
                                                      backgroundColor:'#c2e3f1'
                                                    },
                                                
                                            }}>
            
                                            <Grid item xs={6} sm={2} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline', 
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                               {locationReading.device ? locationReading.device.name : ''}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Sensor Name"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline',
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                                {locationReading.device ? locationReading.device.nickName : ''}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Device Nick Name"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={1} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline', 
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                               {locationReading.device ? locationReading.device.imei : ''}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"IMEI"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline',
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                                {locationReading.latitude ? locationReading.latitude : '--'}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Latitude"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline', 
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                                {locationReading.longitude ? locationReading.longitude : '--'}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Longitude"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={2}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline', 
                                                                }}
                                                                component="span"
                                                                variant="subtitle2"
                                                                color="text.primary"
                                                            >
                                                                {locationReading.messageType ? locationReading.messageType : '--'}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Message Type"}
                                                />
                                            </Grid>
                                           
                                                    
                                        </Grid>
                                    )
                                })
                : null} */}
                {/* <Typography component="div">
              
                    {(Array.isArray(locationReadings) && locationReadings.length > 0) ?
                        <TablePagination
                            component="div"
                            count={totalResults ? totalResults : 0}
                            rowsPerPage={this.state.locationReadingTableRowsPerPage}
                            page={this.state.locationReadingTablePage}
                            backIconButtonProps={{ 'aria-label': 'Previous Page', 'onClick': this.loadPreviousPage }}
                            nextIconButtonProps={{ 'aria-label': 'Next Page', 'onClick': this.loadNextPage, }}
                            onPageChange={this.handleChangelocationReadingPage}
                            onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                        /> : null}
                    {(Array.isArray(locationReadings) && locationReadings.length == 0) ?
                        <div style={{ 'textAlign': 'center','margin':10 }}>No Record Found</div>
                    : null}
                </Typography>  */}
            </Paper>)
    }
}
const styles = (theme: Theme) => ({
    paper: {
        overflow: 'hidden',
        color: theme.palette.text.secondary,
    },
    container: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    tableContainer: {
        padding: '1px',
        overflow: 'auto'
    },
    iconPointer: {
        cursor: 'pointer'
    },
    dialogContainer: {
        width: '50%',
        height: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            margin: 0,
            maxWidth: '100%',
            maxHeight: 'none',
            borderRadius: 0,
        },
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    tableHeaderBg: {
        backgroundColor: '#1976d2',
        fontWeight: 700,
    }
});
const mapStateToProps = (state: any) => {
    return {
        locationReadings: state.locationReading?.locationReadings,
        totalResults: state.locationReading?.totalResults,
        utility: state?.utility,
    }
}
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getLocationReadingsDetailsByRange: LocationReadingsAction.getLocationReadingsDetailsByRange
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchtoProps)(withStyles(styles as any)(LocationReadings as any)) as any);