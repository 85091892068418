import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { withStyles } from '@mui/styles';
import { Paper, Theme, Typography } from '@mui/material';
const classNames = require('classnames');

interface IPageProps {
    user: any,
    classes: any,
}
interface IPageState {

}
class VerifyEmail extends React.Component<IPageProps, IPageState> {

    render() {
        const { user, classes } = this.props;
        return (
            <Paper className={classNames(classes.paper, classes.container, 'container')} elevation={5} square={false}>
                <Typography component={'div'} style={{margin:50}}>
                    <Typography component={'p'} align="center">
                        Hi <b>{user?.userName}</b>
                    </Typography>

                    <Typography component={'p'} variant="body1" align="center" style={{fontSize:20}}>
                        We have sent mail to your email address.
                    </Typography>
                    <Typography component={'p'} variant="body1" align="center"style={{fontSize:28}}>
                        {'Please verify your email address and then login and continue.'}
                    </Typography>
                    <Typography component={'p'} variant="body1" align="center"style={{fontSize:25}}>
                       {'Thanks :)'}
                    </Typography>
                </Typography>
            </Paper>

        );
    }
}

const styles = (theme: Theme) => ({
    paper: {
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.divider}`,
        minHeight: 600,
    },
    container: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '32%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
        overflow: 'hidden',
    },
});
const mapStateToProps = (state: any) => {
    return {
        user: state.auth?.user
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(VerifyEmail as any)) as any);;
