import { TokenStoreKey } from "../AppConstants";
import { axiosInstance, logoutSessionExpire } from "./AxiosUtils";

export let api = async ({ method = "get", body = '', param = '', token = '', baseURL = 'normal', apiEndPoint = '' }) => {
    return new Promise(async (resolve, reject) => {
        const APIInstance = axiosInstance;
        const params = new URLSearchParams(param);
        const HeaderKey = ['Authorization', 'Content-Type'];
        if (baseURL !== "registration" && baseURL !== "tokenVerify" && baseURL !== "forgotpassword" && baseURL !== "auth") {
            APIInstance.defaults.headers.common[HeaderKey[0]] = `Bearer ${localStorage.getItem(TokenStoreKey)}`;
        }
        APIInstance.defaults.headers.common[HeaderKey[1]] = `application/json`;
        axiosInstance.defaults.headers.post[HeaderKey[1]] = `application/json`;
        axiosInstance.defaults.headers.put[HeaderKey[1]] = `application/json`;
        await APIInstance[method](`${process.env.REACT_APP_BASE_URL + apiEndPoint}`, body ? body : params ? params : '').then(async (response: any) => {
            if (token === 'string') {
                resolve(response.data);
            } else {
                resolve(statusHelper(response))
            }
        }).catch(async (error: any) => {
            try {
                if (error.response) {
                    reject(statusHelper(error.response))
                } else {
                    reject(error)
                }
            }
            catch (err) {
                console.error(err)
                reject(err)
            }
        })
    })
}

const statusHelper = async (response: any) => {
    if (response.status === 401) {
        await logoutSessionExpire();
    }
    if (response.status === 200) {
        return {
            status: response.status,
            ...response.data
        }
    } else {
        return {
            ...response.data
        }
    }
}
