export type AlertTypeAndColor = 'success' | 'info' | 'warning' | 'error';
interface IAlertButtonOptions {
    label: string;
    handler: () => void;
}

export interface IAlert {
    alertType?: AlertTypeAndColor;
    message?: string;
    buttons?: IAlertButtonOptions[];
}



export class Alert {
    public alertType: AlertTypeAndColor;
    public message: string;
    public buttons?: IAlertButtonOptions[];

    constructor(data: Alert) {
        this.alertType = data.alertType
        this.message = data.message
        this.buttons = data.buttons
    }

}