import React from 'react';
import { withStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import IDevice from '../../models/device/IDeviceView';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import {
    Checkbox, Container, Dialog, FormControl, Select, MenuItem,
    FormControlLabel, Grid, Input, InputLabel, Table, Theme, TableBody, TableCell, TableHead, TablePagination, TableRow, Box
} from '@mui/material';
import NativeSelect from '@mui/material/NativeSelect';
import * as CommonAction from '../../store/actions/Common.Action';
import ICustomerUserDetailMapView from '../../models/customer/ICustomerUserDetailMapView';
import deviceContinuity from '../../data/common-data/DeviceContinuity';
import IOpenCircuitModel from '../../models/common/OpenCircuitModel';
import SimpleReactValidator from 'simple-react-validator';
import * as DeviceAction from '../../store/actions/Devices.Action';
import * as SensorAction from '../../store/actions/SensorReadings.Action';
import * as UserAction from '../../store/actions/Users.Action';
import { RouteComponentProps } from "react-router-dom";
import Measurement from '../../data/common-data/Measurement';
import CommunicationInterval from '../../data/common-data/CommunicationInterval'
import IMeasurementModel from '../../models/common/IMeasurementModel';
import ICommunicationIntervalModel from '../../models/common/ICommunicationIntervalModel';
import IUser from '../../models/user/IUser';
import ISensor from '../../models/sensor-reading/ISensor';
import IAppOwner from '../../models/app-owner/IAppOwner';
import ICustomer from '../../models/customer/ICustomerView';
import InputMask from 'react-input-mask';
import IRecipient from '../../models/device/IRecipient';
import { PhoneNumberMaskInput } from '../../components/mask-input/PhoneNumberMaskInput';
import { DialogContent, DialogTitle } from '../../components/dialog/DialogTitle';


interface IPageProps extends RouteComponentProps<{}> {
    classes?: any,
    editDeviceDetail: IDevice,
    getAllCustomers: () => any;
    getDeviceById: (deviceId: string) => any;
    getSensorDetailsByDeviceId: (deviceId: string) => any;
    getAllRecipientsUsersAsPU: (customerId: string | any) => any;
    getAllRecipientsUsersAsOUorAU: () => void;
    getUserSelected: (deviceId: string) => any;
    match: any;
    sensorDetails?: ISensor[];
    getRecipients: IUser[];
    getSelectedUsers: IUser[];
    putUpdateDeviceDetails: (deviceId: any, body: string) => any;
    putUpdateUserDetailsByDeviceId: (deviceId: string, body: string) => any;
    postSensorConfigurationsSave: (body: string) => any;
    resetEditDeviceDetails: () => void
}

interface ISensorObjet {
    batteryLevelValue: string | any,
    powerLevelValue: string | any,
    temperatureBelow: string | any,
    temperatureAbove: string | any,
    dwyerExternalTemperatureSensorBelow: string | any,
    dwyerExternalTemperatureSensorAbove: string | any,
    sensorexORPBelow: string | any,
    sensorexORPAbove: string | any,
    dolExternalTemperatureSensorBelow: string | any,
    dolExternalTemperatureSensorAbove: string | any,
    flowlineLevelSensorBelow: string | any,
    flowlineLevelSensorAbove: string | any,
    dolExternalHumiditySensorBelow: string | any,
    dolExternalHumiditySensorAbove: string | any,
}
interface IPageState extends IDevice, ISensorObjet {
    customers: ICustomerUserDetailMapView[];
    activeStep: number;
    formSteps: string[];
    customerId: any;
    isUsersRolePU: boolean,
    isUsersRoleOUorAU: boolean,
    sensorDetails: any[],
    measurementInterval: number | string,
    communicationInterval: number,
    openCircuitid: string;
    getAllRecipients: IUser[];
    getSelectedUsers: IUser[];
    isEditDeviceRecord: boolean;
    openModel: boolean,
    recipientDetails: IRecipient;
    isEditRecipient: boolean,
    pageNo: number,
    rowsPerPage: number,
    pageStartCount: number,
    editIndex: any;
}
class DeviceForm extends React.Component<IPageProps, IPageState> {
    public isUsersRolePU: any;
    public isUsersRoleOUorAU: any;
    public stepFormAvailability: any = ['Device Information', 'Device Address',"Subscription","Sensor Configuration", 'Recipients', 'Measurement Interval']
    public validators: any;
    public validator: SimpleReactValidator = new SimpleReactValidator();
    constructor(props: IPageProps) {
        super(props);
        this.state = {
            customers:[],
            activeStep: 0,
            formSteps: ['Device Information', 'Device Address',"Subscription", "Sensor Configuration", 'Recipients', 'Measurement Interval'],
            ...props.editDeviceDetail,
            customerId:  null,
            batteryLevelValue: '',
            powerLevelValue: '',
            temperatureBelow: '',
            temperatureAbove: '',
            dwyerExternalTemperatureSensorBelow: '',
            dwyerExternalTemperatureSensorAbove: '',
            sensorexORPBelow: '',
            sensorexORPAbove: '',
            dolExternalTemperatureSensorBelow: '',
            dolExternalTemperatureSensorAbove: '',
            flowlineLevelSensorBelow: '',
            flowlineLevelSensorAbove: '',
            dolExternalHumiditySensorBelow: '',
            dolExternalHumiditySensorAbove: '',
            isUsersRolePU: false,
            isUsersRoleOUorAU: true,
            sensorDetails: [],
            measurementInterval: 0,
            communicationInterval: 0,
            customMessagePowerOn: '',
            customMessagePowerOff: '',
            openCircuitid: this.props.editDeviceDetail ? this.props.editDeviceDetail.openCircuit ? 'Yes' : 'No' : 'No',
            getAllRecipients: this.props.getRecipients ? this.props.getRecipients : [],
            getSelectedUsers: this.props.getSelectedUsers ? this.props.getSelectedUsers : [],
            isEditDeviceRecord: true,
            openModel: false,
            recipientDetails: {

            },
            isEditRecipient: false,
            pageNo: 0,
            rowsPerPage: 5,
            pageStartCount: 10,
            editIndex: null,
        }


        this.validators = this.stepFormAvailability.map((list: any, index: any) => {
            return {
                validator: new SimpleReactValidator({
                    autoForceUpdate: this,
                    validators: {
                        editorRequired: { // name the rule
                            message: "The :attribute field is required",
                            rule: (val: any, params: any) => {
                                const parser = new DOMParser();
                                const { textContent } = parser.parseFromString(val, "text/html").documentElement;
                                return (textContent?.trim() !== '')
                            }
                        },
                    },
                    messages: {
                        integer: "Please enter only number"
                    }
                })
            }
        });

        this.isUsersRolePU = (localStorage.getItem('userType') && localStorage.getItem('userType')?.toUpperCase() === 'PU');
        this.isUsersRoleOUorAU = (localStorage.getItem('userType') && (localStorage.getItem('userType')?.toUpperCase() === 'OU' || localStorage.getItem('userType')?.toUpperCase() === 'AU'));
    }
    async componentDidMount() {
        const deviceId = this.props.match.params.id;
        if (deviceId) {
            this.setState({ isEditDeviceRecord: true });
            await this.props.getUserSelected(deviceId).then((user:any)=>{
                if (user?.data && user?.data.length>0) {
                    this.setState({ getSelectedUsers: user.data })
                }
            });
            await this.props.getAllCustomers().then((response:any)=>{
                if (response.data.length>0) {
                    this.setState({ customers : response.data })
                }
            });
            await this.props.getDeviceById(deviceId).then((response:any) => {
                try {
                    this.setState({
                        ...response?.data,
                        customerId: response?.data.customer ? response?.data.customer.customerId : '',
                        openCircuitid: response?.data ? response?.data?.openCircuit ? 'Yes' : !response?.data?.openCircuit ? 'NO' : 'NO' : '',
                        communicationInterval: response?.data ? response?.data.communicationInterval ? response?.data.communicationInterval : 0 : 0,
                        measurementInterval: response?.data ? response?.data.measurementInterval ? response?.data.measurementInterval : 0 : 0,
                        customMessagePowerOff: response?.data.customMessagePowerOff,
                        customMessagePowerOn: response?.data.customMessagePowerOn,
                        isEditDeviceRecord: (Object.keys(response?.data).length > 0),
                        getAllRecipients: response?.data.recipientList
                    });
                } catch (error) {

                }
            });
            await this.props.getSensorDetailsByDeviceId(deviceId).then((response:any)=>{
                    if (response && response?.data) {
                    if (response?.data?.length > 0) {
                        response.data.sort((a, b) => a.sensorNumber - b.sensorNumber);
                        this.setState({ sensorDetails: response.data });
                       }
                   }
            });

        }
      
    }
   
    private handleNext = (index: number) => {
        if (this.validators[index]?.validator?.allValid()) {
            this.setState({ activeStep: this.state.activeStep + 1 });
        } else {
            this.validators[index]?.validator?.showMessages();
            this.validators[index]?.validator?.purgeFields();
            // this.forceUpdate();
        }
    };
    private editRecord = (data: any, index: number) => {
        this.setState({ recipientDetails: { ...data }, editIndex: index, isEditRecipient: true, openModel: true, })

    }
    private handleChangeTableRowsPerPage = async (event: any) => {
        this.setState({ rowsPerPage: event.target.value, pageStartCount: event.target.value, pageNo: 0 });
    };
    private deleteRecord = (data: any, index: number) => {
        const recipientList: any[] = this.state.recipientList;
        if (index > -1) {
            recipientList.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.setState({ recipientList: recipientList })

    }
    private handleOpenModel = () => {
        this.setState({ openModel: true, recipientDetails: {} })
    }
    private handleCloseModel = () => {
        this.setState({ openModel: false })
    }
    private handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };
    private handleDeviceGrid = () => {
        this.props.history.push('/devices')
    };
    private onhandleChangeForm = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    
    private onSensorFormChange = (sensor: any, event: any) => {
        const value = event.target.value;
        const updatedSensorDetails = [...this.state.sensorDetails]; 
        const index = updatedSensorDetails.findIndex((item) => item.sensorNumber === sensor.sensorNumber);
      
        if (index !== -1) {
          updatedSensorDetails[index] = { ...sensor, [event.target.name]: value ,isEdited:true};
          this.setState({
            sensorDetails: updatedSensorDetails,
          });
        }
      };
    private handleChangeCustomer = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value as number
        });
    }
    private handleChangeUserRecipient = (event: any, index: number) => {
        const value = event.target.checked;
        const name = event.target.name;
        const getAllRecipients = this.state.getAllRecipients;
        if (name === "textNotification") {
            getAllRecipients[index].textNotification = value
        } else if (name === 'phoneNotification') {
            getAllRecipients[index].phoneNotification = value
        } else if (name === "emailNotification") {
            getAllRecipients[index].emailNotification = value
        } else {
            getAllRecipients[index].isChecked = value
        }
        this.setState({ getAllRecipients })
    }
    private handleChangeOpenCircuit = (event: any) => {
        const value = event.target.value;
        const foundopenCircuit = deviceContinuity.find((element: IOpenCircuitModel) => {
            return element.name === value;
        });
        if (foundopenCircuit) {
            this.setState({ openCircuit: foundopenCircuit.openCircuitid })
        } else {
            this.setState({ openCircuit: false })
        }
        this.setState({
            openCircuitid: value
        });

    }
    private handleInterval = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value as string
        });
    }

    private getNewObject = (oldObject: object, neededKey: string) => {
        const newObject = Object.keys(oldObject).reduce((object, key) => {
            if (key === neededKey) {
                object[key] = oldObject[key]
            }
            return object
        }, {})
        return newObject;
    }
    private handleChangePage = (event: any, page: number) => {
        console.log('page', page)
    };
    private saveOrUpdateDeviceDetails = async (finishIndex: number) => {
        console.log('finishIndex', finishIndex)
        console.log('this.validators', this.validators)
        if (this.validators[finishIndex]?.validator?.allValid()) {
            if (this.state.isEditDeviceRecord) {
                const deviceDetailsCopy: any = Object.assign({}, this.state);
                const sensorDetails: ISensor[] = Object.assign([], this.state.sensorDetails);
                const appOwnerdetails: IAppOwner = Object.assign({}, this.state.appOwner);
                const customerDetails: ICustomer = Object.assign({}, this.state.customer);
                const userDetails: IUser[] = Object.assign([], this.state.getAllRecipients);
                deviceDetailsCopy.customer = this.getNewObject(customerDetails, 'customerId');
                deviceDetailsCopy.appOwner = this.getNewObject(appOwnerdetails, 'appOwnerId');
                delete deviceDetailsCopy.deviceUserDetails;
                delete deviceDetailsCopy.sensorDetails;
                delete deviceDetailsCopy.formSteps;
                delete deviceDetailsCopy.latestDeviceReadings;
                delete deviceDetailsCopy.getAllRecipients;
                delete deviceDetailsCopy.isUsersRoleOUorAU;
                delete deviceDetailsCopy.isUsersRolePU;
                delete deviceDetailsCopy.activeStep;
                delete deviceDetailsCopy.openCircuitid;
                delete deviceDetailsCopy.showAndHideSensorField;
                delete deviceDetailsCopy.getSelectedUsers;
                Object.keys(deviceDetailsCopy).forEach(key => deviceDetailsCopy[key] === undefined && delete deviceDetailsCopy[key]);
                await this.props.putUpdateDeviceDetails(deviceDetailsCopy.deviceId, JSON.stringify(deviceDetailsCopy));
                await sensorDetails.forEach(async (sensor: ISensor) => {
                    if (sensor && 'isEdited' in sensor) {
                        delete sensor.isEdited
                        await this.props.postSensorConfigurationsSave(JSON.stringify(sensor));
                    }
                })
                const saveUser: IUser[] = [];
                await userDetails.forEach((user: IUser) => {
                    if (user.isChecked) {
                        saveUser.push({
                            "userId": user.userId
                        })
                    }
                })
                if (saveUser.length > 0) {
                    await this.props.putUpdateUserDetailsByDeviceId(deviceDetailsCopy.deviceId, JSON.stringify(saveUser)).then((response: any) => {
                        this.setState({ activeStep: this.state.activeStep + 1 });
                    }).catch((error: any) => {
                        console.error(error);
                    });
                } else {
                    this.setState({ activeStep: this.state.activeStep + 1 });
                }


            } else {
               // alert('401 UNAUTHORIZED')
            }

        } else {
            this.validators[finishIndex]?.validator?.showMessages();
        }
    }
    private onhandleChangeRecipientForm = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        const recipientDetails = this.state.recipientDetails;
        recipientDetails[name] = value;
        this.setState({
            recipientDetails: recipientDetails
        });
    }
    private onHandleChangeFormCheckBox = (event: any) => {
        const value = event.target.checked;
        const name = event.target.name;
        const recipientDetails = this.state.recipientDetails;
        recipientDetails[name] = value;
        this.setState({
            recipientDetails: recipientDetails
        });
    }
    private handleSubmitRecipientDetails = async () => {
        if (this.validator.allValid()) {
            const recipientList: any[] = this.state.recipientList ? this.state.recipientList : [];
            const mobileNumberRemoveMask = this.state.recipientDetails?.mobileNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '');
            const landlineNumberRemoveMask = this.state.recipientDetails?.landlineNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '');
            this.state.recipientDetails.mobileNumber = mobileNumberRemoveMask;
            this.state.recipientDetails.landlineNumber = landlineNumberRemoveMask;
            if (this.state.isEditRecipient && this.state.editIndex >= 0) {
                recipientList[this.state.editIndex] = this.state.recipientDetails;
            } else {
                recipientList.push(this.state.recipientDetails);
            }
            this.setState({ recipientList: recipientList, openModel: false, recipientDetails: {}, isEditRecipient: false, })

        } else {
            this.validator.showMessages();
        }
    }
    public render() {
        const { classes } = this.props;
        const { activeStep, formSteps, isUsersRolePU, isUsersRoleOUorAU,customers } = this.state;
        const { imei, swVersion, name, salesOrderNumber, customerId } = this.state;
        const { address, address2, city, state, postalCode, activationDate, renewDate, customer } = this.state;
        const {  measurementInterval, openCircuitid, communicationInterval, customMessagePowerOn,
            customMessagePowerOff, repeatVoiceMail, recipientList, isEditDeviceRecord } = this.state;
        const { pageNo, rowsPerPage, openModel } = this.state;
        const inputProps = {
            step: 300,
        };
        const { email, firstName, lastName, mobileNumber,
            landlineNumber, emailNotification, phoneNotification, textNotification } = this.state.recipientDetails

        const getStepContent = (step: string, index: number) => {
            switch (step) {
                case 'Device Information':
                    return (
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12} sm={12}>
                                <Grid container={true} spacing={3}>
                                    {((!isUsersRolePU && isUsersRoleOUorAU)) ?
                                        <Grid item={true} xs={12} sm={6}>
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel shrink={customerId ? true : false} htmlFor="customerId">Customer</InputLabel>
                                                <NativeSelect
                                                    id="customerId"
                                                    value={customerId || ''}
                                                    name='customerId'
                                                    onChange={this.handleChangeCustomer}
                                                    onBlur={() => this.validators[index]?.validator?.showMessageFor('Customer')}
                                                >
                                                    {/* <option aria-label="None" value="" /> */}
                                                    {customers ? customers.map((eachCustomer: ICustomerUserDetailMapView, customerIndex: number) => {
                                                        return (<option key={customerIndex} value={`${eachCustomer?.customerId}`}>{eachCustomer.name}</option>)
                                                    })
                                                        : null}
                                                </NativeSelect>
                                                {this.validators[index]?.validator?.message('Customer', customerId, 'required')}
                                            </FormControl>
                                        </Grid> : null}
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="swVersion">Software Version</InputLabel>
                                            <Input value={swVersion ? swVersion : ''} onChange={this.onhandleChangeForm} id="swVersion" name="swVersion" disabled={isEditDeviceRecord}
                                                onBlur={() => this.validators[index]?.validator?.showMessageFor('Software Version')}
                                            />
                                            {this.validators[index]?.validator?.message('Software Version', swVersion, 'required')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="imei">IMEI</InputLabel>
                                            <Input value={imei ? imei : ''} onChange={this.onhandleChangeForm} id="imei" name="imei" disabled={isEditDeviceRecord}
                                                onBlur={() => this.validators[index]?.validator?.showMessageFor('IMEI')}
                                            />
                                            {this.validators[index]?.validator?.message('IMEI', imei, 'required')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="openCircuitid">Device has continuity</InputLabel>
                                            <NativeSelect
                                                id="openCircuitid"
                                                value={openCircuitid ? openCircuitid : ''}
                                                name='openCircuitid'
                                                disabled={(isUsersRoleOUorAU && isEditDeviceRecord)}
                                                onFocus={() => this.validators[index]?.validator?.showMessageFor('Device has continuity')}
                                                onChange={this.handleChangeOpenCircuit}
                                            >
                                                <option aria-label="None" value="" />
                                                {deviceContinuity ? deviceContinuity.map((circuite: IOpenCircuitModel, circuiteIndex: number) => {
                                                    return (<option key={circuiteIndex} aria-label={circuite.name} value={circuite.name}>{circuite.name}</option>)
                                                }) : null}
                                            </NativeSelect>
                                            {this.validators[index]?.validator?.message('Device has continuity', openCircuitid, 'required')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="name">Device Name</InputLabel>
                                            <Input value={name ? name : ''} onChange={this.onhandleChangeForm} id="name" name="name" onBlur={() => this.validators[index]?.validator?.showMessageFor('Device Name')} />
                                            {this.validators[index]?.validator?.message('Device Name', name, 'required')}
                                        </FormControl>
                                    </Grid>
                                    {isUsersRoleOUorAU && <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="salesOrderNumber">Sales Order #</InputLabel>
                                            <Input value={salesOrderNumber ? salesOrderNumber : ''} onBlur={() => this.validators[index]?.validator?.showMessageFor('Sales Order')} onChange={this.onhandleChangeForm} id="salesOrderNumber" name="salesOrderNumber" />
                                            {this.validators[index]?.validator?.message('Sales Order', salesOrderNumber, 'required')}
                                        </FormControl>

                                    </Grid>}

                                </Grid>
                            </Grid>
                        </Grid>
                    );

                case 'Device Address':
                    return (<Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="address">Address</InputLabel>
                                    <Input value={address ? address : ''} onChange={this.onhandleChangeForm} id="address" name="address" onBlur={() => this.validators[index]?.validator?.showMessageFor('Address')} />
                                    {this.validators[index]?.validator?.message('Address', address, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="address2">Address2</InputLabel>
                                    <Input value={address2 ? address2 : ''} onChange={this.onhandleChangeForm} id="address2" name="address2" />
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="city">City</InputLabel>
                                    <Input value={city ? city : ''} onChange={this.onhandleChangeForm} id="city" name="city" onBlur={() => this.validators[index]?.validator?.showMessageFor('City')} />
                                    {this.validators[index]?.validator?.message('City', city, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="state">State</InputLabel>
                                    <Input value={state ? state : ''} onChange={this.onhandleChangeForm} id="state" name="state" onBlur={() => this.validators[index]?.validator?.showMessageFor('State')} />
                                    {this.validators[index]?.validator?.message('State', state, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="postalCode">Zip Code</InputLabel>
                                    <Input value={postalCode ? postalCode : ''} onChange={this.onhandleChangeForm} id="postalCode" name="postalCode" onBlur={() => this.validators[index]?.validator?.showMessageFor('Zip Code')} />
                                    {this.validators[index]?.validator?.message('Zip Code', postalCode, 'required|integer|min:0|max:5')}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>);
               
                case 'Subscription':
                    return (<Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field} >
                                    <InputLabel htmlFor="activationDate" shrink={true} >Active Date</InputLabel>
                                    <Input value={activationDate ? activationDate : ''} type="date"
                                        onChange={this.onhandleChangeForm}
                                        onBlur={() => this.validators[index]?.validator?.showMessageFor('Active Date')}
                                        id="activationDate" name="activationDate" />
                                    {this.validators[index]?.validator?.message('Active Date', activationDate, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="renewDate" shrink={true}>Renew Date</InputLabel>
                                    <Input value={renewDate ? renewDate : ''} type="date"
                                        onChange={this.onhandleChangeForm} onBlur={() => this.validators[index]?.validator?.showMessageFor('Renew Date')}
                                        id="renewDate" name="renewDate"
                                    />
                                    {this.validators[index]?.validator?.message('Renew Date', renewDate, 'required')}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>);
             
                case 'Sensor Configuration':
                    return (
                        <Grid container={true} spacing={6}>
                          {this.state.sensorDetails.map(
                            (sensor: any, index: any) => {
                              if(sensor.sensorNumber<=7){
                                let sensorName = sensor.sensorType.charAt(0).toUpperCase() + sensor.sensorType.slice(1);

                              return (
                                <Grid item={true} xs={12} sm={6}>
                                  <InputLabel htmlFor= {sensor.sensorType} style={{marginBottom:10}}>
                                    {sensorName}
                                  </InputLabel>
                                
                                  <Grid container={true}spacing={3} >
                                          <Grid item={true} md={4}>
                                              <FormControl fullWidth={true} className={classes.field}>
                                                  <InputLabel htmlFor="temperatureBelow">Alarm Min limit</InputLabel>
                                                  <Input  value={sensor?.alarmLowerLimitValue}
                                                      onChange={(event)=>this.onSensorFormChange(sensor,event)}
                                                      onBlur={() => this.validators[index]?.validator?.showMessageFor('alarmLowerLimitValue')}
                                                      id={sensor.sensorNumber+'_allow_min'}  name='alarmLowerLimitValue'/>
                                              </FormControl>
                                          </Grid>
                                          <Grid item={true} md={4}>
                                              <FormControl fullWidth={true} className={classes.field}>
                                                  <InputLabel htmlFor="temperatureBelow">Alarm Max limit</InputLabel>
                                                  <Input  value={sensor?.alarmUpperLimitValue}
                                                      onChange={(event)=>this.onSensorFormChange(sensor,event)}
                                                      onBlur={() => this.validators[index]?.validator?.showMessageFor('alertUpperLimitValue')}
                                                      id={sensor.sensorNumber+'_allow_max'} name='alarmUpperLimitValue' />
                                              </FormControl>
                                          </Grid>
                                          <Grid item={true} md={3}>
                                              <FormControl required={false} fullWidth={true} className={classes.field}>
                                                  <InputLabel htmlFor="temperatureBelow"> {sensor.alertUnitofMeasure}</InputLabel>
                                              </FormControl>
                                          </Grid>
                                  </Grid>
                                
                                </Grid>
                              );
                              }
                            }
                          )}
                        </Grid>
                    );
                case 'Recipients':
                    return (<Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={3}>
                            {recipientList?.length > 0 && <Grid item={true} xs={12} sm={12} style={{
                                alignItems: 'end',
                                justifyContent: 'end',
                                display: 'flex'
                            }}>
                                <Button style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' }} onClick={() => this.handleOpenModel()}>Add Recipient</Button>
                            </Grid>}
                            {(Array.isArray(recipientList) && recipientList.length > 0) ?
                                <Grid item={true} xs={12} sm={12}>
                                    <Typography component="div" className={classes.tableContainer}>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tableHeaderBg}>First Name</TableCell>
                                                    <TableCell className={classes.tableHeaderBg}>Email</TableCell>
                                                    <TableCell className={classes.tableHeaderBg}>Mobile Number</TableCell>
                                                    <TableCell className={classes.tableHeaderBg}>Call Number</TableCell>
                                                    <TableCell className={classes.tableHeaderBg}>Email Notification</TableCell>
                                                    <TableCell className={classes.tableHeaderBg}>Text Notification</TableCell>
                                                    <TableCell className={classes.tableHeaderBg}>Phone Notification</TableCell>
                                                    <TableCell className={classes.tableHeaderBg}>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {<TableBody>
                                                {recipientList?.slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)?.map((availableRecipient: IRecipient, recipientIndex: number) => {
                                                    return (
                                                        <TableRow key={`recipient${recipientIndex}`} onDoubleClick={() => this.editRecord(availableRecipient, recipientIndex)}>
                                                            <TableCell >{availableRecipient.firstName}</TableCell>
                                                            <TableCell >{availableRecipient.email} </TableCell>
                                                            <TableCell > <InputMask mask="+1 (999) 999-9999" style={{ border: 0 }} disabled={true} value={availableRecipient?.mobileNumber || ''} /> </TableCell>
                                                            <TableCell > <InputMask mask="+1 (999) 999-9999" style={{ border: 0 }} disabled={true} value={availableRecipient?.landlineNumber || ''} /> </TableCell>
                                                            <TableCell >{availableRecipient.emailNotification ? 'Enabled' : 'Disabled'} </TableCell>
                                                            <TableCell >{availableRecipient.textNotification ? 'Enabled' : 'Disabled'} </TableCell>
                                                            <TableCell >{availableRecipient.phoneNotification ? 'Enabled' : 'Disabled'} </TableCell>
                                                            <TableCell >
                                                                <EditIcon className={classes.iconPointer} onClick={() => this.editRecord(availableRecipient, recipientIndex)} />
                                                                <DeleteForeverIcon className={classes.iconPointer} onClick={() => this.deleteRecord(availableRecipient, recipientIndex)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>}
                                        </Table>

                                        <TablePagination
                                            component="div"
                                            count={recipientList.length}
                                            rowsPerPage={rowsPerPage}
                                            page={pageNo}
                                            onPageChange={this.handleChangePage}
                                            onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                                        />
                                    </Typography>
                                </Grid>
                                : <Typography component={'div'} style={{ padding: 53 }} >No Recipients Found <br />
                                    <Button onClick={() => this.handleOpenModel()}>Add Recipient</Button>
                                </Typography>
                            }
                        </Grid>
                    </Grid >)
                case 'Measurement Interval':
                    return (<Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="measurementInterval">Measurement Interval</InputLabel>
                                    <NativeSelect
                                        id="measurementInterval"
                                        value={measurementInterval ? measurementInterval : ''}
                                        name='measurementInterval'
                                        onBlur={() => this.validators[index]?.validator?.showMessageFor('Measurement Interval')}
                                        onChange={this.handleInterval}
                                    >
                                        {Measurement ? Measurement.map((measurement: IMeasurementModel, measurementIndex: number) => {
                                            return (<option key={measurementIndex} value={measurement.measurementIntervalValue}>{measurement.measurementIntervalName}</option>)
                                        }) : null}
                                    </NativeSelect>
                                    {this.validators[index]?.validator?.message('Measurement Interval', measurementInterval, 'required')}

                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="communicationInterval">Communication Interval</InputLabel>
                                    <NativeSelect
                                        id="communicationInterval"
                                        value={communicationInterval ? communicationInterval : ''}
                                        name='communicationInterval'
                                        onBlur={() => this.validators[index]?.validator?.showMessageFor('Communication Interval')}
                                        onChange={this.handleInterval}
                                    >
                                        {CommunicationInterval ? CommunicationInterval.map((eachcommunicationInterval: ICommunicationIntervalModel, communicationIntervalIndex: number) => {
                                            return (<option key={communicationIntervalIndex} value={`${eachcommunicationInterval.communicationIntervalValue}`}>{eachcommunicationInterval.communicationIntervalName}</option>)
                                        }) : null}
                                    </NativeSelect>
                                    {this.validators[index].validator.message('Communication Interval', communicationInterval, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="customMessagePowerOn">Custom Relay On Message</InputLabel>
                                    <Input value={customMessagePowerOn ? customMessagePowerOn : ''} inputProps={inputProps} onBlur={() => this.validators[index]?.validator?.showMessageFor('Custom Relay On Message')} onChange={this.onhandleChangeForm} id="customMessagePowerOn" name="customMessagePowerOn" />
                                    {this.validators[index]?.validator?.message('Custom Relay On Message', customMessagePowerOn, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="customMessagePowerOff">Custom Relay Off Message</InputLabel>
                                    <Input value={customMessagePowerOff ? customMessagePowerOff : ''} inputProps={inputProps} onBlur={() => this.validators[index]?.validator?.showMessageFor('Custom Relay Off Message')} onChange={this.onhandleChangeForm} id="customMessagePowerOff" name="customMessagePowerOff" />
                                    {this.validators[index]?.validator?.message('Custom Relay Off Message', customMessagePowerOff, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="repeatVoiceMail">Message Repeat</InputLabel>
                                    <Input value={repeatVoiceMail ? repeatVoiceMail : ''} onChange={this.onhandleChangeForm} onBlur={() => this.validators[index]?.validator?.showMessageFor('Message Repeat')} id="repeatVoiceMail" name="repeatVoiceMail" />
                                    {this.validators[index]?.validator?.message('Message Repeat', repeatVoiceMail, 'required|integer')}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>)
                default:
                    return '401 UNAUTHORIZED (or) CONTENT NOT AVAILABLE';
            }
        }

        return (<Paper className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {formSteps.map((label, stepIndex) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <Typography component='div'>{getStepContent(label, stepIndex)}</Typography>
                            <div className={classes.actionsContainer}>
                                <Grid item={true} xs={12} sm={12} style={{ display: 'flex', justifyContent: 'right' }}>
                                    {activeStep != 0 &&
                                        <Button
                                            style={{ backgroundColor: '#f8bbd0', textAlign: "center", color: '#6d6c6c', marginRight: 20, }}
                                            disabled={activeStep === 0}
                                            onClick={this.handleBack}
                                            className={classes.buttonCommon}
                                        >Back</Button>
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' }}
                                        onClick={activeStep === formSteps.length - 1 ? () => this.saveOrUpdateDeviceDetails(stepIndex) : () => this.handleNext(stepIndex)}
                                        className={classes.buttonCommon}
                                    >
                                        {activeStep === formSteps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>

                                </Grid>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            <Dialog classes={{ paper: classes.dialogContainer }}
                onClose={() => this.handleCloseModel()} maxWidth="md"
                fullWidth={true} aria-labelledby="Items-View" open={openModel}>
                <DialogTitle id="recipient-dialog-title" style={{ textAlign: "center", }}>Recipient</DialogTitle>
                <DialogContent >
                    <Container maxWidth="lg" style={{ marginTop: 28 }}>
                        <form autoComplete='off'>
                            <Grid item={true} xs={12} sm={12}>
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} sm={6} >
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="firstName">First Name</InputLabel>
                                            <Input value={firstName || ''} onChange={this.onhandleChangeRecipientForm} id="firstName" name="firstName"
                                                onBlur={() => { this.validator.showMessageFor('First Name'); }}
                                            />
                                            {this.validator.message('First Name', firstName, 'required')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6} >
                                        <FormControl fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="lastName">Last Name</InputLabel>
                                            <Input value={lastName || ''} onChange={this.onhandleChangeRecipientForm} id="lastName" name="lastName" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6} >
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="email">Email</InputLabel>
                                            <Input value={email || ''} onChange={this.onhandleChangeRecipientForm} id="email" name="email"
                                                onBlur={() => this.validator.showMessageFor('Email')}
                                            />
                                            {this.validator.message('Email', email, 'required|email')}
                                        </FormControl>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} >
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="mobileNumber">Text Number</InputLabel>
                                            <PhoneNumberMaskInput value={mobileNumber || ''}
                                                onChange={this.onhandleChangeRecipientForm}
                                                id="mobileNumber"
                                                name="mobileNumber"
                                                disabled={false}
                                                onBlur={() => this.validator.showMessageFor('Text Number')}
                                            />
                                            {this.validator.message('Text Number', mobileNumber, 'required|phone')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6} >
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="landlineNumber">Call Number</InputLabel>
                                            <PhoneNumberMaskInput value={landlineNumber || ''}
                                                onChange={this.onhandleChangeRecipientForm}
                                                id="landlineNumber"
                                                disabled={false}
                                                name="landlineNumber"
                                                onBlur={() => this.validator.showMessageFor('Call Number')}
                                            />
                                            {this.validator.message('Call Number', landlineNumber, 'required|phone')}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12} sm={12}>
                                <Grid container={true} spacing={2} style={{ marginTop: 20 }}>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Typography variant="subtitle1" align='left' component="h6" className={classes.formTitle}>Notification Preferences</Typography>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Grid container={true} spacing={3}>
                                            <Grid item={true} xs={12} sm={12}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={emailNotification ? emailNotification : false}
                                                        onChange={(event) => this.onHandleChangeFormCheckBox(event)}
                                                        name="emailNotification"
                                                        color="primary"
                                                    />
                                                    }
                                                    label="Send Email"
                                                />
                                            </Grid>
                                            <Grid item={true} xs={12} sm={12}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={phoneNotification ? phoneNotification : false}
                                                        onChange={(event) => this.onHandleChangeFormCheckBox(event)}
                                                        name="phoneNotification"
                                                        color="primary"
                                                    />
                                                    }
                                                    label="Call by Phone"
                                                />
                                            </Grid>
                                            <Grid item={true} xs={12} sm={12}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={textNotification ? textNotification : false}
                                                        onChange={(event) => this.onHandleChangeFormCheckBox(event)}
                                                        name="textNotification"
                                                        color="primary"
                                                    />
                                                    }
                                                    label="Send Text"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12} sm={12}>
                                <Grid container={true} spacing={2} style={{ flexDirection: 'row-reverse', marginTop: 5, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <Grid item={true} xs={12} sm={6} >
                                        <Button
                                            onClick={this.handleSubmitRecipientDetails}
                                            variant="text"
                                            color="primary"
                                            style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' }}
                                            className={classes.buttonCommon}>Proceed</Button>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}  >
                                        <Button
                                            className={classes.buttonCommon}
                                            onClick={this.handleCloseModel}
                                            variant="text"
                                            color="secondary"
                                            style={{ backgroundColor: '#f8bbd0', textAlign: "center", color: '#6d6c6c' }}
                                        >Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>

                </DialogContent>
            </Dialog>
            {activeStep === formSteps.length && (
                <Paper square={true} elevation={0} className={classes.resetContainer}>
                    <Typography style={{ color: '#0aac26' }} >Device Details Has Successfully Updated</Typography>
                    <Button style={{ color: '#0866b5', backgroundColor: '#ccc' }}
                        onClick={this.handleDeviceGrid} className={classes.button}>Back to Device</Button>
                </Paper>
            )}
        </Paper>)
    }
}
const styles = (theme: Theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    buttonCommon: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
        [theme.breakpoints.between('md', 'xl')]: {
            width: '50%'
        },
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    resetContainer: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
});
const mapStateToProps = (state: any) => {
    return {
        // editDeviceDetail: state.device?.editDeviceDetail,
        // customers: state.common?.allCustomers,
        // sensorDetails: state.device?.sensorDetailsByDeviceId,
        // getRecipients: state.common?.getRecipients,
        // getSelectedUsers: state.unwiredUser.userSelected
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getAllCustomers: CommonAction.getAllCustomers,
        getDeviceById: DeviceAction.getDeviceById,
        getSensorDetailsByDeviceId: SensorAction.getSensorDetailsByDeviceId,
        getAllRecipientsUsersAsPU: CommonAction.getAllRecipientsUsersAsPU,
        getAllRecipientsUsersAsOUorAU: CommonAction.getAllRecipientsUsersAsOUorAU,
        getUserSelected: UserAction.getUsersRangeByDeviceId,
        putUpdateDeviceDetails: DeviceAction.putUpdateDeviceDetailsByDeviceId,
        putUpdateUserDetailsByDeviceId: UserAction.putAddUserByDeviceId,
        postSensorConfigurationsSave: SensorAction.postSensorConfigurationsSave,
        resetEditDeviceDetails: DeviceAction.resetEditDeviceDetails
    }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(DeviceForm as any)) as any);

